/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  Button,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { SketchPicker } from "react-color";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useEffect, useState } from "react";
import { ExpandMore, Save } from "@mui/icons-material";
import { StyledAccordionSummary } from "../../../styles/componentStyled";
import { Company } from "../../setups/types/setupTypes";
import { CompanyFilter } from "../../../components/filter/CompanyFilter";
import { ClientTypes } from "../../auth/types/authType";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectAuth } from "../../auth/authSlice";
import { addCompany, getCompany, selectSetups } from "../../setups/setupSlices";

interface Props {
  setBGC: any;
  setHFC: any;
  setHBC: any;
  setLFC: any;
  setLBC: any;
  setVFC: any;
  setVBC: any;
}

export const CvMaker = ({
  setBGC: setBackgroundColor,
  setHFC,
  setHBC,
  setLFC,
  setLBC,
  setVFC,
  setVBC,
}: Props) => {
  const theme = useTheme();
  const [company, setCompany] = useState<Company>();
  // const [isAgentTemplate, setIsAgentTemplate] = useState<boolean>(false);

  const [backgroundColor, setBgColor] = useState(theme.palette.secondary.main);
  const [hfc, setHFC2] = useState("#fff");
  const [hbc, setHBC2] = useState(theme.palette.secondary.main);
  const [lfc, setLFC2] = useState(theme.palette.primary.main);
  const [lbc, setLBC2] = useState("#fff");
  const [vfc, setVFC2] = useState(theme.palette.secondary.main);
  const [vbc, setVBC2] = useState("#fff");
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(selectAuth);
  const { selectedCompany } = useAppSelector(selectSetups);

  // const changeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsAgentTemplate(!isAgentTemplate);
  // };
  useEffect(() => {
    if (user && user.company && user.company._id)
      dispatch(getCompany(user.company._id));
  }, [dispatch, user]);

  useEffect(() => {
    if (company && company._id) {
      if (company._id === "-1") {
        if (user && user.company && user.company._id)
          dispatch(getCompany(user.company._id));
      } else {
        dispatch(getCompany(company._id));
      }
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (selectedCompany) {
      console.log(selectedCompany);
      if (selectedCompany.remarks && selectedCompany.remarks?.length > 0) {
        const templateColors = selectedCompany.remarks.split(",");
        setBgColor(templateColors[0]);
        setBackgroundColor(templateColors[0]);
        setHFC(templateColors[1]);
        setHFC2(templateColors[1]);
        setHBC(templateColors[2]);
        setHBC2(templateColors[2]);
        setLFC(templateColors[3]);
        setLFC2(templateColors[3]);
        setLBC(templateColors[4]);
        setLBC2(templateColors[4]);
        setVFC(templateColors[5]);
        setVFC2(templateColors[5]);
        setVBC(templateColors[6]);
        setVBC2(templateColors[6]);
      }
    }
  }, [selectedCompany]);

  const saveSelectedCompanyCvTemplate = () => {
    //console.log(selectedCompany);
    dispatch(
      addCompany({
        ...selectedCompany,
        remarks: `${backgroundColor},${hfc},${hbc},${lfc},${lbc},${vfc},${vbc}`,
      })
    );
  };
  return (
    <Accordion sx={{ mb: 1 }} expanded>
      <StyledAccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" component="div">
          CV MAKER
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {/* <Grid item xs={4}>
            <FormControlLabel
              style={{ marginTop: 8 }}
              control={
                <Switch
                  checked={isAgentTemplate}
                  onChange={changeCheckBox}
                  inputProps={{ "aria-label": "controlled" }}
                  name="selectAll"
                  color="secondary"
                />
              }
              label="Add Agent Template"
            />
          </Grid> */}
          {/* {isAgentTemplate && (
            )} */}
          <Grid item xs={12}>
            <CompanyFilter
              companyType={ClientTypes.ForeignAgent}
              setCompany={setCompany}
              includeAgency={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ColorPopState
              title="Background Color"
              buttonBackgroundColor={backgroundColor}
              setLocalColor={setBgColor}
              setParentColor={setBackgroundColor}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <ColorPopState
              title="Header Font Color"
              buttonBackgroundColor={hfc}
              setLocalColor={setHFC2}
              setParentColor={setHFC}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <ColorPopState
              title="Header Background"
              buttonBackgroundColor={hbc}
              setLocalColor={setHBC2}
              setParentColor={setHBC}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <ColorPopState
              title="Label Font Color"
              buttonBackgroundColor={lfc}
              setLocalColor={setLFC2}
              setParentColor={setLFC}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <ColorPopState
              title="Label Background"
              buttonBackgroundColor={lbc}
              setLocalColor={setLBC2}
              setParentColor={setLBC}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <ColorPopState
              title="Value Font Color"
              buttonBackgroundColor={vfc}
              setLocalColor={setVFC2}
              setParentColor={setVFC}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <ColorPopState
              title="Value Background"
              buttonBackgroundColor={vbc}
              setLocalColor={setVBC2}
              setParentColor={setVBC}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="warning"
              variant="contained"
              sx={{ width: "100%" }}
              onClick={saveSelectedCompanyCvTemplate}
            >
              <Save /> Save Template
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

interface PopUpProps {
  setLocalColor: any;
  setParentColor: any;
  title: string;
  buttonBackgroundColor: string;
}

export const ColorPopState = ({
  title,
  setLocalColor,
  setParentColor,
  buttonBackgroundColor,
}: PopUpProps) => {
  const theme = useTheme();
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <>
          <Button
            variant="contained"
            sx={{
              backgroundColor: buttonBackgroundColor,
              width: "100%",
              color: theme.palette.getContrastText(buttonBackgroundColor),
            }}
            {...bindTrigger(popupState)}
          >
            {title}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <SketchPicker
              color={buttonBackgroundColor}
              onChange={(color) => {
                setParentColor(color.hex);
                setLocalColor(color.hex);
              }}
              // onChangeComplete={(color, event) => console.log(color)}
            />
          </Popover>
        </>
      )}
    </PopupState>
  );
};
