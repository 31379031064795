import React, { ReactNode, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../app/hooks";
import { selectAuth } from "../../features/auth/authSlice";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";

interface Props {
  children: ReactNode;
  isOpen: boolean;
  title: string;
  handleDialogClose: Function;
}
function CustomDialog({ children, isOpen, title, handleDialogClose }: Props) {
  const [open, setOpen] = React.useState(isOpen);
  const { success } = useAppSelector(selectAuth);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useAppSelector(selectAuth);
  const [isSudan, setIsSudan] = useState(false);
  useEffect(() => {
    if (
      user &&
      user.company &&
      user.company.depositAmount &&
      user.company.depositAmount === "sudan2"
    ) {
      setIsSudan(true);
    } else setIsSudan(false);
  }, [user]);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    handleDialogClose();
  };

  if (success) {
    handleClose();
  }

  return (
    <Dialog
      title={title}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      sx={{ width: "100%" }}
    >
      <DialogTitle
        sx={{
          m: 0,
          // p: 2,
          backgroundColor: isSudan
            ? theme.palette.warning.main
            : theme.palette.primary.main,
          color: theme.palette.common.white,
          // height: 60,
        }}
      >
        {handleClose ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {title}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                // position: "absolute",
                // right: theme.direction === "ltr" ? 8 : 400,
                // top: 8,
                color: (theme) => theme.palette.grey[200],
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        ) : null}
      </DialogTitle>
      {children}
    </Dialog>
  );
}

export default CustomDialog;
