import { useRef } from "react";
import { toCanvas } from "html-to-image";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fetchEmployees,
  generateLink,
  selectProcesses,
  setSelectedEmployee,
  sharedEmployees,
} from "../processSlices";
import AuthSkeleton from "../../auth/AuthSkeleton";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { NavLink as RouterLink, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  Tooltip,
  useTheme,
  Stack,
  Box,
  Container,
  Typography,
  IconButton,
  Fab,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  StyledTableRow,
  StyledTableCellLabelTemplate,
  StyledTableCellValueTemplate,
  LabelTemplate,
} from "../../../styles/tableStyles";
import Image from "mui-image";
import {
  ArrowBack,
  ArrowForward,
  Download,
  Edit,
  List,
  Share,
  ThumbUp,
} from "@mui/icons-material";
import { Employee, EmployeeStatus, ShortLink } from "../types/processTypes";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import {
  changePageTitle,
  selectPreference,
} from "../../preferences/preferencesSlice";
import { useTranslation } from "react-i18next";

import CustomDialog from "../../../components/modals/CustomDialog";
import ShareCvs from "../components/ShareCvs";
// import { selectAuth } from "../../auth/authSlice";
import { CvMaker } from "./CvMaker";
import { PostApplied } from "./cv/PostApplied";
import { Detail } from "./cv/Detail";
import { CvSkills } from "./cv/CvSkills";
import { CvLanguage } from "./cv/CvLanguage";
import { CvExperience } from "./cv/CvExperience";
import jsPDF from "jspdf";

function CvDetailTemplate() {
  const { link } = useParams() as {
    link: string;
  };
  const dispatch = useAppDispatch();
  const { t: tt } = useTranslation();
  const theme = useTheme();

  const { searchText } = useAppSelector(selectPreference);
  const [open, setOpen] = useState(false);
  const [shortLink, setShortLink] = useState<ShortLink>({});
  const [isAgentTemplate, setIsAgentTemplate] = useState<boolean>(false);
  // const { user } = useAppSelector(selectAuth);
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [headFontColor, setHeadFontColor] = useState("#ffffff");
  const [headBgColor, setHeadBgColor] = useState(theme.palette.primary.main);
  const [labelFontColor, setLabelFontColor] = useState(
    theme.palette.primary.main
  );
  const [labelBgColor, setLabelBgColor] = useState("#ffffff");
  const [valueFontColor, setValueFontColor] = useState(
    theme.palette.secondary.main
  );
  const [valueBgColor, setValueBgColor] = useState("#ffffff");

  const [cvTemplate, setCvTemplate] = useState<LabelTemplate>({
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
  });
  useEffect(() => {
    setCvTemplate({
      headFontColor,
      headBgColor,
      labelFontColor,
      labelBgColor,
      valueFontColor,
      valueBgColor,
    });
  }, [
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
  ]);

  const {
    selectedEmployee: currentEmployee,
    employeesWithCount: { employees },
    selectedShortLink,
    success,
    loading,
  } = useAppSelector(selectProcesses);

  const renderCvFormat = (remarks: string) => {
    if (remarks.length > 0) {
      //console.log(remarks);
      const templateColors = remarks.split(",");
      setBackgroundColor(templateColors[0]);
      setHeadFontColor(templateColors[1]);
      setHeadBgColor(templateColors[2]);
      setLabelFontColor(templateColors[3]);
      setLabelBgColor(templateColors[4]);
      setValueFontColor(templateColors[5]);
      setValueBgColor(templateColors[6]);
    }
  };

  useEffect(() => {
    if (currentEmployee) {
      if (
        currentEmployee.prospects &&
        currentEmployee.prospects[0] &&
        currentEmployee.prospects[0].remarks
      ) {
        renderCvFormat(currentEmployee.prospects[0].remarks as string);
      } else if (currentEmployee.owner && currentEmployee.owner.remarks) {
        renderCvFormat(currentEmployee.owner.remarks as string);
      }
    }
  }, [currentEmployee]);
  useEffect(() => {
    dispatch(changePageTitle("Cvs Detail"));
    if (!employees || employees.length === 0 || searchText) {
      if (link && link.length === 6) {
        dispatch(
          sharedEmployees({
            link: link,
            refreshList: "network-only",
          })
        );
      } else {
        dispatch(
          fetchEmployees({
            searchText: "",
            skip: 0,
            take: 500,
            status: EmployeeStatus.new,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, link]);

  useEffect(() => {
    if (employees && employees.length > 0) {
      if (currentEmployee.givenName === "") {
        dispatch(setSelectedEmployee(employees[0]));
      } else {
        let indexOf = employees.findIndex(
          (e: Employee) => e._id === currentEmployee._id
        );
        dispatch(setSelectedEmployee(employees[indexOf]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employees]);

  const previousEmployee = () => {
    let indexOf = employees.findIndex(
      (e: Employee) => e._id === currentEmployee._id
    );
    if (indexOf === 0) indexOf = employees.length - 1;
    else indexOf--;
    dispatch(setSelectedEmployee(employees[indexOf]));
  };
  const nextEmployee = () => {
    let indexOf = employees.findIndex(
      (e: Employee) => e._id === currentEmployee._id
    );
    if (indexOf === employees.length - 1) indexOf = 0;
    else indexOf++;
    dispatch(setSelectedEmployee(employees[indexOf]));
  };

  // useEffect(() => {
  //   dispatch(changePageTitle("Cvs Detail"));
  //   if (!employees || employees.length === 0 || searchText) {
  //     if (link && link.length === 6) {
  //       dispatch(
  //         sharedEmployees({
  //           link: link,
  //           refreshList: "network-only",
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         fetchEmployees({
  //           searchText: "",
  //           skip: 0,
  //           take: 500,
  //           status: EmployeeStatus.new,
  //         })
  //       );
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, searchText, link]);

  const shareSingle = (employeeId: string) => {
    dispatch(generateLink({ employeeIds: [employeeId] }));
  };
  const dialogClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (success && selectedShortLink && selectedShortLink.link) {
      setOpen(true);
      setShortLink(selectedShortLink);
    }
  }, [success, selectedShortLink]);
  const shareCV = () => {};

  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const handleGeneratePdf = () => {
    if (ref.current === null || ref2.current === null) {
      return;
    }
    const fileName = currentEmployee.givenName as string;

    //doc.setFont("Open-Sans", "normal");
    //doc.addPage();
    const doc = new jsPDF("p", "mm", "a4", false);
    toCanvas(ref.current)
      .then((canvas) => {
        const imgWidth = 200;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const imgData = canvas.toDataURL("image/png");
        doc.addImage({
          imageData: imgData,
          format: "PNG",
          x: 4,
          y: 4,
          width: imgWidth,
          height: imgHeight,
          alias: "alias1",
          compression: "FAST",
        });

        doc.addPage();
        toCanvas(ref2.current as HTMLDivElement)
          .then((canvas2) => {
            const imgWidth2 = 200;
            const imgHeight2 = (canvas.height * imgWidth2) / canvas2.width;
            const imgData2 = canvas2.toDataURL("image/png");
            //console.log(imgData);
            doc.addImage({
              imageData: imgData2,
              format: "PNG",
              x: 4,
              y: 4,
              width: imgWidth2,
              height: imgHeight2,
              alias: "alias2",
              compression: "FAST",
            });
            doc.save(`${fileName}.pdf`);
            // doc.save("download.pdf", { returnPromise: true })
            //   .then((pdfFile) => {

            // });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // doc.html(ref.current, {
    //   async callback(doc) {
    //     await doc.save("document");
    //   },
    //   width: 500,
    // });
  };

  const changeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgentTemplate(!isAgentTemplate);
  };

  return (
    <>
      <Grid container>
        {/* <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "flex-end",
          }}
        ></Grid> */}
        <Grid item sx={{ p: 1 }} xs={8}>
          {employees && employees.length > 0 ? (
            <Container maxWidth="md">
              <Box>
                {!link && (
                  <>
                    <Stack direction="row" justifyContent="space-between">
                      <>
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="flex-start"
                          sx={{
                            width: "100%",
                            "& > :not(style)": {
                              m: 0,
                              ml: 1,
                            },
                          }}
                        >
                          <Tooltip title="Previous" sx={{ mt: 30 }}>
                            <Fab component="span" color="primary">
                              <IconButton
                                color="inherit"
                                onClick={previousEmployee}
                                size="large"
                              >
                                <ArrowBack />
                              </IconButton>
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Show List">
                            <Button
                              color="secondary"
                              component={RouterLink}
                              variant="contained"
                              to={`/app/cvs`}
                              size="small"
                            >
                              <List />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Download CV">
                            <Button
                              color="secondary"
                              onClick={handleGeneratePdf}
                              variant="contained"
                              size="small"
                            >
                              <Download />
                            </Button>
                          </Tooltip>
                        </Stack>
                      </>

                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                        sx={{
                          width: "100%",
                          "& > :not(style)": {
                            m: 0,
                            ml: 1,
                          },
                        }}
                      >
                        <Tooltip title="Select Candidate">
                          <Button
                            color="secondary"
                            onClick={shareCV}
                            variant="contained"
                            size="small"
                          >
                            <ThumbUp />
                          </Button>
                        </Tooltip>
                        {!link && (
                          <Tooltip title="Edit Detail">
                            <Button
                              color="secondary"
                              component={RouterLink}
                              variant="contained"
                              to={`/app/employee/${
                                currentEmployee._id as string
                              }`}
                              size="small"
                            >
                              <Edit />
                            </Button>
                          </Tooltip>
                        )}
                        <Tooltip title="Share Candidate">
                          <Button
                            color="secondary"
                            onClick={() =>
                              shareSingle(currentEmployee._id as string)
                            }
                            variant="contained"
                            size="small"
                          >
                            <Share />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Next" sx={{ mt: 30 }}>
                          <Fab component="span" color="primary">
                            <IconButton
                              color="inherit"
                              onClick={nextEmployee}
                              size="large"
                            >
                              <ArrowForward />
                            </IconButton>
                          </Fab>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </>
                )}

                <Box
                  component={Paper}
                  elevation={3}
                  sx={{ display: "block", overflow: "hidden", width: "100%" }}
                >
                  <div dir={theme.direction === "rtl" ? "rtl" : "ltr"}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        p: 1,
                        backgroundColor: backgroundColor,
                      }}
                      ref={ref}
                    >
                      <Grid item xs={12} sx={{ height: "150px" }}>
                        {currentEmployee.prospects &&
                        currentEmployee.prospects[0] ? (
                          <img
                            width="100%"
                            height="100%"
                            src={
                              currentEmployee.prospects[0].header
                                ? (currentEmployee.prospects[0]
                                    .header as string)
                                : "/noHeader.png"
                            }
                            alt="No agent header"
                          />
                        ) : (
                          <img
                            width="100%"
                            height="100%"
                            src={
                              currentEmployee &&
                              currentEmployee.owner &&
                              currentEmployee.owner.header
                                ? (currentEmployee.owner.header as string)
                                : "/noHeader.png"
                            }
                            alt="No Agency/Agent header"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ mt: 1 }}
                      >
                        {/* Short Photo */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "4px",
                          }}
                        >
                          <Image
                            src={
                              currentEmployee && currentEmployee.facePhoto
                                ? (currentEmployee.facePhoto as string)
                                : "/blankShort.jpg"
                            }
                            alt={currentEmployee.passportNumber}
                            width={180}
                            height={180}
                            shift="top"
                            distance="2rem"
                            shiftDuration={320}
                            style={{
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                        {cvTemplate && currentEmployee && (
                          <PostApplied
                            currentEmployee={currentEmployee}
                            template={cvTemplate}
                          />
                        )}
                        {cvTemplate && currentEmployee && (
                          <Detail
                            currentEmployee={currentEmployee}
                            template={cvTemplate}
                          />
                        )}
                        {cvTemplate && currentEmployee && (
                          <CvSkills
                            currentEmployee={currentEmployee}
                            template={cvTemplate}
                          />
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {loading === "pending" ? (
                          <AuthSkeleton />
                        ) : (
                          <Stack
                            spacing={1}
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            {cvTemplate && currentEmployee && (
                              <CvLanguage
                                currentEmployee={currentEmployee}
                                template={cvTemplate}
                              />
                            )}
                            {cvTemplate && currentEmployee && (
                              <CvExperience
                                currentEmployee={currentEmployee}
                                template={cvTemplate}
                              />
                            )}

                            <Image
                              src={
                                currentEmployee && currentEmployee.standPhoto
                                  ? (currentEmployee.standPhoto as string)
                                  : "/blankLong.jpg"
                              }
                              alt={currentEmployee.passportNumber}
                              width="95%"
                              height="600px"
                              shift="top"
                              distance="2rem"
                              shiftDuration={320}
                              style={{
                                borderRadius: "2%",
                                objectFit: "fill",
                              }}
                            />
                          </Stack>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {cvTemplate && currentEmployee && (
                          <TableContainer
                            component={Paper}
                            sx={{
                              marginTop: 1,
                              borderRadius: "10px",
                              width: "100%",
                            }}
                          >
                            <Table size="small" aria-label="simple table">
                              <TableBody>
                                <StyledTableRow key="0">
                                  <StyledTableCellLabelTemplate
                                    template={cvTemplate}
                                    sx={{
                                      width: "20%",
                                    }}
                                  >
                                    {tt("remark")}
                                  </StyledTableCellLabelTemplate>
                                  <StyledTableCellValueTemplate
                                    sx={{ width: "80%" }}
                                    template={cvTemplate}
                                  >
                                    {
                                      currentEmployee.additionalData
                                        ?.notes as string
                                    }
                                  </StyledTableCellValueTemplate>
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Grid>
                    </Grid>
                    {/* ADD_PAGE */}
                    <Box ref={ref2}>
                      {currentEmployee && currentEmployee.passportScan && (
                        <Image
                          src={currentEmployee.passportScan as string}
                          alt={currentEmployee.passportNumber}
                          width="100%"
                          height="800px"
                          shift="top"
                          distance="2rem"
                          shiftDuration={320}
                          style={{
                            borderRadius: "2%",
                            marginTop: 40,
                            objectFit: "fill",
                          }}
                        />
                      )}
                    </Box>
                  </div>
                </Box>
              </Box>
            </Container>
          ) : loading === "pending" ? (
            <AuthSkeleton />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Image
                src="/No_Data.svg"
                alt="No Data"
                width={250}
                height={120}
                shift="top"
                distance="2rem"
                shiftDuration={320}
                style={{
                  borderRadius: "50%",
                }}
              />
              <Typography variant="h5">No CV Found</Typography>
            </Box>
          )}
          <CustomDialog
            title="Shared Links"
            isOpen={open}
            handleDialogClose={dialogClose}
          >
            <ShareCvs
              link={shortLink.link as string}
              employees={
                shortLink.employees
                  ?.map((e) => e.givenName)
                  .join(", ") as string
              }
            />
          </CustomDialog>{" "}
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "flex-start",
          }}
        >
          <Stack alignItems="center">
            <FormControlLabel
              style={{ marginTop: 2 }}
              control={
                <Switch
                  checked={isAgentTemplate}
                  onChange={changeCheckBox}
                  inputProps={{ "aria-label": "controlled" }}
                  name="selectAll"
                  color="secondary"
                />
              }
              label="Show CV Maker"
            />
            {isAgentTemplate && (
              <CvMaker
                setBGC={setBackgroundColor}
                setHFC={setHeadFontColor}
                setHBC={setHeadBgColor}
                setLFC={setLabelFontColor}
                setLBC={setLabelBgColor}
                setVFC={setValueFontColor}
                setVBC={setValueBgColor}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default CvDetailTemplate;
