import Grid from "@mui/material/Grid";
import { Box, Stack } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import { BasicPostAppliedSingleValue } from "./components/basic/BasicPostApplied";
import { BasicDetail } from "./components/basic/BasicDetail";
import { BasicLanguage } from "./components/basic/BasicLanguage";
import { BasicExperienceNoHeader } from "./components/basic/BasicExperience";
import { BasicSkillsTwoRows } from "./components/basic/BasicSkills";
import { BasicPassport } from "./components/basic/BasicPassport";
import { BasicFullName } from "./components/basic/BasicFullName";
import { BasicFace } from "./components/basic/BasicFace";
import { BasicStand } from "./components/basic/BasicStand";
import { BasicRemarks } from "./components/basic/BasicRemarks";
import { BasicReference } from "./components/basic/BasicReference";
import { BasicPassportScan } from "./components/basic/BasicPassportScan";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
function BasicTopExperienceTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  //a08745;f2f1f0 fffcf2
  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "90%",
              mb: 1,
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            justifyContent="center"
            alignItems="center"
          >
            {/* Short Photo */}
            <Grid item xs={3}>
              {cvTemplate && currentEmployee && (
                <BasicFace
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid>

            <Grid container item xs={9}>
              <Stack spacing={cvTemplate.spacing} sx={{ width: "100%" }}>
                {/* <BasicTitleSingleLang template={cvTemplate} /> */}
                <BasicExperienceNoHeader
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                  type="country"
                />
                <BasicReference
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
                {cvTemplate && currentEmployee && (
                  <BasicPostAppliedSingleValue
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    showHeader={false}
                    showProfession={true}
                    showSalary={true}
                    showContractPeriod={true}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicFullName
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicDetail
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showNationality={true}
                showReligion={true}
                showDateOfBirth={true}
                showAge={true}
                showPlaceOfBirth={true}
                showMaritalStatus={true}
                showNumberOfChildren={true}
                showWeightHeight={true}
                showComplexion={true}
                showEducation={false}
                showLivingTown={false}
                showEmployeeTelephone={false}
                showContactName={false}
                showContactTelephone={false}
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicLanguage
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showEducation={true}
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicSkillsTwoRows
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack
              spacing={cvTemplate.spacing}
              sx={{
                alignItems: "center",
              }}
            >
              {cvTemplate && currentEmployee && (
                <BasicPassport
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                  showHeader={true}
                  showPassportNumber={true}
                  showIssueDate={true}
                  showPlaceOfIssue={true}
                  showExpiryDate={true}
                />
              )}
              <Stack direction="row" spacing={1}>
                {cvTemplate && currentEmployee && (
                  <BasicStand
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                )}
              </Stack>
              {/* {cvTemplate && currentEmployee && (
                <BasicExperience
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                  type="basic"
                />
              )} */}
            </Stack>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12}>
              {cvTemplate && currentEmployee && (
                <BasicRemarks
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid>
            {/* <Grid item xs={2}>
              {cvTemplate && currentEmployee && (
                <BasicLocation
                  type="location"
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
      <Stack
        id="passportData"
        sx={{
          p: 1,
          pt: 1,
          backgroundColor: backgroundColor,
          minHeight: "1500px",
        }}
      >
        {cvTemplate.addPassportHeader && (
          <Box
            sx={{
              height: "90%",
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Box>
        )}
        {cvTemplate && currentEmployee && (
          <Box
            sx={{
              mt: 15,
            }}
          >
            <BasicPassportScan
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default BasicTopExperienceTemplate;
