import Grid from "@mui/material/Grid";
import { Box, Stack } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import { BasicPassportShort } from "./components/basic/BasicPassport";
import { BasicReferenceOnly } from "./components/basic/BasicReference";
import { InterStand } from "./components/inter/InterStand";
import { InterExperience } from "./components/inter/InterExperience";
import { InterPostApplied } from "./components/inter/InterPostApplied";
import { InterDetail } from "./components/inter/InterDetail";
import { InterPassportScan } from "./components/inter/InterPassportScan";
import { InterFullName } from "./components/inter/InterFullName";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";
import { InterLocation } from "./components/inter/InterLocation";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
function InterTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "100%",
              mb: 1,
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            justifyContent="center"
            alignItems="start"
          >
            <Grid item sm={6} xs={12}>
              {cvTemplate && currentEmployee && (
                <Stack spacing={cvTemplate.spacing} sx={{ width: "100%" }}>
                  <BasicReferenceOnly
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />

                  <InterStand
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />

                  <BasicPassportShort
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />

                  <InterExperience
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                </Stack>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              {cvTemplate && currentEmployee && (
                <Stack spacing={cvTemplate.spacing} sx={{ width: "100%" }}>
                  <InterFullName
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />

                  <InterPostApplied
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />

                  <InterDetail
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />

                  <InterPassportScan
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />

                  <InterLocation
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    type="location"
                  />
                </Stack>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default InterTemplate;
