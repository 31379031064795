import { Table, TableBody, useTheme, TableHead } from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  showHeader?: boolean | false;
  showProfession?: boolean | false;
  showSalary?: boolean | false;
  showContractPeriod?: boolean | false;
  showEmployeeTelephone?: boolean | false;
  showContactName?: boolean | false;
  showContactTelephone?: boolean | false;
}

export const BasicContact = ({
  currentEmployee,
  template,
  showHeader,
  showProfession,
  showSalary,
  showContractPeriod,
  showEmployeeTelephone,
  showContactName,
  showContactTelephone,
}: Props) => {
  const theme = useTheme();
  return (
    <StyledTableContainer
      template={template}
      sx={{
        marginTop: template.spacing,
      }}
    >
      <Table size="small" aria-label="simple table">
        {showHeader && (
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("workInformation", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("workInformation", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
        )}
        <TableBody>
          {showProfession && (
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate template={template}>
                {tt("profession", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.additionalData?.appliedProfession as string,
                  "en"
                )
                  .toLowerCase()
                  .toUpperCase()}
              </StyledTableCellValueTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.additionalData?.appliedProfession as string,
                  "ar"
                )
                  .toLowerCase()
                  .toUpperCase()}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("profession", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          )}
          {showSalary && (
            <StyledTableRowTemplate template={template} key="2">
              <StyledTableCellLabelTemplate template={template}>
                {tt("salary", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {currentEmployee.additionalData?.salary?.toLocaleString(
                  theme.direction === "rtl" ? "ar-SA" : "en-US"
                ) +
                  " " +
                  tt(
                    (
                      currentEmployee.additionalData?.currency as string
                    ).toLowerCase(),
                    "en"
                  )}
              </StyledTableCellValueTemplate>
              <StyledTableCellValueTemplate template={template}>
                {currentEmployee.additionalData?.salary?.toLocaleString(
                  theme.direction === "rtl" ? "ar-SA" : "en-US"
                ) +
                  " " +
                  tt(
                    (
                      currentEmployee.additionalData?.currency as string
                    ).toLowerCase(),
                    "ar"
                  )}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("salary", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          )}
          {showContractPeriod && (
            <StyledTableRowTemplate template={template} key="1">
              <StyledTableCellLabelTemplate template={template}>
                {tt("contractPeriod", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.additionalData?.contractPeriod as string,
                  "en"
                )}
              </StyledTableCellValueTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.additionalData?.contractPeriod as string,
                  "ar"
                )}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("contractPeriod", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export const BasicBeneficiary = ({
  currentEmployee,
  template,
  showHeader,
}: Props) => {
  // const theme = useTheme();
  return (
    <StyledTableContainer
      template={template}
      sx={{
        marginTop: template.spacing,
      }}
    >
      <Table size="small" aria-label="simple table">
        {showHeader && (
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={1}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("workInformation", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={1}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("workInformation", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
        )}
        <TableBody>
          <StyledTableRowTemplate template={template} key="3">
            <StyledTableCellLabelTemplate template={template}>
              BENEFICIARY NAME
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate template={template}>
              {currentEmployee.contactPerson
                ? (currentEmployee.contactPerson?.displayName as string)
                : ""}
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
          <StyledTableRowTemplate template={template} key="2">
            <StyledTableCellLabelTemplate template={template}>
              ADDRESS
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate template={template}>
              {currentEmployee.contactPerson &&
              currentEmployee.contactPerson.address
                ? (currentEmployee.contactPerson.address
                    ?.streetAddress as string)
                : ""}
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>

          <StyledTableRowTemplate template={template} key="1">
            <StyledTableCellLabelTemplate template={template}>
              BENEFICIARY RELATIONSHIP
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate template={template}>
              {currentEmployee.contactPerson
                ? (currentEmployee.contactPerson?.kinship as string)
                : ""}
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
          <StyledTableRowTemplate template={template} key="1">
            <StyledTableCellLabelTemplate template={template}>
              CONTACT NO.
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate template={template}>
              {currentEmployee.contactPerson &&
              currentEmployee.contactPerson.address
                ? (currentEmployee.contactPerson.address?.telephone as string)
                : ""}
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
