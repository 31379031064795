import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Form, FormikProps, Formik } from "formik";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HeaderIcon from "@mui/icons-material/Topic";
import CorporateFareRounded from "@mui/icons-material/CorporateFareRounded";
import { Grid, LinearProgress, useTheme } from "@mui/material";
import Save from "@mui/icons-material/Save";
import Accordion from "@mui/material/Accordion";
import {
  StyledAccordionSummary,
  StyledTab,
} from "../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { registerSchema } from "./validation";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Toast from "../../components/Layout/Toast";
import { addCompany, getCompany, selectSetups } from "./setupSlices";
import { Company } from "./types/setupTypes";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { changePageTitle } from "../preferences/preferencesSlice";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { AddressDetail } from "../process/components/AddressDetail";
import { PartnersEntry } from "./PartnersEntry";
import CompanyHeader from "./components/CompanyHeader";
interface CompanyProfileProps {
  companyId?: string | undefined;
}
export const CompanyEntry = ({ companyId }: CompanyProfileProps) => {
  const [value, setValue] = useState("0");
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const {
    loading,
    error,
    success,
    selectedCompany: companyProfile,
  } = useAppSelector(selectSetups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      changePageTitle(
        `${
          companyProfile !== null
            ? companyProfile?.displayName
            : "Company Entry"
        }`
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, companyProfile]);

  useEffect(() => {
    if (companyId && companyId.length > 0) {
      dispatch(getCompany(companyId as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  // function resetFields() {
  //   dispatch(resetCompany());
  // }

  return (
    <>
      <Helmet>
        <title>
          {companyProfile !== null
            ? companyProfile.displayName
            : "Company Entry"}{" "}
          | EnjazPlus
        </title>
      </Helmet>

      <Box sx={{ mb: 3 }}>
        <>
          <Formik
            enableReinitialize={true}
            initialValues={companyProfile as Company}
            validationSchema={registerSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              dispatch(addCompany(values));
            }}
          >
            {(props: FormikProps<Company>) => (
              <Form>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={!props.isValid}
                >
                  <Save />
                </Button>
                <Accordion sx={{ m: 1 }} expanded={true}>
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Profile Detail</Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item md={8} xs={12}>
                        <FormikTextField formikKey="displayName" label="Name" />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormikTextField formikKey="email" label="Email" />
                      </Grid>
                      <Grid item md={8} xs={12}>
                        <FormikTextField
                          formikKey="nameAmharic"
                          label="Agency Name(Amharic)"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormikTextField
                          formikKey="managerNameAmharic"
                          label="Manager Name(Amharic)"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormikTextField
                          formikKey="licenseNumber"
                          label="License Number"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormikTextField
                          formikKey="enjazPlusLicense"
                          label="EnjazPlus License Status"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormikTextField
                          formikKey="depositAmount"
                          label="Agency Type"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField formikKey="remarks" label="Remarks" />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormikTextField
                          formikKey="address.mobile"
                          label="Mobile"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormikTextField
                          formikKey="address.alternateMobile"
                          label="Alternate Mobile"
                        />
                      </Grid>
                    </Grid>
                    <AddressDetail />
                  </AccordionDetails>
                </Accordion>
                <br />
                {loading === "pending" && <LinearProgress color="secondary" />}
                {success && <Toast severity="success">{success.message}</Toast>}
                {error && <Toast severity="error">{error.message}</Toast>}
              </Form>
            )}
          </Formik>

          <TabContext value={value}>
            <Box
              sx={{
                my: 1,
                backgroundColor: theme.palette.primary.main,
                borderRadius: "4px",
              }}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="lab API tabs example"
              >
                <StyledTab
                  icon={<CorporateFareRounded />}
                  label="Partner Agents"
                  value="0"
                />
                <StyledTab
                  icon={<HeaderIcon />}
                  label="Headers and Footers"
                  value="1"
                />
              </TabList>
            </Box>
            <TabPanel value="0">
              <PartnersEntry companyData={companyProfile} />
            </TabPanel>
            <TabPanel value="1">
              <CompanyHeader companyData={companyProfile} />
            </TabPanel>
          </TabContext>

          {/* <Tabs
                  sx={{ m: 1, p: 1 }}
                  value={value}
                  onChange={handleChange}
                  scrollButtons="auto"
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  <Tab icon={<PhoneIcon />} label="Address" />

                  <Tab
                    icon={<CorporateFareRounded />}
                    label="Partner Agencies"
                  />
                </Tabs>

                {value === 0 && (
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <FormikTextField
                        formikKey="address.mobile"
                        label="Mobile"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormikTextField
                        formikKey="address.telephone"
                        label="Telephone"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormikTextField
                        formikKey="address.email"
                        label="Email"
                      />
                    </Grid>
                  </Grid>
                )} */}
        </>
      </Box>

      {/* <Divider variant="middle" sx={{ my: 2 }} /> */}
    </>
  );
};
