import { useEffect, useState } from "react";
import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Company } from "../../features/setups/types/setupTypes";
import { selectSetups } from "../../features/setups/setupSlices";
import { fetchCompanies } from "../../features/setups/setupSlices";
import { setCompany as setCurrentCompany } from "../../features/setups/setupSlices";

interface Props {
  setCompany: any;
  searchText: string;
  companyId?: string | undefined;
}
export const CvMakerCompanyFilter = ({
  setCompany,
  searchText,
  companyId,
}: Props) => {
  const dispatch = useAppDispatch();

  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [companies, setCompanies] = useState<Company[]>([]);
  //const { loading: loadingForeignAgent } = useAppSelector(selectAuth);
  const {
    companiesWithCount: { companies: allCompanies },
    loading: companiesLoading,
  } = useAppSelector(selectSetups);

  useEffect(() => {
    if (searchText && searchText.length > 2) {
      dispatch(fetchCompanies({ searchText, skip: 0, take: 10 }));
    }
  }, [dispatch, searchText]);

  useEffect(() => {
    if (allCompanies.length > 0) {
      setCompanies(allCompanies);
    }
  }, [allCompanies]);

  useEffect(() => {
    if (companies?.length > 0) {
      const defaultCompany =
        companyId && companyId.length > 0
          ? (companies.find((c) => c._id === companyId) as Company)
          : (companies[0] as Company);

      setCompany(defaultCompany);
      setSelectedCompany(defaultCompany);
      dispatch(setCurrentCompany({ ...defaultCompany }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, setCompany, companyId]);
  return (
    <>
      {companiesLoading === "pending" ? (
        <LinearProgress color="secondary" />
      ) : (
        // Search and Link your partner + " " + option.email
        <Autocomplete
          id="companyId"
          options={companies}
          value={selectedCompany}
          getOptionLabel={(option) => option.displayName}
          sx={{ mt: 0 }}
          defaultValue={companies[0]}
          onChange={(e, value) => {
            setSelectedCompany(value as Company);
            setCompany(value as Company);
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              label="Company"
              name="companyId"
              {...params}
              variant="outlined"
            />
          )}
        />
      )}
    </>
  );
};
