import { Document, Page, StyleSheet, Font } from "@react-pdf/renderer";
import { Employee } from "../../../features/process/types/processTypes";
import { BarcodeHeader } from "./BarcodeHeader";
import ListRow from "./ListRow";
import { AuthUser } from "../../../features/auth/types/authType";
import ListRowCommission from "./ListRowCommission";
import { BarcodeHeaderCommission } from "./BarcodeHeaderCommission";
import { Company } from "../../../features/setups/types/setupTypes";

Font.register({
  family: "Noto",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Naskh_Arabic/NotoNaskhArabic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Open Sans",
  format: "truetype",
  fonts: [
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      src: "/fonts/open-sans-regular.ttf",
    },
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      src: "/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
interface Props {
  employee: Employee;
  user: AuthUser;
  company: Company;
}

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
});
export const SaudiApplication = ({ employee, user, company }: Props) => {
  const agency = employee.owner;
  return (
    <Document
      creator="pinnasofts"
      author="pinnaSofts"
      title="Enjaz+"
      subject="Embassy Application"
    >
      <Page size="A4" style={styles.page}>
        {employee &&
          employee.visa &&
          employee.passportNumber &&
          employee.passportNumber.length > 0 &&
          employee.embassyProcess &&
          employee.embassyProcess.enjazNumber && (
            <>
              {agency &&
              agency.depositAmount &&
              agency.depositAmount.length > 0 ? (
                <>
                  <BarcodeHeaderCommission
                    employee={employee}
                    user={user}
                    company={company}
                  />
                  <ListRowCommission employee={employee} company={company} />
                </>
              ) : (
                <>
                  <BarcodeHeader employee={employee} user={user} />
                  <ListRow employee={employee} company={company} />
                </>
              )}
            </>
          )}
      </Page>
    </Document>
  );
};

//  <View style={styles.row}>
//    <Text style={[styles.cellLeft35, { width: "40%" }]}>
//      {" "}
//      Duration of stay in the kingdom :
//    </Text>
//    <Text style={styles.cellSpace1}></Text>
//    <Text style={styles.cellRight20}>:مدة الإقامة بالمملكة</Text>
//    <Text style={styles.cellSpace1}></Text>
//    <Text style={styles.cellLeft15}>Arrival Date</Text>
//    <Text style={styles.cellSpace1}></Text>
//    <Text style={styles.cellRight15}>:تاريخ الوصو</Text>
//    <Text style={styles.cellSpace1}></Text>
//    <Text style={styles.cellLeft20}>Departure Date:</Text>
//    <Text style={styles.cellSpace1}></Text>
//    <Text style={styles.cellRight15}>:تاريخ المغادرة</Text>
//  </View>;
