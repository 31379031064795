import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  useTheme,
  Stack,
} from "@mui/material";
import { differenceInMonths, format } from "date-fns";
import Image from "mui-image";

import {
  StyledTableRow,
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
} from "../../../../styles/tableStyles";
import { useTranslation } from "react-i18next";
import { Employee } from "../../types/processTypes";
interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const Detail = ({ currentEmployee, template }: Props) => {
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");
  const theme = useTheme();
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "10px",
          width: "100%",
          marginTop: 1,
        }}
      >
        <Table size="medium" aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCellLabelTemplate colSpan={2} template={template}>
                {tt("detailsOfApplication")}
              </StyledTableCellLabelTemplate>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow key="0">
              <StyledTableCellLabelTemplate template={template}>
                {te("nationality")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                <Stack direction="row" justifyContent="center" spacing={1}>
                  {te(currentEmployee.additionalData?.nationality as string)}
                  <Image
                    src="/ethiopiaFlag.png"
                    alt={currentEmployee.passportNumber}
                    width="70px"
                    height="30px"
                    shift="top"
                    distance="2rem"
                    shiftDuration={320}
                    style={{
                      objectFit: "fill",
                    }}
                  />
                </Stack>
              </StyledTableCellValueTemplate>
            </StyledTableRow>
            <StyledTableRow key="1">
              <StyledTableCellLabelTemplate template={template}>
                {te("religion")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {te(currentEmployee.additionalData?.religion as string)}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
            <StyledTableRow key="2">
              <StyledTableCellLabelTemplate template={template}>
                {tt("dateOfBirth")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {theme.direction === "ltr"
                  ? format(
                      new Date(currentEmployee.dateOfBirth as Date),
                      "dd-MMM-yyyy"
                    )
                  : new Date(
                      currentEmployee.dateOfBirth as Date
                    ).toLocaleDateString("ar-SA", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
            <StyledTableRow key="3">
              <StyledTableCellLabelTemplate template={template}>
                {tt("age")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {Math.floor(
                  differenceInMonths(
                    new Date(),
                    new Date(currentEmployee.dateOfBirth as Date)
                  ) / 12
                ).toLocaleString(theme.direction === "rtl" ? "ar-SA" : "en-US")}
              </StyledTableCellValueTemplate>
            </StyledTableRow>

            <StyledTableRow key="5">
              <StyledTableCellLabelTemplate template={template}>
                {te("maritalStatus")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {te(currentEmployee.additionalData?.maritalStatus as string)}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
            <StyledTableRow key="6">
              <StyledTableCellLabelTemplate template={template}>
                {tt("numberOfChildren")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {currentEmployee.additionalData?.numberOfChildren?.toLocaleString(
                  theme.direction === "rtl" ? "ar-SA" : "en-US"
                )}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
            <StyledTableRow key="7">
              <StyledTableCellLabelTemplate template={template}>
                {tt("weight") + "/" + tt("height")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {(currentEmployee.additionalData?.weight === 0
                  ? "-"
                  : currentEmployee.additionalData?.weight?.toLocaleString(
                      theme.direction === "rtl" ? "ar-SA" : "en-US"
                    ) + tt("kg")) +
                  "/" +
                  (currentEmployee.additionalData?.height === 0
                    ? "-"
                    : currentEmployee.additionalData?.height?.toLocaleString(
                        theme.direction === "rtl" ? "ar-SA" : "en-US"
                      ) + tt("meter"))}
              </StyledTableCellValueTemplate>
            </StyledTableRow>

            <StyledTableRow key="9">
              <StyledTableCellLabelTemplate template={template}>
                {te("complexion")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {te(currentEmployee.additionalData?.complexion as string)}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
