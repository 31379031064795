import { ArrowDropDown, Print } from "@mui/icons-material";

import { Button, Menu, MenuItem, useTheme } from "@mui/material";
import { useState } from "react";
import { StyledListItemText } from "../../../styles/listStyled";

interface Props {
  printContractTranslation: Function;
  printSaudiApplication: Function;
  downloadContractTranslation: Function;
  downloadSaudiApplication: Function;
}

export function EmployeeEntryMenu({
  printContractTranslation,
  printSaudiApplication,
  downloadContractTranslation,
  downloadSaudiApplication,
}: Props) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: theme.direction === "ltr" ? "right" : "left",
        }}
        id={"otherPrintsId"}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: theme.direction === "ltr" ? "right" : "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.default",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {[
          <MenuItem key="14" onClick={() => printSaudiApplication()}>
            <StyledListItemText active={0}>Print First Page</StyledListItemText>
          </MenuItem>,
          <MenuItem key="15" onClick={() => printContractTranslation()}>
            <StyledListItemText active={0}>
              Print Contract Translation
            </StyledListItemText>
          </MenuItem>,
          <MenuItem key="16" onClick={() => downloadSaudiApplication()}>
            <StyledListItemText active={0}>
              Download First Page
            </StyledListItemText>
          </MenuItem>,
          <MenuItem key="17" onClick={() => downloadContractTranslation()}>
            <StyledListItemText active={0}>
              Download Contract Translation
            </StyledListItemText>
          </MenuItem>,
        ]}
      </Menu>

      <Button
        aria-label="Other Prints"
        aria-controls={"otherPrintsId"}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        size="medium"
        color="secondary"
        variant="contained"
        // sx={{ alignSelf: "flex-end", marginBottom: "-30px" }}
        sx={{
          "& > :not(style)": {
            mt: 0,
            mr: theme.direction === "ltr" ? 1 : 0,
            ml: theme.direction === "rtl" ? 1 : 0,
          },
        }}
        startIcon={<Print />}
        endIcon={<ArrowDropDown />}
      >
        Print/Download
      </Button>
    </>
  );
}
