import {
  Button,
  // MenuItem,
  // Select,
  // SelectChangeEvent,
  // Typography,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import {
  selectPreference,
  setLanguage,
} from "../../features/preferences/preferencesSlice";
export type LocaleTypes = {
  lang: string;
  nativeName: string;
};
export default function Language() {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const { lang } = useAppSelector(selectPreference);
  const langs = [
    { lang: "en", nativeName: "Eng", code: "US", icon: "usaFlag.png" },
    { lang: "ar", nativeName: "العربية", code: "SA", icon: "saudiFlag.png" },
    // { lang: "et", nativeName: "አማርኛ", code: "ET", icon: "ethiopiaFlag.png" },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    setSelectedLanguage(lang);
  }, [lang]);

  // const handleChange = (event: SelectChangeEvent<string>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   i18n.changeLanguage(value);
  //   setSelectedLanguage(value);
  //   setLanguage(value);
  //   if (i18n.language === "ar") {
  //     document.body.setAttribute("dir", "rtl");
  //     theme.direction = "rtl";
  //   } else {
  //     document.body.setAttribute("dir", "ltr");
  //     theme.direction = "ltr";
  //   }
  // };
  const handleChange = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    setSelectedLanguage(newLanguage);
    setLanguage(newLanguage);

    if (i18n.language === "en") {
      document.body.setAttribute("dir", "rtl");
      theme.direction = "rtl";
    } else {
      document.body.setAttribute("dir", "ltr");
      theme.direction = "ltr";
    }
  };
  return (
    <Button
      onClick={handleChange}
      variant="text"
      sx={{
        color: "white",
        borderColor: "white",
        p: "3px 4px",
        textTransform: "capitalize",
        fontSize: "18px",
        fontWeight: 900,
      }}
    >
      {i18n.language === "en" ? langs[1].nativeName : langs[0].nativeName}
      {/* <Typography variant="h4">
      </Typography> */}
    </Button>

    // <Tooltip
    //   sx={{ mr: 0, ml: 1 }}
    //   title={`${i18n.language === "ar" ? "To English" : "To Arabic"}`}
    // >
    //   <IconButton
    //     onClick={handleChange}
    //     color="inherit"
    //     size="large"
    //     edge="end"
    //   >
    //     {i18n.language === "ar" ? (
    //       <Avatar
    //         alt="e"
    //         src={"/english.png"}
    //         sx={{
    //           height: 20,
    //           width: 20,
    //         }}
    //       />
    //     ) : (
    //       <Avatar
    //         alt="a"
    //         src={"/arabic.png"}
    //         sx={{
    //           height: 20,
    //           width: 20,
    //         }}
    //       />
    //     )}
    //   </IconButton>
    // </Tooltip>
    // <Select
    //   value={selectedLanguage}
    //   onChange={handleChange}
    //   displayEmpty
    //   autoWidth
    //   variant="standard"
    //   sx={{
    //     color: "White",
    //     borderColor: "white",
    //     p: "6px 8px",
    //   }}
    // >
    //   {langs.map((lang) => (
    //     <MenuItem key={lang.lang} value={lang.lang}>
    //       <img
    //         loading="lazy"
    //         width="30"
    //         src={`https://flagcdn.com/w40/${lang.code.toLowerCase()}.png`}
    //         srcSet={`https://flagcdn.com/w40/${lang.code.toLowerCase()}.png 2x`}
    //         alt={lang.nativeName}
    //       />
    //       {lang.nativeName}
    //     </MenuItem>
    //   ))}

    // </Select>
  );
}
