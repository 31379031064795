import { Table, TableBody } from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";

import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const BasicRemarks = ({ currentEmployee, template }: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableBody>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  width: "20%",
                }}
              >
                {tt("remarks", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate
                sx={{ width: "60%" }}
                template={template}
              >
                {currentEmployee.additionalData?.notes as string}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  width: "20%",
                  textAlign: "right",
                }}
              >
                {tt("remarks", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export const BasicRemarksVer = ({ currentEmployee, template }: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableBody>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {tt("remarks", "en")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellValueTemplate
                sx={{
                  textAlign: "center",
                }}
                template={template}
              >
                {currentEmployee.additionalData?.notes as string}
              </StyledTableCellValueTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {tt("remarks", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
