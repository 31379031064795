import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  StyledTableRow,
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
} from "../../../../styles/tableStyles";
import { useTranslation } from "react-i18next";
import { Employee, Skills } from "../../types/processTypes";
interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const CvSkills = ({ currentEmployee, template }: Props) => {
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");
  // const theme = useTheme();
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <TableContainer
          component={Paper}
          sx={{
            marginTop: 1,
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRow key="3">
                <StyledTableCellLabelTemplate colSpan={4} template={template}>
                  {te("skills")}
                </StyledTableCellLabelTemplate>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow key="0">
                <StyledTableCellLabelTemplate template={template}>
                  {te("washing_dishes")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt("yes")}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate template={template}>
                  {te("washing_ironing_clothes")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate>
              </StyledTableRow>
              <StyledTableRow key="1">
                <StyledTableCellLabelTemplate template={template}>
                  {te("dusting_cleaning")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate template={template}>
                  {te("baby_sitting")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate>
                {/* <StyledTableCellLabelTemplate template={template}>
                  {te("driving")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(Skills.driving) >
                  -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate> */}
              </StyledTableRow>

              <StyledTableRow key="3">
                {/* <StyledTableCellLabelTemplate template={template}>
                  {te("children_care")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate> */}
              </StyledTableRow>

              <StyledTableRow key="4">
                <StyledTableCellLabelTemplate template={template}>
                  {te("cooking")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate template={template}>
                  {te("arabic_cooking")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.arabic_cooking
                  ) > -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate>
              </StyledTableRow>
              <StyledTableRow key="5">
                {/* <StyledTableCellLabelTemplate template={template}>
                  {te("tutoring_children")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.tutoring_children
                  ) > -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate> */}
                <StyledTableCellLabelTemplate template={template}>
                  {te("children_care")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate template={template}>
                  {te("sewing")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(Skills.sewing) >
                  -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate>
              </StyledTableRow>
              {/* <StyledTableRow key="6">
                <StyledTableCellLabelTemplate template={template}>
                  {te("computer")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(Skills.computer) >
                  -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate template={template}>
                  {te("decorating")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.decorating
                  ) > -1
                    ? tt("yes")
                    : tt("no")}
                </StyledTableCellValueTemplate>
              </StyledTableRow> */}

              <StyledTableRow key="7">
                <StyledTableCellLabelTemplate template={template}>
                  {te("other_skills")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate colSpan={3} template={template}>
                  {currentEmployee.education.remarks}
                </StyledTableCellValueTemplate>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
