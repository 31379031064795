import { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  Grid,
  Link,
} from "@mui/material";

import { useAppDispatch } from "../../app/hooks";
import { changePageTitle } from "../preferences/preferencesSlice";

const ImportantLinks = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changePageTitle("Important Links"));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Important Links | EnjazPlus</title>
      </Helmet>

      <Box sx={{ m: 4, py: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
              <CardActionArea
                href="https://visa.mofa.gov.sa/Account/Login/enjazcompany"
                target="_blank"
              >
                <CardMedia
                  sx={{ height: 200, width: 200, objectFit: "fill" }}
                  component="img"
                  image="/mofaLogo.jpg"
                  alt="Visa Platform"
                />
              </CardActionArea>
              <CardActions sx={{ display: "flex", flexDirection: "column" }}>
                <Link
                  color="secondary"
                  variant="h4"
                  href="https://visa.mofa.gov.sa/Account/Login/enjazcompany"
                  target="_blank"
                >
                  Visa Platform
                </Link>
                <Link
                  color="secondary"
                  variant="h4"
                  href="https://visa.mofa.gov.sa/Enjaz/GetVisaInformation/Person"
                  target="_blank"
                >
                  Wekala
                </Link>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
              <CardActionArea
                href="https://tawtheeq.musaned.com.sa/"
                target="_blank"
              >
                <CardMedia
                  sx={{ height: 200, width: 200, objectFit: "fill" }}
                  component="img"
                  image="/musaned2.png"
                  alt="Visa Platform"
                />
              </CardActionArea>
              <CardActions sx={{ display: "flex", flexDirection: "column" }}>
                <Link
                  color="secondary"
                  variant="h4"
                  href="https://tawtheeq.musaned.com.sa/"
                  target="_blank"
                >
                  Musaned
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
              <CardActionArea
                href="https://www.wafid.com/book-appointment/"
                target="_blank"
              >
                <CardMedia
                  sx={{ height: 200, width: 200, objectFit: "fill" }}
                  component="img"
                  image="/wafid2.png"
                  alt="Visa Platform"
                />
              </CardActionArea>
              <CardActions sx={{ display: "flex", flexDirection: "column" }}>
                <Link
                  color="secondary"
                  variant="h4"
                  href="https://www.wafid.com/book-appointment/"
                  target="_blank"
                >
                  New Appointment
                </Link>
                <Link
                  color="secondary"
                  variant="h4"
                  href="https://www.wafid.com/medical-status-search/"
                  target="_blank"
                >
                  Medical Results
                </Link>
                <Link
                  color="secondary"
                  variant="h4"
                  href="https://www.wafid.com/search-slip/"
                  target="_blank"
                >
                  Get Slips
                </Link>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
              <CardActionArea href="https://lmis.gov.et/" target="_blank">
                <CardMedia
                  sx={{ height: 200, width: 200, objectFit: "fill" }}
                  component="img"
                  image="/ELMIS-logo.png"
                  alt="Visa Platform"
                />
              </CardActionArea>
              <CardActions sx={{ display: "flex", flexDirection: "column" }}>
                <Link
                  color="secondary"
                  variant="h4"
                  href="https://lmis.gov.et/"
                  target="_blank"
                >
                  E-LMIS
                </Link>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ImportantLinks;
