import {
  BarChart as BarChartIcon,
  Lock as LockIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle as UserIcon,
  People as UsersIcon,
  CorporateFareSharp,
  Security,
  QuestionAnswer,
  VideoLibrary,
  List,
  Add,
  DescriptionSharp,
  Woman,
  Link,
  GraphicEq,
  MonetizationOn,
  People,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { RoleTypes } from "../../features/auth/types/roleTypes";
import { NavItemChildProps, NavItemProps } from "./types";

export const SideBarItems = (userRoles: string[]) => {
  const { t } = useTranslation();
  const { t: ta } = useTranslation("auth");
  let menuItems: NavItemProps[] = [
    {
      href: "/app/dashboard",
      icon: <BarChartIcon />,
      title: `${t("dashboard")}`,
      role: RoleTypes.ViewDashboard,
    },
    {
      href: "/app/coms",
      icon: <List />,
      title: `${t("list")}`,
      role: RoleTypes.EnjazEntry,
    },
    {
      href: `/app/com/${Math.random().toString(36).slice(2, 12)}`,
      icon: <Add />,
      title: `${t("addNew")}`,
      role: RoleTypes.EnjazEntry,
    },
    {
      href: `/app/employees/`,
      icon: <List />,
      title: `${t("list")}`,
      role: RoleTypes.ViewEmployees,
    },
    {
      href: `/app/employee/${Math.random().toString(36).slice(2, 12)}`,
      icon: <Add />,
      title: `${t("addNew")}`,
      role: RoleTypes.ViewEmployees,
    },
    {
      href: "divider",
      icon: <VideoLibrary />,
      title: "videoLibrary",
      role: RoleTypes.ViewEmployees,
    },
    {
      href: "/app/cvs",
      icon: <UsersIcon />,
      title: `CVs ${t("list")}`,
      role: RoleTypes.ViewCandidates,
    },
    {
      href: "/app/cvd",
      icon: <Woman />,
      title: `CVs ${t("detail")}`,
      role: RoleTypes.ViewCandidates,
    },
    {
      href: "/app/pcv",
      icon: <People />,
      title: `CVs ${t("list")}`,
      role: RoleTypes.EditEmployees,
    },
    {
      href: "/app/cvp",
      icon: <Woman />,
      title: `Custom CVs`,
      role: RoleTypes.EditEmployees,
    },
    {
      href: "/app/cvm",
      icon: <MonetizationOn />,
      title: `CV Maker`,
      role: RoleTypes.ViewCompanies,
    },
    {
      href: "divider",
      icon: <VideoLibrary />,
      title: "cvDivider",
      role: RoleTypes.ViewEmployees,
    },
    // {
    //   href: "",
    //   icon: <Person2 />,
    //   title: `${t("cv")}`,
    //   role: RoleTypes.ViewCandidates,
    //   children: [

    //   ],
    // },
    // {
    //   href: "",
    //   icon: <UsersIcon />,
    //   title: `${t("employees")}`,
    //   role: RoleTypes.ViewEmployees,
    //   children: [],
    // },
    {
      href: "",
      icon: <DescriptionSharp />,
      title: `${t("visas")}`,
      role: RoleTypes.AddVisas,
      children: [
        {
          href: "/app/visas",
          icon: <UsersIcon />,
          title: `${t("list")}`,
          role: RoleTypes.AddVisas,
        },
        {
          href: "/app/visa/0",
          icon: <UsersIcon />,
          title: `${t("addNew")}`,
          role: RoleTypes.AddVisas,
        },
      ],
    },
    {
      href: "",
      icon: <QuestionAnswer />,
      title: `${t("complaints")}`,
      role: RoleTypes.EditComplains,
      children: [
        {
          href: "/app/complaints",
          icon: <UsersIcon />,
          title: `${t("list")}`,
          role: RoleTypes.EditComplains,
        },
        {
          href: "/app/complaints",
          icon: <UsersIcon />,
          title: `${t("addNew")}`,
          role: RoleTypes.EditComplains,
        },
      ],
    },
    {
      href: "/app/reports",
      icon: <GraphicEq />,
      title: `Reports`,
      role: RoleTypes.ViewReports,
    },
    {
      href: "",
      icon: <Security />,
      title: `${ta("admin")}`,
      role: "Admin",
      children: [
        {
          href: "/app/cp",
          icon: <CorporateFareSharp />,
          title: `${ta("companyProfile")}`,
          role: RoleTypes.CompanyManagement,
        },
        {
          href: "/app/users",
          icon: <UsersIcon />,
          title: `${ta("users")}`,
          role: RoleTypes.ManageUsers,
          clickedText: "",
        },

        {
          href: "/app/companies",
          icon: <CorporateFareSharp />,
          title: `${ta("companies")}`,
          role: RoleTypes.ViewCompanies,
          clickedText: "",
        },
      ],
    },
  ];

  const checkUserPrivilege = (item: NavItemProps) => {
    if (
      userRoles.some((userRole) => userRole === item.role) ||
      userRoles.some(
        (userRole) => userRole === item.role.replace("View", "Add")
      ) ||
      userRoles.some(
        (userRole) => userRole === item.role.replace("View", "Manage")
      )
    )
      return true;
    return false;
  };
  let privilegedMenuItems: NavItemProps[] = [];

  menuItems.forEach((menuItem) => {
    if (menuItem.children) {
      let nestedItem: NavItemChildProps[] = [];
      menuItem.children.forEach((child) => {
        if (checkUserPrivilege(child)) nestedItem.push(child);
      });
      if (nestedItem.length > 0) {
        privilegedMenuItems.push({
          ...menuItem,
          children: nestedItem as NavItemChildProps[],
        });
      }
    } else {
      if (checkUserPrivilege(menuItem)) privilegedMenuItems.push(menuItem);
    }
  });

  privilegedMenuItems = privilegedMenuItems.concat([
    {
      href: "divider",
      icon: <VideoLibrary />,
      title: "divider2",
      role: RoleTypes.ViewEmployees,
    },

    {
      href: "/app/tutors",
      icon: <VideoLibrary />,
      title: `${t("videos")}`,
      role: RoleTypes.ViewEmployees,
    },
    {
      href: "/app/support",
      icon: <Link />,
      title: `${t("links")}`,
      role: RoleTypes.ViewEmployees,
    },

    {
      href: "divider",
      icon: <VideoLibrary />,
      title: "a",
      role: RoleTypes.ViewEmployees,
    },
    {
      href: "/app/profile",
      icon: <UserIcon />,
      title: `${ta("companyProfile")}`,
      role: RoleTypes.ViewEmployees,
    },
    {
      href: "",
      icon: <LockIcon />,
      title: `${ta("changePassword")}`,
      clickedText: "changePassword",
      role: RoleTypes.ViewEmployees,
    },
    {
      href: "",
      icon: <ExitToAppIcon />,
      title: `${ta("signOut")}`,
      clickedText: "logout",
      role: RoleTypes.ViewEmployees,
    },
  ]);
  return privilegedMenuItems;

  // {
  //     href: "/app/settings",
  //     icon: <SettingsIcon />,
  //     title: "Settings",
  //   },
  // {
  //     href: "/app/links",
  //     icon: <Link />,
  //     title: `${t("links")}`,
  //     role: RoleTypes.ViewEmployees,
  //   },
  // {
  //     href: "/app/support",
  //     icon: <HelpCenter />,
  //     title: `${t("support")}`,
  //     role: RoleTypes.ViewEmployees,
  //   },
};
