import { Table, TableHead, TableBody } from "@mui/material";
import { differenceInMonths } from "date-fns";

import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { tt } from "../../../../../data/langUtility";
interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const InterDetail = ({ currentEmployee, template }: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="medium" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template}>
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("detailsOfApplication", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("detailsOfApplication", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("nationality", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.additionalData?.nationality as string,
                  template.cvLang
                )}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("nationality", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="1">
              <StyledTableCellLabelTemplate template={template}>
                {tt("religion", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.additionalData?.religion as string,
                  template.cvLang
                )}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("religion", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>

            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate template={template}>
                {tt("age", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {Math.floor(
                  differenceInMonths(
                    new Date(),
                    new Date(currentEmployee.dateOfBirth as Date)
                  ) / 12
                ).toLocaleString(template.cvLang === "ar" ? "ar-SA" : "en-US")}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("age", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>

            <StyledTableRowTemplate template={template} key="5">
              <StyledTableCellLabelTemplate template={template}>
                {tt("maritalStatus", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.additionalData?.maritalStatus as string,
                  template.cvLang
                )}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("maritalStatus", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="6">
              <StyledTableCellLabelTemplate template={template}>
                {tt("numberOfChildren", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {currentEmployee.additionalData?.numberOfChildren?.toLocaleString(
                  template.cvLang === "ar" ? "ar-SA" : "en-US"
                )}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("numberOfChildren", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
