import {
  Document,
  Page,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
// import QRCode from "qrcode";

import { Employee } from "../../../features/process/types/processTypes";
import { AuthUser } from "../../../features/auth/types/authType";
import JsBarcode from "jsbarcode";
import { Company } from "../../../features/setups/types/setupTypes";
import { useState, useEffect } from "react";

// const qrCanvas = document.createElement("canvas");

Font.register({
  family: "Noto",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Naskh_Arabic/NotoNaskhArabic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Open Sans",
  format: "truetype",
  fonts: [
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      src: "/fonts/open-sans-regular.ttf",
    },
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      src: "/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
interface Props {
  employees: Employee[];
  user: AuthUser;
  company: Company;
  letterDate: string | undefined;
  letterNumber: string | undefined;
  office: string | undefined;
  subject: string | undefined;
  body: string | undefined;
}
const enjazCanvas = document.createElement("canvas");

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  row1: {
    flexDirection: "row",
    alignItems: "center",
    height: 20,
  },
  row2: {
    flexDirection: "row",
    alignItems: "center",
    height: 25,
  },
  row21: {
    borderColor: "#FFFFFF",
    borderWidth: 2,
    borderLeftWidth: 2,
    alignItems: "center",
    marginRight: 0,
    borderRightWidth: 0,
    height: 30,
  },
  row31: {
    width: "25%",
    borderColor: "#000000",
    borderWidth: 1,
    alignItems: "center",
    marginRight: 0,
    borderTopWidth: 1,
  },
  row3: {
    flexDirection: "row",
    height: 35,
  },
  cellCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Noto",
  },
  cellLeft: {
    textAlign: "left",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Noto",
  },
  header: {
    position: "absolute",
    top: 10,
    left: 0,
    right: 0,
    height: "80px",
    marginBottom: 10,
  },
  image: {
    width: 100,
    height: 25,
  },
  qrCode: {
    position: "absolute",
    bottom: 50,
    left: 0,
    right: 0,
    height: "35px",
    display: "flex",
    marginTop: -10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dateHeader: {
    textAlign: "left",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Noto",
    fontSize: "14px",
  },
  flexRowReverse: {
    flexDirection: "row-reverse",
  },
  flexWrap: {
    flexWrap: "wrap",
  },
  ml1: {
    marginLeft: "2pt",
  },
});
export const EmbassyListCommission = ({
  employees,
  user,
  company,
  letterDate,
  letterNumber,
  office,
  subject,
  body,
}: Props) => {
  // QRCode.toCanvas(qrCanvas, "https://www.enjazplus.com?lng=ar");
  // const qr = qrCanvas.toDataURL("png");

  const [splittedText, setSplittedText] = useState<string[]>([]);
  useEffect(() => {
    if (body) {
      // console.log(body);
      const _splittedText = body.split(" ");
      setSplittedText(_splittedText);
    }
  }, [body]);

  return (
    <Document
      creator="enjazplus"
      author="enjazplus"
      title="enjazplus"
      subject="Embassy List"
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image
            source={
              user && user.company && user.company?.header
                ? user.company?.header
                : "/noHeader.png"
            }
          />
        </View>
        <View style={{ marginTop: 95 }}>
          <View style={[styles.row2, { paddingHorizontal: 20 }]} key="-20">
            <View
              style={{
                width: "50%",
                flexDirection: "row",
              }}
            >
              <Text
                style={[
                  styles.dateHeader,
                  {
                    borderBottomWidth: 2,
                    borderColor: "black",
                    fontWeight: "normal",
                    marginRight: 6,
                  },
                ]}
              >{` ${letterNumber}`}</Text>
              <Text
                style={[styles.dateHeader, { fontWeight: "normal" }]}
              >{` :رقم`}</Text>
            </View>
            <View
              style={{
                width: "50%",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={[
                  styles.dateHeader,
                  {
                    borderBottomWidth: 2,
                    borderColor: "black",
                    fontWeight: "normal",
                    marginRight: 6,
                  },
                ]}
              >
                {letterDate}
              </Text>
              <Text
                style={[styles.dateHeader, { fontWeight: "normal" }]}
              >{`تاريخ: `}</Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 10 }}>
          <View style={[styles.row2, { paddingHorizontal: 20 }]} key="-123">
            <View
              style={{
                width: "100%",
              }}
              key="-31"
            >
              <Text
                style={[
                  styles.dateHeader,
                  { textAlign: "right", fontWeight: "normal" },
                ]}
              >
                {office}
              </Text>
            </View>
          </View>

          <View style={[styles.row2]} key="-131">
            <View
              style={{
                width: "100%",
                paddingHorizontal: 20,
              }}
            >
              <Text
                style={[
                  styles.dateHeader,
                  {
                    textAlign: "center",
                    fontWeight: "normal",
                  },
                ]}
              >
                {`السلام عليكم ورحمة الله وبركاته `}{" "}
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          <View
            style={[
              styles.row2,
              { alignItems: "center", justifyContent: "center" },
            ]}
            key="-50"
          >
            <Text
              style={[
                styles.dateHeader,
                {
                  borderBottomWidth: 2,
                  borderColor: "black",
                  textAlign: "center",
                },
              ]}
            >
              {`الموضوع : ${subject}`}
            </Text>
          </View>

          <View
            style={[
              styles.flexRowReverse,
              styles.flexWrap,
              { paddingHorizontal: 20 },
            ]}
          >
            {splittedText.map((word, idx) => {
              return (
                <Text
                  style={[
                    styles.dateHeader,
                    styles.ml1,
                    { fontWeight: "medium" },
                  ]}
                  key={idx}
                >
                  {word}
                </Text>
              );
            })}
          </View>

          {/* <View style={{ paddingHorizontal: 20 }} key="-60">
            <Text
              style={[
                styles.dateHeader,
                { fontWeight: "medium", textAlign: "right" },
              ]}
            >
              {`بكل التقدير والاحترام وبالاشارة للموضوع عالية، نلتمس موافقة سعادتكم رسوم المعاملات الموضحة `}
            </Text>
          </View>
          <View style={[{ paddingHorizontal: 20 }]} key="-660">
            <Text
              style={[
                styles.dateHeader,
                { fontWeight: "medium", textAlign: "right" },
              ]}
            >
              {` بالجدول ادناه`}
            </Text>
          </View> */}
        </View>
        <View style={{ marginTop: 5 }}>
          <View
            style={[
              styles.row2,
              {
                marginHorizontal: 6,
                backgroundColor: "#222222",
                color: "white",
                height: 30,
                padding: 0,
              },
            ]}
          >
            <View style={[styles.row21, { width: "20%" }]}>
              <Text style={styles.cellCenter}>{"الباركود"}</Text>
            </View>
            <View style={[styles.row21, { width: "15%" }]}>
              <Text style={styles.cellCenter}>{"رقم الطلب"}</Text>
            </View>
            <View style={[styles.row21, { width: "15%" }]}>
              <Text style={styles.cellCenter}>{"الجنسية"}</Text>
            </View>
            <View style={[styles.row21, { width: "15%" }]}>
              <Text style={styles.cellCenter}>{"رقم جواز"}</Text>
            </View>
            <View style={[styles.row21, { width: "30%" }]}>
              <Text style={styles.cellCenter}>{"الاسم المعطى"}</Text>
            </View>
            <View
              style={[
                styles.row21,
                {
                  width: "5%",
                },
              ]}
            >
              <Text style={styles.cellCenter}>{".ر.س"}</Text>
            </View>
          </View>
          {employees.map((employee, index) => {
            JsBarcode(
              enjazCanvas,
              employee.embassyProcess?.enjazNumber as string,
              {
                format: "code128a",
                width: 1,
                height: 35,
                displayValue: false,
                margin: 0,
                background: "#ffffff",
                lineColor: "#000000",
              }
            );
            const enjazNoBarcode = enjazCanvas.toDataURL();
            return (
              employee &&
              employee.visa &&
              employee.passportNumber &&
              employee.passportNumber.length > 0 &&
              employee.embassyProcess &&
              employee.embassyProcess.enjazNumber && (
                <View
                  style={[styles.row3, { paddingHorizontal: 5 }]}
                  key={index}
                >
                  <View
                    style={[
                      styles.row31,
                      { marginRight: 0, borderRightWidth: 1.5, width: "20%" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.cellCenter,
                        { fontFamily: "Open Sans", paddingTop: 0.5 },
                      ]}
                    >
                      <Image style={styles.image} source={enjazNoBarcode} />
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "15%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.embassyProcess.enjazNumber}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "15%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.additionalData?.nationality?.toUpperCase()}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "15%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.passportNumber}
                    </Text>
                  </View>
                  <View
                    wrap={true}
                    style={{
                      width: "30%",
                      borderColor: "#000000",
                      borderWidth: 1,
                    }}
                  >
                    <Text style={[styles.cellCenter]}>
                      {employee.givenName + " " + employee.surName}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row31,
                      {
                        width: "5%",
                      },
                    ]}
                  >
                    <Text style={styles.cellCenter}>{index + 1}</Text>
                  </View>
                </View>
              )
            );
          })}
        </View>
        <View style={{ marginTop: 5 }}>
          <View style={styles.row2} key="-50">
            <View
              style={{
                width: "100%",
                paddingHorizontal: 20,
              }}
            >
              <Text style={[styles.dateHeader, { textAlign: "center" }]}>
                لكم منا خالص الشكر والتقدير
              </Text>
            </View>
          </View>

          <View style={[styles.row2, { marginTop: 5 }]} key="-90">
            <View
              style={{
                width: "100%",
                paddingHorizontal: 20,
              }}
            >
              <Text style={styles.dateHeader}>المدير العام</Text>
            </View>
          </View>
          <View style={[styles.row2, { marginTop: 10 }]} key="-80">
            <View
              style={{
                width: "100%",
                paddingHorizontal: 20,
              }}
            >
              <Text style={styles.dateHeader}>
                {user?.company?.managerNameAmharic}
              </Text>
            </View>
          </View>
        </View>

        {/* <View style={styles.qrCode} fixed>
          <Image source={qr} />
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                fontFamily: "Noto",
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {company?.displayName +
                (company.licenseNumber ? " - " + company.licenseNumber : "")}
            </Text>

            <Text
              style={{
                fontFamily: "Open Sans",
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {(company?.address?.telephone
                ? company?.address?.telephone
                : ".") +
                (company?.address?.alternateTelephone
                  ? "/" + company?.address?.alternateTelephone
                  : "") +
                (company?.address?.mobile
                  ? "/" + company?.address?.mobile
                  : "")}
            </Text>
          </View>
        </View> */}
        {/* <View style={styles.qrCode} fixed>
          <Image source={qr} />
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text>EnjazPlus</Text>
            <Text>+251-911-850-908</Text>
          </View>
        </View> */}
      </Page>
    </Document>
  );
};
