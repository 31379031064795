import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { differenceInMonths } from "date-fns";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fetchEmployees,
  linkedEmployees,
  resetSuccess,
  selectProcesses,
  setSelectedEmployee,
} from "../../process/processSlices";
import Grid from "@mui/material/Grid";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import {
  changePageTitle,
  selectPreference,
} from "../../preferences/preferencesSlice";
import {
  Stack,
  Button,
  Typography,
  Skeleton,
  Box,
  Table,
  TableBody,
  TableHead,
  useTheme,
  Tooltip,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import Image from "mui-image";

import { PagingForCV } from "../../../components/Layout/Paging";
import { EmployeeStatus } from "../types/processTypes";
import {
  StyledTableCellLabelTemplate,
  StyledTableCellValueTemplate,
  LabelTemplate,
  StyledTableContainer,
  StyledTableRowTemplate,
} from "./styles/table";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../../components/modals/CustomDialog";
import { getCompany, selectSetups } from "../../setups/setupSlices";
import { selectAuth } from "../../auth/authSlice";
interface Props {
  isPremium: boolean;
}

export const PremiumCandidates = ({ isPremium }: Props) => {
  const { agencyId, agentId } = useParams() as {
    agencyId: string;
    agentId: string;
  };

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");
  const {
    employeesWithCount: { employees, totalCount },
    loading,
  } = useAppSelector(selectProcesses);
  const { searchText } = useAppSelector(selectPreference);
  const { selectedCompany } = useAppSelector(selectSetups);
  const { user } = useAppSelector(selectAuth);

  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [openVisa, setOpenVisa] = useState(false);
  const navigate = useNavigate();
  // const [headerGradient, setHeaderGradient] = useState<GradientTemplate>({
  //   typeOfGradient: "radial-gradient",
  //   degree: "circle",
  //   firstColor: theme.palette.primary.main,
  //   firstPercentage: "0%",
  //   secondColor: theme.palette.secondary.main,
  //   secondPercentage: "100%",
  // });
  // const [evenGradient, setEvenGradient] = useState<GradientTemplate>({
  //   typeOfGradient: "linear-gradient",
  //   degree: "90deg",
  //   firstColor: "transparent",
  //   firstPercentage: "0%",
  //   secondColor: "transparent",
  //   secondPercentage: "100%",
  // });
  // const [oddGradient, setOddGradient] = useState<GradientTemplate>({
  //   typeOfGradient: "linear-gradient",
  //   degree: "90deg",
  //   firstColor: "transparent",
  //   firstPercentage: "0%",
  //   secondColor: "transparent",
  //   secondPercentage: "100%",
  // });
  const viewCvHandler = (id: string) => {
    dispatch(setSelectedEmployee(employees.find((e) => e._id === id)));
    if (isPremium) {
      if (agencyId && agentId) navigate(`/cpp/${agencyId}/${agentId}`);
      else navigate(`/app/cvp`);
    } else navigate("/app/cvd");
  };

  const dialogCloseVisa = () => {
    setOpenVisa(false);
    dispatch(resetSuccess());
  };

  useEffect(() => {
    dispatch(changePageTitle("Candidates List"));

    const skipRows = currentPage * rowsPerPage;

    if (!employees || employees.length === 0 || searchText) {
      if (
        agencyId &&
        agentId &&
        agencyId.length === 24 &&
        agentId.length === 24
      ) {
        dispatch(getCompany(agentId));
        dispatch(
          linkedEmployees({
            agencyId: agencyId,
            agentId: agentId,
            status: EmployeeStatus.new,
            refreshList: "network-only",
          })
        );
      } else {
        if (user && user.company)
          dispatch(getCompany(user.company._id as string));
        dispatch(
          fetchEmployees({
            searchText,
            skip: skipRows,
            take: rowsPerPage,
            status: EmployeeStatus.new,
          })
        );
      }
    }
  }, [
    dispatch,
    searchText,
    currentPage,
    rowsPerPage,
    employees,
    agencyId,
    agentId,
    user,
  ]); //
  const [cvTemplate, setCvTemplate] = useState<LabelTemplate>({
    name: "basic",
    numberOfPages: 2,
    cvLang: "en",
    bgColor: "#FFFFFF",
    headFontColor: "#000000",
    headBgColor: "#FFFFFF",
    labelFontColor: "#000000",
    labelBgColor: "#FFFFFF",
    valueFontColor: "#000000",
    valueBgColor: "#FFFFFF",
    borderColor: "#FFFFFF",
    borderRadius: "10px",
    borderWidth: 1,
    borderStyle: "solid",
    spacing: 1,
    labelFontSize: 18,
    valueFontSize: 20,
    labelFontWeight: 900,
    valueFontWeight: 900,
    textTransform: "uppercase",
    headerHeight: 48,
    bodyHeight: 32,
    headerGradient: {
      typeOfGradient: "linear-gradient",
      degree: "90deg",
      firstColor: "transparent",
      firstPercentage: "0%",
      secondColor: "transparent",
      secondPercentage: "100%",
    },
    evenGradient: {
      typeOfGradient: "linear-gradient",
      degree: "90deg",
      firstColor: "transparent",
      firstPercentage: "0%",
      secondColor: "transparent",
      secondPercentage: "100%",
    },
    oddGradient: {
      typeOfGradient: "linear-gradient",
      degree: "90deg",
      firstColor: "transparent",
      firstPercentage: "0%",
      secondColor: "transparent",
      secondPercentage: "100%",
    },
    addPassportHeader: false,
    useHeaderGradient: true,
    useBodyGradient: false,
    headerShadow: "",
    bodyShadow: "",
    photoShadow: "",
    passportShadow: "",
    qrLocationLink: "",
    qrLocationWidth: 200,
    qrLocationHeight: 200,
    qrLocationImageUrl: "",
    qrLocationMargin: 2,
    qrMoreCvsLink: "",
    qrMoreCvsWidth: 200,
    qrMoreCvsHeight: 200,
    qrMoreCvsImageUrl: "",
    qrMoreCvsMargin: 2,
    faceHeight: "240px",
    faceWidth: "100%",
    standHeight: "600px",
    standWidth: "400px",
    flagHeight: "200px",
    flagWidth: "100%",
    passportHeight: "650px",
    passportWidth: "700px",
  });
  useEffect(() => {
    if (selectedCompany && selectedCompany._id) {
      if (selectedCompany.cvTemplate) {
        //console.log(selectedCompany.cvTemplate);
        const cv = selectedCompany.cvTemplate;
        const headerGr = cv.headerGradient.split("_");
        //if (headerGr.length === 6) {
        const headerGra = {
          typeOfGradient: headerGr[0],
          degree: headerGr[1],
          firstColor: headerGr[2],
          firstPercentage: headerGr[3],
          secondColor: headerGr[4],
          secondPercentage: headerGr[5],
        };
        //}

        const evenGr = cv.evenGradient.split("_");

        //if (evenGr.length === 6) {
        const evenGra = {
          typeOfGradient: evenGr[0],
          degree: evenGr[1],
          firstColor: evenGr[2],
          firstPercentage: evenGr[3],
          secondColor: evenGr[4],
          secondPercentage: evenGr[5],
        };
        //}
        const oddGr = cv.oddGradient.split("_");
        //if (oddGr.length === 6) {
        const oddGra = {
          typeOfGradient: oddGr[0],
          degree: oddGr[1],
          firstColor: oddGr[2],
          firstPercentage: oddGr[3],
          secondColor: oddGr[4],
          secondPercentage: oddGr[5],
        };
        //}

        setCvTemplate({
          ...selectedCompany.cvTemplate,
          headerGradient: headerGra,
          evenGradient: evenGra,
          oddGradient: oddGra,
          labelFontSize: 14,
          valueFontSize: 16,
        });
      } else {
        //renderCvFormat(currentEmployee.owner.cvTemplate);
        //console.log(selectedCompany);
      }
    }
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    setTotal(totalCount as number);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  return (
    <>
      <Helmet>
        <title>Candidates | EnjazPlus</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <>
          <>
            <Grid container justifyContent="center" sx={{ mt: 1 }} spacing={1}>
              {loading === "pending" ? (
                [...Array(10)].map((e, i) => (
                  <Grid
                    container
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    direction="column"
                    display="flex"
                    alignItems="center"
                    key={i}
                  >
                    <Skeleton
                      variant="rounded"
                      width={180}
                      height={180}
                      style={{ margin: "5px" }}
                    />
                    <Skeleton variant="text" height={25} width={180} />
                    <Skeleton variant="text" height={25} width={180} />
                  </Grid>
                ))
              ) : employees.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src="/No_Data.svg"
                    alt="No Data"
                    width={250}
                    height={120}
                    shift="top"
                    distance="2rem"
                    shiftDuration={320}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                  <Typography variant="h5">No CVs Found</Typography>
                  <Tooltip title="Add New Employee">
                    <Button
                      color="secondary"
                      variant="contained"
                      component={NavLink}
                      to={`/app/employee/${Math.random()
                        .toString(36)
                        .slice(2, 12)}`}
                    >
                      <Add /> Add New Employee
                    </Button>
                  </Tooltip>
                </Box>
              ) : (
                employees.map((row, index) => (
                  <Grid
                    container
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    direction="column"
                    display="flex"
                    alignItems="center"
                    key={index}
                  >
                    <Button onClick={() => viewCvHandler(row._id as string)}>
                      <Box sx={{ boxShadow: cvTemplate.photoShadow }}>
                        <Image
                          src={
                            row && row.facePhoto
                              ? (row.facePhoto as string)
                              : "/blankShort.jpg"
                          }
                          width={240}
                          height={240}
                          shift="top"
                          distance="2rem"
                          shiftDuration={320}
                          style={{
                            objectFit: "fill",
                            borderRadius: cvTemplate.borderRadius,
                            marginTop: cvTemplate.spacing,
                            borderColor: cvTemplate.borderColor,
                            borderWidth: (cvTemplate.borderWidth as number) * 3,
                            borderStyle: cvTemplate.borderStyle,
                          }}
                        />
                      </Box>
                    </Button>

                    <StyledTableContainer
                      template={cvTemplate}
                      sx={{
                        marginTop: 1,
                        borderRadius: "10px",
                        width: "100%",
                      }}
                    >
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <StyledTableRowTemplate
                            template={cvTemplate}
                            key="33"
                          >
                            <StyledTableCellLabelTemplate
                              template={cvTemplate}
                              colSpan={4}
                              sx={{ textAlign: "center" }}
                            >
                              {row.givenName + " (" + row.passportNumber + ")"}
                            </StyledTableCellLabelTemplate>
                          </StyledTableRowTemplate>
                        </TableHead>
                        <TableBody>
                          <StyledTableRowTemplate template={cvTemplate} key="1">
                            <StyledTableCellLabelTemplate template={cvTemplate}>
                              {tt("age")}
                            </StyledTableCellLabelTemplate>
                            <StyledTableCellValueTemplate template={cvTemplate}>
                              {Math.floor(
                                differenceInMonths(
                                  new Date(),
                                  new Date(row.dateOfBirth as Date)
                                ) / 12
                              ).toLocaleString(
                                theme.direction === "rtl" ? "ar-SA" : "en-US"
                              )}
                            </StyledTableCellValueTemplate>

                            <StyledTableCellLabelTemplate template={cvTemplate}>
                              {tt("salary")}
                            </StyledTableCellLabelTemplate>
                            <StyledTableCellValueTemplate template={cvTemplate}>
                              {row.additionalData?.salary?.toLocaleString(
                                theme.direction === "rtl" ? "ar-SA" : "en-US"
                              ) +
                                "" +
                                te(row.additionalData?.currency as string)}
                            </StyledTableCellValueTemplate>
                          </StyledTableRowTemplate>
                          <StyledTableRowTemplate template={cvTemplate} key="0">
                            <StyledTableCellLabelTemplate template={cvTemplate}>
                              {te("arabic")}
                            </StyledTableCellLabelTemplate>
                            <StyledTableCellValueTemplate template={cvTemplate}>
                              {te(row.education?.arabicLanguage as string)}
                            </StyledTableCellValueTemplate>

                            <StyledTableCellLabelTemplate template={cvTemplate}>
                              {te("english")}
                            </StyledTableCellLabelTemplate>
                            <StyledTableCellValueTemplate template={cvTemplate}>
                              {te(row.education?.englishLanguage as string)}
                            </StyledTableCellValueTemplate>
                          </StyledTableRowTemplate>
                          <StyledTableRowTemplate template={cvTemplate} key="0">
                            <StyledTableCellLabelTemplate template={cvTemplate}>
                              {te("religion")}
                            </StyledTableCellLabelTemplate>
                            <StyledTableCellValueTemplate template={cvTemplate}>
                              {te(row.additionalData?.religion as string)}
                            </StyledTableCellValueTemplate>

                            <StyledTableCellLabelTemplate template={cvTemplate}>
                              {te("maritalStatus")}
                            </StyledTableCellLabelTemplate>
                            <StyledTableCellValueTemplate template={cvTemplate}>
                              {te(row.additionalData?.maritalStatus as string)}
                            </StyledTableCellValueTemplate>
                          </StyledTableRowTemplate>
                          <StyledTableRowTemplate template={cvTemplate} key="5">
                            <StyledTableCellLabelTemplate template={cvTemplate}>
                              {tt("workExperience")}
                            </StyledTableCellLabelTemplate>
                            <StyledTableCellValueTemplate
                              template={cvTemplate}
                              colSpan={3}
                            >
                              {row.workExperience &&
                              row.workExperience.length <= 0 ? (
                                <>{tt("firstTimer")}</>
                              ) : (
                                <>
                                  {te(
                                    (row.workExperience &&
                                      row.workExperience[0].country) as string
                                  ) +
                                    "-" +
                                    te(
                                      (row.workExperience &&
                                        row.workExperience[0]
                                          .profession) as string
                                    ) +
                                    "-" +
                                    te(
                                      (row.workExperience &&
                                        row.workExperience[0].years) as string
                                    )}
                                </>
                              )}
                            </StyledTableCellValueTemplate>
                          </StyledTableRowTemplate>
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                  </Grid>
                ))
              )}
            </Grid>
            {employees.length > 0 && (
              <Stack spacing={1} alignItems="center" sx={{ mt: 2 }}>
                <PagingForCV
                  total={total}
                  rowsPerPage={rowsPerPage}
                  currentPage={currentPage}
                  setRowsPerPage={setRowsPerPage}
                  setCurrentPage={setCurrentPage}
                  template={cvTemplate}
                />
                <Typography variant="body2" color="textPrimary" component="div">
                  Total: {total}
                </Typography>
              </Stack>
            )}
          </>
          <CustomDialog
            title="Select Candidate"
            isOpen={openVisa}
            handleDialogClose={dialogCloseVisa}
          >
            <Box sx={{ p: 2, width: "400px", height: "400px" }}>
              {/* <VisaBrief /> */}
            </Box>
          </CustomDialog>{" "}
        </>
      </Box>
    </>
  );
};
