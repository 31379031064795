import { Table, TableHead, TableBody, Stack } from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const InterExperience = ({ currentEmployee, template }: Props) => {
  return (
    <>
      <Stack
        sx={{
          width: "100%",
          "& > :not(style)": {
            m: 0,
          },
        }}
        spacing={template.spacing}
      >
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate key="3" template={template}>
                <StyledTableCellLabelTemplate
                  template={template}
                  colSpan={2}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {tt("workExperience", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("workExperience", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template}>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("country", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.workExperience &&
                  currentEmployee.workExperience[0]
                    ? tt(
                        currentEmployee.workExperience[0].country as string,
                        template.cvLang
                      )
                    : tt("firstTimer", template.cvLang)}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("country", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template}>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("forYears", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.workExperience &&
                  currentEmployee.workExperience[0]
                    ? tt(
                        currentEmployee.workExperience[0].years as string,
                        template.cvLang
                      )
                    : ""}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("forYears", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("arabic", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt(
                    currentEmployee.education?.arabicLanguage as string,
                    template.cvLang
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("arabic", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="120">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                ></StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="130">
                <StyledTableCellValueTemplate colSpan={3} template={template}>
                  {`${tt("elderly_care", "ar")} - ${tt(
                    "children_care",
                    "ar"
                  )} - ${tt("cooking", "ar")} - ${tt(
                    "dusting_cleaning",
                    "ar"
                  )}`}
                  <br />
                  {`${tt("elderly_care", "en")} - ${tt(
                    "children_care",
                    "en"
                  )} - ${tt("cooking", "en")} - ${tt(
                    "dusting_cleaning",
                    "en"
                  )}`}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
        <StyledTableContainer template={template}>
          <Table size="small" aria-label="simple table">
            <TableHead></TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="4">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {`في العقد الدولي غايتنا خدمتكم بما يرضيكم`}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Stack>
    </>
  );
};
