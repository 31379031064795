import React, { useEffect } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, List, Typography, useTheme } from "@mui/material";
import Link from "@mui/material/Link";

import NavItem from "./NavItem";

import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../../features/auth/authSlice";
import { toggleThis } from "../../features/preferences/preferencesSlice";
import { DrawerHeader } from "../DashboardSidebar";
import CustomDialog from "../modals/CustomDialog";
import ChangePassword from "../account/ChangePassword";
import { logout } from "../../features/auth/authSlice";
import { SideBarItems } from "./SideBarItems";
import Language from "../mode/Language";
import { useTranslation } from "react-i18next";
import Logo from "../../features/process/components/Logo";

const SidebarContent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [roles, setRoles] = React.useState<string[]>([]);
  const theme = useTheme();
  const { user } = useAppSelector(selectAuth);
  // const { isDrawerOpen, isMobileDrawerOpen, isMiniMode } =
  //   useAppSelector(selectPreference);

  const handleDrawerClose = () => {
    dispatch(toggleThis({ type: "Drawer", newValue: false }));
    dispatch(toggleThis({ type: "Mobile", newValue: false }));
    // dispatch(toggleThis({ type: "Mini", newValue: true }));
  };
  const changePasswordHandler = () => {
    //console.log("change Password clicked");
    setOpen(true);
  };
  const dialogClose = () => {
    setOpen(false);
  };
  const signOut = () => {
    dispatch(logout());
  };
  const voidFunction = () => {};
  useEffect(() => {
    setRoles(user?.roles?.map((r) => r.displayName) as string[]);
  }, [user?.roles]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <DrawerHeader>
        <Box
          sx={{
            width: "100%",
            p: 1,
            // background:
            //   user &&
            //   user.company &&
            //   user.company.depositAmount &&
            //   user.company.depositAmount === "sudan2"
            //     ? `linear-gradient(180deg, #D21034 30%, #FFFFFF 60%, #000000 95%)`
            //     : `linear-gradient(to right, ${theme.palette.primary.main} 30%, ${theme.palette.primary.main} 70%)`,
            // background: `linear-gradient(180deg, #D21034 30%, #FFFFFF 60%, #000000 95%)`,
            background: `linear-gradient(to right, ${theme.palette.primary.main} 30%, ${theme.palette.primary.main} 85%)`,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <RouterLink
              to="/"
              // style={{
              //   marginRight: theme.direction === "ltr" ? "24px" : "0px",
              //   marginLeft: theme.direction === "rtl" ? "24px" : "0px",
              // }}
            >
              <Logo />{" "}
            </RouterLink>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                flexDirection: "row",
                display: { xs: "flex", md: "none" },
                color: "white",
              }}
            >
              {/* <Mode /> */}
              <Language />
            </Box>
            <IconButton
              style={{ color: "white" }}
              onClick={handleDrawerClose}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              marginTop: 8,
              marginBottom: 0,
              color: "white",
            }}
          >
            <Typography
              variant="h4"
              color={"white"}
              style={{
                fontWeight: 500,
              }}
            >
              EnjazPlus
              {/* {user && user.name} */}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "16px" }}>
              Simple & Effective
            </Typography>
          </div>
        </Box>
      </DrawerHeader>

      <Box sx={{ px: 1, py: 0 }}>
        <List>
          {SideBarItems(roles).map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              role={item.role}
              icon={item.icon}
              children={item.children}
              click={
                item.href.length === 0
                  ? item.clickedText === "changePassword"
                    ? changePasswordHandler
                    : signOut
                  : voidFunction
              }
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          m: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          id="userEmail"
          sx={{ marginTop: 1, opacity: 0 }}
        >
          {user && user.email}
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Link
            color={"primary"}
            href={"https://pinnasofts.com/"}
            target={"_blank"}
          >
            {t("aboutUs")}
          </Link>
          {/* <Link
            color={"primary"}
            href={"https://pinnasofts.com/about"}
            target={"_blank"}
          >
            About Us
          </Link>
          <Link
            color={"primary"}
            href={"https://pinnasofts.com/blog"}
            target={"_blank"}
          >
            Blog
          </Link> */}
        </Box>
        <Typography>&copy; 2023</Typography>
      </Box>
      {/* <input type="hidden" id="userEmail" value={user && user.email} /> */}
      <CustomDialog
        title="Change Password"
        isOpen={open}
        handleDialogClose={dialogClose}
      >
        <ChangePassword />
      </CustomDialog>
    </Box>
  );
};

export default SidebarContent;
