import {
  Document,
  Page,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";

import { CorrectionReportProps } from "../types";
import { getAmharicCalendarFormatted } from "../../../utils/calendarUtility";
import { useTranslation } from "react-i18next";

Font.register({
  family: "Noto",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Naskh_Arabic/NotoNaskhArabic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Ethiopic",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Sans_Ethiopic/NotoSansEthiopic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Open Sans",
  format: "truetype",
  fonts: [
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      src: "/fonts/open-sans-regular.ttf",
    },
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      src: "/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1.5,
    flexDirection: "column",
  },

  row2: {
    flexDirection: "row",
    alignItems: "center",
    height: 30,
  },
  row21: {
    borderColor: "#000000",
    borderWidth: 2,
    alignItems: "center",
    marginRight: -1,
    borderRightWidth: 0,
  },
  row31: {
    width: "25%",
    borderColor: "#000000",
    borderWidth: 1,
    alignItems: "center",
    marginRight: -1,
    borderTopWidth: 0,
  },
  row3: {
    flexDirection: "row",
    height: 25,
  },
  cellCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "medium",
    fontFamily: "Ethiopic",
    fontSize: "12px",
  },
  headerCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Ethiopic",
    fontSize: "12px",
  },
  header: {
    position: "absolute",
    top: 20,
    left: 0,
    right: 0,
    height: "150px",
    marginBottom: 20,
    width: "99%",
  },
  dateHeader: {
    textAlign: "left",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Ethiopic",
    fontSize: "13px",
  },
});
export const CorrectionLetter = ({
  employees,
  user,
  letterDate,
  letterNumber,
  typoOfCorrection,
  reason,
}: CorrectionReportProps) => {
  const { t: te } = useTranslation("enums");

  return (
    <Document
      creator="pinnasofts"
      author="pinnaSofts"
      title="EnjazPlus"
      subject="Correction Letters"
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <Image
            source={
              user && user.company && user.company?.header
                ? user.company?.header
                : "/noHeader.png"
            }
          />
        </View>
        <View style={{ marginTop: 120 }}>
          <View style={[styles.row2]} key="-20">
            <View style={{ width: "70%" }}></View>
            <View
              style={{
                width: "25%",
                borderBottomWidth: 2,
                borderColor: "black",
              }}
            >
              <Text style={styles.dateHeader}>{`ቀን: ${letterDate}`}</Text>
            </View>
            <View style={{ width: "5%" }}></View>
          </View>
          <View style={[styles.row2]} key="-10">
            <View style={{ width: "70%" }}></View>
            <View
              style={{
                width: "25%",
                borderBottomWidth: 2,
                borderColor: "black",
              }}
            >
              <Text style={styles.dateHeader}>{`ቁጥር: ${letterNumber}`}</Text>
            </View>
            <View style={{ width: "5%" }}></View>
          </View>
        </View>
        <View style={{ marginTop: -5 }}>
          <View
            style={[styles.row2, { paddingHorizontal: 20, height: 25 }]}
            key="-31"
          >
            <Text style={styles.dateHeader}>ለኢትዮጲያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ</Text>
          </View>
          <View
            style={[styles.row2, { paddingHorizontal: 20, height: 25 }]}
            key="-30"
          >
            <Text style={styles.dateHeader}>የስራና ክህሎት ሚኒስቴር</Text>
          </View>
          <View
            style={[
              styles.row2,
              {
                paddingHorizontal: 20,
              },
            ]}
            key="-40"
          >
            <Text
              style={[
                styles.dateHeader,
                {
                  width: "15%",
                  borderBottomWidth: 2,
                  borderColor: "black",
                },
              ]}
            >
              አዲስ አበባ
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          <View style={[styles.row2]} key="-50">
            <View
              style={{ width: typoOfCorrection === "issued" ? "15%" : "15%" }}
            ></View>
            <View
              style={{
                width: typoOfCorrection === "issued" ? "45%" : "70%",
              }}
            >
              <Text
                style={[
                  styles.dateHeader,
                  { borderBottomWidth: 2, borderColor: "black" },
                ]}
              >
                {typoOfCorrection === "name"
                  ? `ጉዳዩ: የስም ስህተት ባዮሜትሪክ ሲስተም ላይ እንዲስተካከልልን ስለመጠየቅ`
                  : typoOfCorrection === "sex"
                  ? `ጉዳዩ: የጾታ ስህተት ባዮሜትሪክ ሲስተም ላይ እንዲስተካከልልን ስለመጠየቅ`
                  : typoOfCorrection === "issued"
                  ? `ጉዳዩ: መረጃ እንዲስተካከልልን ስለመጠየቅ`
                  : ""}
              </Text>
            </View>
          </View>
          <View style={[{ paddingHorizontal: 20 }]} key="-60">
            <Text
              wrap={true}
              style={[styles.dateHeader, { fontWeight: "medium" }]}
            >
              {`ድርጅታችን ${
                user?.company?.nameAmharic
                  ? user?.company?.nameAmharic
                  : user?.company?.displayName
              } በአዋጅ ቁጥር 923/2008 መሰረት ተቋቁሞ ዜጎችን ወደ ውጭ ሀገር ለስራ በመላክ ላይ ይገኛል::`}
            </Text>
          </View>
          <View style={[{ paddingHorizontal: 20 }]} key="-70">
            <Text
              wrap={true}
              style={[styles.dateHeader, { fontWeight: "medium" }]}
            >
              {typoOfCorrection === "name"
                ? `ከላይ በርዕሱ ለመግለጽ እንድተሞከርው ስማቸው ከዚህ በታች የተገለጹት ተጓዦች ባዮሜትሪክ ሲስተም ላይ የስም ስህተት ስላለባቸው ከዚህ በታች በተገለጸው መረጃ መሰረት እንዲስተካክልልን ስንል በአክብሮት እየጠየቅን ለምታደርጉልን ቀና ትብብር ከውዲሁ እናመሰግናለን::`
                : typoOfCorrection === "sex"
                ? `ከላይ በርዕሱ ለመግለጽ እንድተሞከርው ስማቸው ከዚህ በታች የተገለጹት ተጓዦች ባዮሜትሪክ ሲስተም ላይ የጾታ ስህተት ስላለባቸው ከዚህ በታች በተገለጸው መረጃ መሰረት እንዲስተካክልልን ስንል በአክብሮት እየጠየቅን ለምታደርጉልን ቀና ትብብር ከውዲሁ እናመሰግናለን::`
                : typoOfCorrection === "issued"
                ? `ስማቸው ከዚህ በታች የተገለጹት ተጓዦች ሙሉ ፕሮሰስ ጨርሰው ለበረራ ኤርፖርት ደርስው ኢሚግሬሽን ቬሪፋይድ(immigration_verified) ከተባሉ በኋላ ${reason} ያልብርሩ ስለሆነ ሲስተሙ ወደ ነበረበት ኢሹድ(issued) ስታተስ እንዲመለስልን እየጠየቅን ለምታደርጉልን ቀና ትብብር ከውዲሁ እናመሰግናለን::`
                : ""}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 5, paddingHorizontal: 20 }}>
          <View style={[styles.row2]} key="-1">
            <View style={[styles.row21, { width: "5%" }]}>
              <Text style={styles.headerCenter}>ተ.ቁ.</Text>
            </View>
            <View
              style={[
                styles.row21,
                { width: typoOfCorrection === "sex" ? "45%" : "30%" },
              ]}
            >
              <Text style={styles.headerCenter}>NAME</Text>
            </View>
            {typoOfCorrection === "name" && (
              <View style={[styles.row21, { width: "30%" }]}>
                <Text style={styles.headerCenter}>ስም</Text>
              </View>
            )}
            {typoOfCorrection === "sex" && (
              <View style={[styles.row21, { width: "15%" }]}>
                <Text style={styles.headerCenter}>ጾታ</Text>
              </View>
            )}
            <View style={[styles.row21, { width: "16%" }]}>
              <Text style={styles.headerCenter}>ፓስፖርት ቁ.</Text>
            </View>
            <View style={[styles.row21, { width: "19%", borderRightWidth: 2 }]}>
              <Text style={styles.headerCenter}>ሌበር አይዲ</Text>
            </View>
            {typoOfCorrection === "issued" && (
              <View
                style={[styles.row21, { width: "30%", borderRightWidth: 2 }]}
              >
                <Text style={styles.headerCenter}>የበረራ ቀን</Text>
              </View>
            )}
          </View>
          {employees.map((employee, index) => {
            const stDate =
              employee &&
              employee.additionalData &&
              employee.additionalData.afterFlightStatusDate
                ? employee.additionalData.afterFlightStatusDate
                : new Date();

            const labourId =
              employee &&
              employee.additionalData &&
              employee.additionalData.labourId &&
              employee.additionalData.labourId.length > 5
                ? employee.additionalData.labourId
                : "-";
            const nameAmharic =
              employee &&
              employee.additionalData &&
              employee.additionalData.fullNameAmharic &&
              employee.additionalData.fullNameAmharic.length > 8
                ? employee.additionalData.fullNameAmharic
                : "-";
            return (
              employee &&
              employee.passportNumber &&
              employee.passportNumber.length > 0 &&
              employee.additionalData &&
              employee.address && (
                <View style={[styles.row3]} key={index}>
                  <View style={[styles.row31, { width: "5%" }]}>
                    <Text style={styles.cellCenter}>{index + 1}</Text>
                  </View>
                  <View
                    style={[
                      styles.row31,
                      { width: typoOfCorrection === "sex" ? "45%" : "30%" },
                    ]}
                  >
                    <Text style={styles.cellCenter}>
                      {employee.givenName + " " + employee.surName}
                    </Text>
                  </View>
                  {typoOfCorrection === "name" && (
                    <View style={[styles.row31, { width: "30%" }]}>
                      <Text wrap={false} style={styles.cellCenter}>
                        {nameAmharic}
                      </Text>
                    </View>
                  )}
                  {typoOfCorrection === "sex" && (
                    <View style={[styles.row31, { width: "15%" }]}>
                      <Text wrap={false} style={styles.cellCenter}>
                        {te(employee.sex as string)}
                      </Text>
                    </View>
                  )}
                  <View style={[styles.row31, { width: "16%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.passportNumber}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "19%" }]}>
                    <Text style={styles.cellCenter}>{labourId}</Text>
                  </View>
                  {typoOfCorrection === "issued" && (
                    <View style={[styles.row31, { width: "30%" }]}>
                      <Text wrap={false} style={styles.cellCenter}>
                        {getAmharicCalendarFormatted(stDate, "/")}
                      </Text>
                    </View>
                  )}
                </View>
              )
            );
          })}
        </View>
        <View style={{ marginTop: 10 }}>
          <View style={[styles.row2]} key="-50">
            <View style={{ width: "65%" }}></View>
            <View style={{ width: "35%" }}>
              <Text style={[styles.dateHeader]}>ከሠላምታ ጋር</Text>
            </View>
          </View>

          <View style={[styles.row2, { marginTop: 40 }]} key="-80">
            <View style={{ width: "65%" }}></View>
            <View style={{ width: "35%" }}>
              <Text style={styles.dateHeader}>
                {user?.company?.managerNameAmharic}
              </Text>
            </View>
          </View>
          <View style={[styles.row2, { marginTop: 0 }]} key="-90">
            <View style={{ width: "65%" }}></View>
            <View style={{ width: "35%" }}>
              <Text style={styles.dateHeader}>ዋና ስራ አስኪያጅ</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
