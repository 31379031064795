import tinycolor from "tinycolor2";
import { grey } from "@mui/material/colors";
import { PaletteMode } from "@mui/material";

const primary = "#007229"; //#00800 "#388e3c";
const secondary = "#FFA500"; //"#D21034"; //
const success = "#2e7d32";
const warning = "#FFA500"; //"#D21034";
const info = "#000000";

// const primary = "#455a64";
// const secondary = "#e91e63";

// const warning = "#FFC260";
// const success = "#3CD4A0";
// const info = "#9013FE";

//const lightenRate = 7.5;
const darkenRate = 5;

export const lightTheme = {
  palette: {
    mode: "light" as PaletteMode,
    primary: { main: tinycolor(primary).setAlpha(1).toRgbString() },
    secondary: { main: secondary },
    warning: { main: warning },
    success: { main: success },
    info: { main: info },
    background: {
      //default: `linear-gradient(to right, ${primary} 30%, ${secondary} 90%)`,
      //paper: `linear-gradient(to right, ${primary} 30%, ${secondary} 90%)`,
      //default: "#ffffff",
      // paper: "#121212",
    },
    text: {
      primary: "#000",
      secondary: "#6E6E6E",
    },
    action: {
      selected: "rgb(232, 245, 233)", // "rgba(0, 128, 0, 0.2)",
      hover: tinycolor("rgba(0, 128, 0, 0.2)").darken(darkenRate).toHexString(),
    },
  },
};

export const darkTheme = {
  palette: {
    mode: "dark" as PaletteMode,
    primary: { main: tinycolor(primary).darken(darkenRate).toHexString() },
    secondary: { main: tinycolor(secondary).darken(darkenRate).toHexString() },
    warning: { main: tinycolor(warning).darken(darkenRate).toHexString() },
    success: { main: tinycolor(success).darken(darkenRate).toHexString() },
    info: { main: tinycolor(info).darken(darkenRate).toHexString() },
    background: {
      //default: `linear-gradient(to right, ${primary} 30%, ${secondary} 90%)`,
      //paper: `linear-gradient(to bottom, ${secondary} 30%, ${primary} 90%)`,
      //default: "#121212",
      //paper: "#121212",
    },
    text: {
      primary: "#fff",
      secondary: grey[500],
    },
    action: {
      selected: warning,
      hover: tinycolor(warning).darken(darkenRate).toHexString(),
    },
  },
};
