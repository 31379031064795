import { Table, Stack, TableHead } from "@mui/material";
import {
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import Image from "mui-image";
import { Employee } from "../../../types/processTypes";

import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const StandWithHeader = ({ currentEmployee, template }: Props) => {
  return (
    <Stack
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        marginTop: template.spacing,
        borderWidth: template.borderWidth,
        borderColor: template.borderColor,
        borderStyle: template.borderStyle,
        boxShadow: template.bodyShadow,
        backgroundColor: template.labelBgColor,
        p: 1,
      }}
      spacing={1}
      alignItems="center"
    >
      <StyledTableContainer template={template}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("personalPhoto", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("personalPhoto", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
        </Table>
      </StyledTableContainer>

      <Image
        src={
          currentEmployee && currentEmployee.standPhoto
            ? (currentEmployee.standPhoto as string)
            : "/blankLong.jpg"
        }
        alt={currentEmployee.passportNumber}
        width="80%"
        height="550px"
        shift="top"
        distance="2rem"
        shiftDuration={320}
        style={{
          objectFit: "fill",
          borderRadius: template.borderRadius,
          borderColor: template.borderColor,
          borderStyle: template.borderStyle,
          borderWidth: (template.borderWidth as number) * 2,
        }}
      />
    </Stack>
  );
};
