import { Table, TableHead, TableBody, Stack, Typography } from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  type: string;
}
export const BasicExperience = ({ currentEmployee, template, type }: Props) => {
  return (
    <>
      <Stack sx={{ marginTop: 0, width: "100%" }}>
        {currentEmployee.workExperience &&
        currentEmployee.workExperience.length <= 0 ? (
          <>
            <StyledTableContainer
              template={template}
              sx={{
                marginTop: template.spacing,
              }}
            >
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <StyledTableRowTemplate template={template} key="300">
                    <StyledTableCellLabelTemplate template={template}>
                      {tt("workExperience", "en")}
                    </StyledTableCellLabelTemplate>
                    <StyledTableCellLabelTemplate
                      template={template}
                    ></StyledTableCellLabelTemplate>
                    <StyledTableCellLabelTemplate
                      template={template}
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      {tt("workExperience", "ar")}
                    </StyledTableCellLabelTemplate>
                  </StyledTableRowTemplate>
                </TableHead>
                <TableBody>
                  {type === "entrance" ? (
                    <>
                      <StyledTableRowTemplate template={template}>
                        <StyledTableCellLabelTemplate
                          template={template}
                          sx={{ textAlign: "center" }}
                        >
                          {tt("country", template.cvLang)}
                        </StyledTableCellLabelTemplate>
                        <StyledTableCellLabelTemplate
                          template={template}
                          sx={{ textAlign: "center" }}
                        >
                          {tt("profession", template.cvLang)}
                        </StyledTableCellLabelTemplate>
                        <StyledTableCellLabelTemplate
                          template={template}
                          sx={{ textAlign: "center" }}
                        >
                          {tt("forYears", template.cvLang)}
                        </StyledTableCellLabelTemplate>
                      </StyledTableRowTemplate>
                      <StyledTableRowTemplate
                        template={template}
                        sx={{ height: "155px" }}
                      >
                        <StyledTableCellValueTemplate
                          colSpan={3}
                          template={template}
                          sx={{ textAlign: "center" }}
                        >
                          {tt("firstTimer", template.cvLang)}
                        </StyledTableCellValueTemplate>
                      </StyledTableRowTemplate>
                    </>
                  ) : (
                    <StyledTableRowTemplate template={template}>
                      <StyledTableCellValueTemplate
                        colSpan={3}
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt("firstTimer", template.cvLang)}
                      </StyledTableCellValueTemplate>
                    </StyledTableRowTemplate>
                  )}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </>
        ) : type === "doubleHeading" ? (
          <StyledTableContainer
            template={template}
            sx={{
              marginTop: template.spacing,
            }}
          >
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRowTemplate key="3" template={template}>
                  <StyledTableCellLabelTemplate template={template}>
                    {tt("workExperience", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                  ></StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {tt("workExperience", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </TableHead>
              <TableBody>
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("country", "ar")}
                    <br />
                    {tt("country", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("profession", "ar")}
                    <br />
                    {tt("profession", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("forYears", "ar")}
                    <br />
                    {tt("forYears", "en")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
                {currentEmployee.workExperience &&
                  currentEmployee.workExperience.map((row, index) => (
                    <StyledTableRowTemplate template={template} key={index}>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.country as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.profession as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.years as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                    </StyledTableRowTemplate>
                  ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        ) : type === "onlyOneExperience" ? (
          <StyledTableContainer
            template={template}
            sx={{
              marginTop: template.spacing,
            }}
          >
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRowTemplate key="3" template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    colSpan={2}
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {tt("workExperience", "en")}
                  </StyledTableCellLabelTemplate>

                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {tt("workExperience", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </TableHead>
              <TableBody>
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "left" }}
                  >
                    {tt("profession", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellValueTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {currentEmployee.workExperience &&
                    currentEmployee.workExperience[0]
                      ? tt(
                          currentEmployee.workExperience[0]
                            .profession as string,
                          template.cvLang
                        )
                      : tt("firstTimer", template.cvLang)}
                  </StyledTableCellValueTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "right" }}
                  >
                    {tt("profession", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>

                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "left" }}
                  >
                    {tt("period", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellValueTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {currentEmployee.workExperience &&
                    currentEmployee.workExperience[0]
                      ? tt(
                          currentEmployee.workExperience[0].years as string,
                          template.cvLang
                        )
                      : ""}
                  </StyledTableCellValueTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "right" }}
                  >
                    {tt("period", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </TableBody>
            </Table>
          </StyledTableContainer>
        ) : type === "onlyOneExperienceFull" ? (
          <StyledTableContainer
            template={template}
            sx={{
              marginTop: template.spacing,
            }}
          >
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRowTemplate key="3" template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    colSpan={2}
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {tt("workExperience", "en")}
                  </StyledTableCellLabelTemplate>

                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {tt("workExperience", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </TableHead>
              <TableBody>
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "left" }}
                  >
                    {tt("profession", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellValueTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {currentEmployee.workExperience &&
                    currentEmployee.workExperience[0]
                      ? tt(
                          currentEmployee.workExperience[0]
                            .profession as string,
                          template.cvLang
                        )
                      : tt("firstTimer", template.cvLang)}
                  </StyledTableCellValueTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "right" }}
                  >
                    {tt("profession", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>

                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "left" }}
                  >
                    {tt("period", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellValueTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {currentEmployee.workExperience &&
                    currentEmployee.workExperience[0]
                      ? tt(
                          currentEmployee.workExperience[0].years as string,
                          template.cvLang
                        )
                      : ""}
                  </StyledTableCellValueTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "right" }}
                  >
                    {tt("period", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "left" }}
                  >
                    {tt("country", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellValueTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {currentEmployee.workExperience &&
                    currentEmployee.workExperience[0]
                      ? tt(
                          currentEmployee.workExperience[0].country as string,
                          template.cvLang
                        )
                      : ""}
                  </StyledTableCellValueTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "right" }}
                  >
                    {tt("country", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </TableBody>
            </Table>
          </StyledTableContainer>
        ) : type === "basic" || type === "entrance" ? (
          <StyledTableContainer
            template={template}
            sx={{
              marginTop: template.spacing,
            }}
          >
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRowTemplate key="3" template={template}>
                  <StyledTableCellLabelTemplate template={template}>
                    {tt("workExperience", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                  ></StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {tt("workExperience", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </TableHead>
              <TableBody>
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("country", template.cvLang)}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("profession", template.cvLang)}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("forYears", template.cvLang)}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
                {currentEmployee.workExperience &&
                  currentEmployee.workExperience.map((row, index) => (
                    <StyledTableRowTemplate template={template} key={index}>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.country as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.profession as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.years as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                    </StyledTableRowTemplate>
                  ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        ) : type === "noProfession" ? (
          <StyledTableContainer
            template={template}
            sx={{
              marginTop: template.spacing,
            }}
          >
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRowTemplate key="3" template={template}>
                  <StyledTableCellLabelTemplate template={template}>
                    {tt("workExperience", "en")}
                  </StyledTableCellLabelTemplate>

                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {tt("workExperience", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </TableHead>
              <TableBody>
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("country", "ar")}
                    <br />
                    {tt("country", "en")}
                  </StyledTableCellLabelTemplate>

                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("forYears", "ar")}
                    <br />
                    {tt("forYears", "en")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
                {currentEmployee.workExperience &&
                  currentEmployee.workExperience.map((row, index) => (
                    <StyledTableRowTemplate template={template} key={index}>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.country as string, template.cvLang)}
                      </StyledTableCellValueTemplate>

                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.years as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                    </StyledTableRowTemplate>
                  ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        ) : (
          <StyledTableContainer
            template={template}
            sx={{
              marginTop: template.spacing,
            }}
          >
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRowTemplate key="3" template={template}>
                  <StyledTableCellLabelTemplate template={template}>
                    {tt("workExperience", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                  ></StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {tt("workExperience", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </TableHead>
              <TableBody>
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("country", "ar")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("profession", "ar")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("forYears", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
                {currentEmployee.workExperience &&
                  currentEmployee.workExperience.map((row, index) => (
                    <StyledTableRowTemplate template={template} key={index}>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.country as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.profession as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellValueTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt(row.years as string, template.cvLang)}
                      </StyledTableCellValueTemplate>
                    </StyledTableRowTemplate>
                  ))}
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("country", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("profession", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    {tt("forYears", "en")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </Stack>
    </>
  );
};

export const BasicExperienceNoHeader = ({
  currentEmployee,
  template,
  type,
}: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableBody>
            {currentEmployee.workExperience &&
            currentEmployee.workExperience.length <= 0 ? (
              <>
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellValueTemplate
                    colSpan={2}
                    template={template}
                    sx={{ textAlign: "center" }}
                  >
                    <Typography
                      variant="h4"
                      color="red"
                      sx={{ textAlign: "center", fontWeight: 900 }}
                    >
                      {tt("firstTimer", template.cvLang)}
                    </Typography>
                    {/* {tt("firstTimer", template.cvLang)} */}
                  </StyledTableCellValueTemplate>
                </StyledTableRowTemplate>
              </>
            ) : (
              currentEmployee.workExperience &&
              currentEmployee.workExperience.length >= 1 && (
                <StyledTableRowTemplate template={template}>
                  <StyledTableCellValueTemplate
                    colSpan={2}
                    template={template}
                    sx={{ textAlign: "right" }}
                  >
                    {currentEmployee.workExperience &&
                    currentEmployee.workExperience[0] ? (
                      <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Typography
                          variant="h4"
                          color="red"
                          sx={{ textAlign: "left", fontWeight: 900 }}
                        >
                          EXPERIENCED:
                        </Typography>
                        <Typography
                          variant="h4"
                          color="red"
                          sx={{ textAlign: "left", fontWeight: 900 }}
                        >
                          {tt(
                            currentEmployee.workExperience[0].years as string,
                            template.cvLang
                          )}
                        </Typography>
                        <Typography
                          variant="h4"
                          color="red"
                          sx={{ textAlign: "right", fontWeight: 900 }}
                        >
                          {tt(
                            currentEmployee.workExperience[0].country as string,
                            template.cvLang
                          )}
                        </Typography>
                      </Stack>
                    ) : (
                      ""
                    )}
                  </StyledTableCellValueTemplate>
                </StyledTableRowTemplate>
              )
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
