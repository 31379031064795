import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ColorButton, GetInTouchArrow } from "./styles/componentStyled";
import TypeWriter from "./TypeWriter";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Header() {
  //const classes = useStyles();
  const theme = useTheme();
  const [isSudan, setIsSudan] = useState(false);
  const { t: ta } = useTranslation("auth");
  const { t: tl } = useTranslation("landing_page");

  // useEffect(() => {
  //   fetch("http://ip-api.com/json")
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (payload) {
  //       setIsSudan(payload.country === "sudan2");
  //     });
  // }, []);
  return (
    <Box id="home" sx={{ color: "white", width: "100%" }}>
      {/* </GradientBackground>
      <GradientBackground> */}
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          color: isSudan
            ? theme.palette.warning.main
            : theme.palette.primary.main,
          // pt: 2,
        }}
      >
        <img
          data-aos="fade-up"
          src="../platform_banner.jpg"
          alt="Web Development"
          width="100%"
          height="100%"
        />
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            padding: { xs: 2, md: 10 },
            backgroundColor: "#ffffff",
            opacity: "0.55",
            color: isSudan
              ? theme.palette.warning.main
              : theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Container maxWidth="xl">
            <Stack
              spacing={2}
              data-aos="fade-up"
              sx={{
                alignItems: "center",
                p: 2,
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                sx={{ textAlign: "center", textTransform: "camelCase" }}
              >
                {"EnjazPlus"}
              </Typography>

              <Typography
                variant="h3"
                component="h3"
                sx={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  display: { xs: "none", md: "block" },
                }}
              >
                {tl("slogan")}
              </Typography>

              <TypeWriter />

              <ColorButton variant="contained" href="/register">
                {ta("register")}
                <GetInTouchArrow>
                  <ArrowForward />
                </GetInTouchArrow>
              </ColorButton>
            </Stack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
