import { Table, TableHead, TableBody } from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";

import { tt } from "../../../../../data/langUtility";
import { Check } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import { BasicRate } from "./BasicRate";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  showEducation?: boolean | false;
  showOtherLanguage?: boolean | false;
}
export const BasicLanguage = ({
  currentEmployee,
  template,
  showEducation,
  showOtherLanguage,
}: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "left",
                  width: "33%",
                }}
              >
                {tt("language", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{ width: "34%" }}
              ></StyledTableCellLabelTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                  width: "33%",
                }}
              >
                {tt("language", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("arabic", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.education?.arabicLanguage as string,
                  template.cvLang
                )}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("arabic", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="1">
              <StyledTableCellLabelTemplate template={template}>
                {tt("english", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.education?.englishLanguage as string,
                  template.cvLang
                )}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("english", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            {showEducation && (
              <StyledTableRowTemplate template={template} key="10">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("education_level", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt(
                    currentEmployee.education?.educationLevel as string,
                    template.cvLang
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("education_level", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showOtherLanguage && (
              <StyledTableRowTemplate template={template} key="10">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("other", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt("ethiopian", template.cvLang)}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("other", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export const BasicLanguageDetail = ({ currentEmployee, template }: Props) => {
  const arabic = currentEmployee.education?.arabicLanguage as string;
  const english = currentEmployee.education?.englishLanguage as string;
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "left",
                  width: "50%",
                }}
              >
                {tt("language_knowledge", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "right",
                  width: "50%",
                }}
              >
                {tt("language_knowledge", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("english", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("english2", "ar")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("arabic", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("arabic2", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("poor", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {english === "poor" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>

              <StyledTableCellValueTemplate template={template}>
                {arabic === "poor" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("poor", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("fair", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {english === "fair" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>

              <StyledTableCellValueTemplate template={template}>
                {arabic === "fair" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("fair", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("good", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {english === "good" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>

              <StyledTableCellValueTemplate template={template}>
                {arabic === "good" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("good", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("fluent", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {english === "fluent" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>

              <StyledTableCellValueTemplate template={template}>
                {arabic === "fluent" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("fluent", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
export const BasicLanguageMaleda = ({ currentEmployee, template }: Props) => {
  const arabic = currentEmployee.education?.arabicLanguage as string;
  const english = currentEmployee.education?.englishLanguage as string;
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={1}
                width="25%"
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("language_knowledge", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={1}
                width="50%"
                sx={{
                  textAlign: "center",
                }}
              ></StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={1}
                width="25%"
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("language_knowledge", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                rowSpan={4}
                template={template}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("english", "en")}
                <br />
                {tt("english2", "ar")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                rowSpan={4}
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                <Table>
                  <TableBody>
                    <StyledTableRowTemplate template={template} key="100">
                      <StyledTableCellLabelTemplate
                        template={template}
                        colSpan={3}
                        sx={{ textAlign: "center" }}
                      >
                        Status
                      </StyledTableCellLabelTemplate>
                    </StyledTableRowTemplate>

                    <StyledTableRowTemplate template={template} key="10">
                      <StyledTableCellValueTemplate template={template}>
                        {english === "poor" ? <Check /> : <Close />}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellLabelTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt("poor2", "en")}
                        <br />
                        {tt("poor2", "ar")}
                      </StyledTableCellLabelTemplate>

                      <StyledTableCellValueTemplate template={template}>
                        {arabic === "poor" ? <Check /> : <Close />}
                      </StyledTableCellValueTemplate>
                    </StyledTableRowTemplate>

                    <StyledTableRowTemplate template={template} key="20">
                      <StyledTableCellValueTemplate template={template}>
                        {english === "good" ? <Check /> : <Close />}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellLabelTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt("good", "en")}
                        <br />
                        {tt("good", "ar")}
                      </StyledTableCellLabelTemplate>

                      <StyledTableCellValueTemplate template={template}>
                        {arabic === "good" ? <Check /> : <Close />}
                      </StyledTableCellValueTemplate>
                    </StyledTableRowTemplate>

                    <StyledTableRowTemplate template={template} key="30">
                      <StyledTableCellValueTemplate template={template}>
                        {english === "fluent" ? <Check /> : <Close />}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellLabelTemplate
                        template={template}
                        sx={{ textAlign: "center" }}
                      >
                        {tt("fluent", "en")}
                        <br />
                        {tt("fluent", "ar")}
                      </StyledTableCellLabelTemplate>

                      <StyledTableCellValueTemplate template={template}>
                        {arabic === "fluent" ? <Check /> : <Close />}
                      </StyledTableCellValueTemplate>
                    </StyledTableRowTemplate>
                  </TableBody>
                </Table>
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                rowSpan={4}
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("arabic", "en")}
                <br />
                {tt("arabic2", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
export const BasicLanguageLevels = ({ currentEmployee, template }: Props) => {
  const arabic = currentEmployee.education?.arabicLanguage as string;
  const english = currentEmployee.education?.englishLanguage as string;
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "left",
                  width: "50%",
                }}
              >
                {tt("language_knowledge", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "right",
                  width: "50%",
                }}
              >
                {tt("language_knowledge", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("english", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("english2", "ar")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("arabic", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("arabic2", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("fluent", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {english === "fluent" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>

              <StyledTableCellValueTemplate template={template}>
                {arabic === "fluent" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("fluent", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>

            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("average", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {english === "fair" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>

              <StyledTableCellValueTemplate template={template}>
                {arabic === "fair" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("average", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>

            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("poor2", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {english === "poor" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>

              <StyledTableCellValueTemplate template={template}>
                {arabic === "poor" ? <Check /> : <Close />}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("poor2", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export const BasicLanguageStar = ({
  currentEmployee,
  template,
  showEducation,
  showOtherLanguage,
}: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "left",
                  width: "33%",
                }}
              >
                {tt("language", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{ width: "34%" }}
              ></StyledTableCellLabelTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                  width: "33%",
                }}
              >
                {tt("language", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("arabic", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                <BasicRate
                  currentEmployee={currentEmployee}
                  template={template}
                  rateType="arabic"
                />
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("arabic", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="1">
              <StyledTableCellLabelTemplate template={template}>
                {tt("english", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                <BasicRate
                  currentEmployee={currentEmployee}
                  template={template}
                  rateType="english"
                />
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("english", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            {showEducation && (
              <StyledTableRowTemplate template={template} key="10">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("education_level", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt(
                    currentEmployee.education?.educationLevel as string,
                    template.cvLang
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("education_level", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showOtherLanguage && (
              <StyledTableRowTemplate template={template} key="10">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("other", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt("ethiopian", template.cvLang)}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("other", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
