import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
//import { styled } from "@mui/material/styles";
import { styled, Theme } from "@mui/material/styles";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import {
  selectPreference,
  toggleThis,
} from "../features/preferences/preferencesSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import NavbarContent from "./header/NavbarContent";
import { ShadowStyled } from "../styles/layoutStyled";
import { selectAuth } from "../features/auth/authSlice";
import React from "react";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBarCustom = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  [theme.breakpoints.up("lg")]: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginRight: theme.direction === "rtl" ? drawerWidth : 0,
      marginLeft: theme.direction === "ltr" ? drawerWidth : 0,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  backgroundColor: "theme.palette.primary.main",
}));

const DashboardNavbar = () => {
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const theme = useTheme();
  const { isDrawerOpen } = useAppSelector(selectPreference);
  const { user } = useAppSelector(selectAuth);
  const [isSudan, setIsSudan] = useState(false);
  useEffect(() => {
    if (
      user &&
      user.company &&
      user.company.depositAmount &&
      user.company.depositAmount === "sudan2"
    ) {
      setIsSudan(true);
    } else setIsSudan(false);
  }, [user]);
  useEffect(() => {
    if (isDesktop) {
      dispatch(toggleThis({ type: "Drawer", newValue: true }));
      //dispatch(toggleThis({ type: "Mini", newValue: false }));
    }
  }, [dispatch, isDesktop]);

  const handleDrawerOpen = () => {
    if (isDesktop) {
      dispatch(toggleThis({ type: "Drawer", newValue: true }));
      //dispatch(toggleThis({ type: "Mini", newValue: false }));
    } else {
      dispatch(toggleThis({ type: "Mobile", newValue: true }));
    }
  };
  return (
    <>
      <AppBarCustom position="fixed" open={isDrawerOpen}>
        <Box
          // sx={{
          //   background: `linear-gradient(to right, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 70%)`,
          // }}
          sx={{
            background: isSudan
              ? theme.direction === "ltr"
                ? `linear-gradient(90deg, #D21034 45%, #FFFFFF 65%, #000000 95%)`
                : `linear-gradient(-90deg, #D21034 45%, #FFFFFF 65%, #000000 95%)`
              : theme.direction === "ltr"
              ? `linear-gradient(90deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 70%)`
              : `linear-gradient(-90deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 70%)`,
          }}
        >
          <ShadowStyled>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: theme.direction === "ltr" ? "0px" : "-12px",
                  marginLeft: theme.direction === "rtl" ? "0px" : "-12px",
                  ...(isDrawerOpen && { display: "none" }),
                }}
                size="large"
              >
                <MenuIcon />
              </IconButton>

              <Avatar
                alt="avatar"
                src={user ? user.avatar : ""}
                sx={{
                  width: 48,
                  height: 48,
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                  fontSize: "48px",
                }}
              >
                {user?.company?.displayName.substring(0, 1)}
              </Avatar>
              <NavbarContent />
            </Toolbar>
          </ShadowStyled>
        </Box>
      </AppBarCustom>
    </>
  );
};

export default DashboardNavbar;
