import { ArrowDropDown, Print } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StyledListItemText } from "../../../styles/listStyled";
import CustomDialog from "../../../components/modals/CustomDialog";
import { useAppSelector } from "../../../app/hooks";
import { selectPreference } from "../../preferences/preferencesSlice";
import { getAmharicCalendarFormatted } from "../../../utils/calendarUtility";

interface Props {
  printAsharaList: Function;
  printEmbassyList: Function;
  printCorrectionLetter: Function;
  setLetterAmharicDate: Function;
  setLetterNumber: Function;
  setTypeOfCorrection: Function;
  setReason: Function;
}

export function EmployeesReportsMenu({
  printAsharaList,
  printEmbassyList,
  printCorrectionLetter,
  setLetterAmharicDate,
  setLetterNumber,
  setTypeOfCorrection,
  setReason,
}: Props) {
  const theme = useTheme();
  const { locale } = useAppSelector(selectPreference);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [openVisa, setOpenVisa] = useState(false);
  const [isCorrection, setIsCorrection] = useState(false);

  const dialogCloseVisa = () => {
    setOpenVisa(false);
  };

  const printAsharaListWithDate = () => {
    setIsCorrection(false);
    setOpenVisa(true);
  };
  const setCorrectionLetter = (type: string) => {
    setIsCorrection(true);
    setTypeOfCorrection(type);
    setTypeOfCorrection2(type);
    setOpenVisa(true);
  };
  const [letterNumber, setLetterNumber2] = useState("");
  const [reason, setReason2] = useState("");
  const [typeOfCorrection2, setTypeOfCorrection2] = useState("name");

  const [letterDate, setLetterDate] = useState<Date | null>(new Date());
  const [letterAmharicDate, setLetterAmharicDate2] = useState(
    getAmharicCalendarFormatted(new Date(), "/")
  );

  useEffect(() => {
    if (letterDate) {
      setLetterAmharicDate(getAmharicCalendarFormatted(letterDate, "/"));
      setLetterAmharicDate2(getAmharicCalendarFormatted(letterDate, "/"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letterDate]);

  const handleChange = (v: string) => {
    setLetterNumber(v);
    setLetterNumber2(v);
  };

  const handleChangeReason = (v: string) => {
    setReason(v);
    setReason2(v);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: theme.direction === "ltr" ? "right" : "left",
        }}
        id={"otherPrintsId"}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: theme.direction === "ltr" ? "right" : "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.default",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {[
          <MenuItem key="14" onClick={() => printEmbassyList()}>
            <StyledListItemText active={0}>Embassy Letter</StyledListItemText>
          </MenuItem>,
          <MenuItem key="16" onClick={() => setCorrectionLetter("name")}>
            <StyledListItemText active={0}>የስም ማስተካከያ ደብዳቤ</StyledListItemText>
          </MenuItem>,
          <MenuItem key="17" onClick={() => setCorrectionLetter("sex")}>
            <StyledListItemText active={0}>የጾታ ማስተካከያ ደብዳቤ</StyledListItemText>
          </MenuItem>,
          <MenuItem key="18" onClick={() => setCorrectionLetter("issued")}>
            <StyledListItemText active={0}>
              የimmigration_verified ማስተካከያ ደብዳቤ
            </StyledListItemText>
          </MenuItem>,
          <MenuItem key="15" onClick={() => printAsharaListWithDate()}>
            <StyledListItemText active={0}>የአሻራ ቀጠሮ ደብዳቤ</StyledListItemText>
          </MenuItem>,
        ]}
      </Menu>

      <Button
        aria-label="Other Prints"
        aria-controls={"otherPrintsId"}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        size="medium"
        color="secondary"
        variant="contained"
        // sx={{ alignSelf: "flex-end", marginBottom: "-30px" }}
        sx={{
          "& > :not(style)": {
            mt: 0,
            mr: theme.direction === "ltr" ? 1 : 0,
            ml: theme.direction === "rtl" ? 1 : 0,
          },
        }}
        startIcon={<Print />}
        endIcon={<ArrowDropDown />}
      >
        Print Letters
      </Button>
      <CustomDialog
        title="Show Letter"
        isOpen={openVisa}
        handleDialogClose={dialogCloseVisa}
      >
        <Box sx={{ p: 2, width: "400px", height: "300px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="column" alignItems="center">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={locale}
                >
                  <DatePicker
                    label={"የደብዳቤ ቀን"}
                    disableMaskedInput
                    views={["year", "month", "day"]}
                    minDate={new Date("2023-01-01")}
                    inputFormat="DD-MMM-YYYY"
                    value={letterDate}
                    onChange={(newValue) => {
                      setLetterDate(new Date(newValue as Date));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="startDate"
                        sx={{
                          mt: 1,
                        }}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography variant="h6" noWrap sx={{ width: "60%" }}>
                  {letterAmharicDate}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="letterNumber"
                label="ደብዳቤ ቁጥር"
                onChange={(e) => handleChange(e.target.value)}
                value={letterNumber}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              visibility={typeOfCorrection2 === "issued" ? "visible" : "hidden"}
            >
              <TextField
                id="reason"
                label="ምክንያት"
                onChange={(e) => handleChangeReason(e.target.value)}
                value={reason}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                size="medium"
                color="secondary"
                variant="contained"
                onClick={
                  isCorrection
                    ? () => printCorrectionLetter()
                    : () => printAsharaList()
                }
                fullWidth
                sx={{ mt: 1 }}
              >
                Show
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </>
  );
}
