import {
  Document,
  Page,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";

import { AsharaReportProps } from "../types";

Font.register({
  family: "Noto",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Naskh_Arabic/NotoNaskhArabic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Ethiopic",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Sans_Ethiopic/NotoSansEthiopic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Open Sans",
  format: "truetype",
  fonts: [
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      src: "/fonts/open-sans-regular.ttf",
    },
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      src: "/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

export const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },

  row2: {
    flexDirection: "row",
    alignItems: "center",
    height: 30,
  },
  row21: {
    borderColor: "#000000",
    borderWidth: 2,
    alignItems: "center",
    marginRight: -1,
    borderRightWidth: 0,
  },
  row31: {
    width: "25%",
    borderColor: "#000000",
    borderWidth: 1,
    alignItems: "center",
    marginRight: -1,
    borderTopWidth: 0,
  },
  row3: {
    flexDirection: "row",
    height: 30,
  },
  cellCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "medium",
    fontFamily: "Ethiopic",
    fontSize: "14px",
  },
  headerCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Ethiopic",
    fontSize: "14px",
  },
  header: {
    position: "absolute",
    top: 20,
    left: 0,
    right: 0,
    height: "150px",
    marginBottom: 20,
    width: "99%",
  },
  dateHeader: {
    textAlign: "left",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Ethiopic",
    fontSize: "14px",
  },
});
export const FingerPrintList = ({
  employees,
  user,
  letterDate,
  letterNumber,
}: AsharaReportProps) => {
  return (
    <Document
      creator="pinnasofts"
      author="pinnaSofts"
      title="enjazPlus"
      subject="Ashara List"
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <Image
            source={
              user && user.company && user.company?.header
                ? user.company?.header
                : "/noHeader.png"
            }
          />
        </View>
        <View style={{ marginTop: 110 }}>
          <View style={[styles.row2]} key="-20">
            <View style={{ width: "65%" }}></View>
            <View
              style={{
                width: "35%",
                borderBottomWidth: 2,
                borderColor: "black",
              }}
            >
              <Text style={styles.dateHeader}>{`ቀን: ${letterDate}`}</Text>
            </View>
          </View>
          <View style={[styles.row2]} key="-10">
            <View style={{ width: "65%" }}></View>
            <View
              style={{
                width: "35%",
                borderBottomWidth: 2,
                borderColor: "black",
              }}
            >
              <Text style={styles.dateHeader}>{`ቁጥር: ${letterNumber}`}</Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          <View
            style={[styles.row2, { paddingHorizontal: 20, height: 25 }]}
            key="-31"
          >
            <Text style={styles.dateHeader}>ለኢትዮጲያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ</Text>
          </View>
          <View
            style={[styles.row2, { paddingHorizontal: 20, height: 25 }]}
            key="-30"
          >
            <Text style={styles.dateHeader}>የስራና ክህሎት ሚኒስቴር</Text>
          </View>
          <View
            style={[
              styles.row2,
              {
                paddingHorizontal: 20,
              },
            ]}
            key="-40"
          >
            <Text
              style={[
                styles.dateHeader,
                {
                  width: "15%",
                  borderBottomWidth: 2,
                  borderColor: "black",
                },
              ]}
            >
              አዲስ አበባ
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          <View style={[styles.row2]} key="-50">
            <View style={{ width: "35%" }}></View>
            <View
              style={{
                width: "35%",
              }}
            >
              <Text
                style={[
                  styles.dateHeader,
                  { borderBottomWidth: 2, borderColor: "black" },
                ]}
              >
                ጉዳዩ: አሻራ መስጠትን ይመለከታል
              </Text>
            </View>
          </View>
          <View style={[{ paddingHorizontal: 20 }]} key="-60">
            <Text
              wrap={true}
              style={[styles.dateHeader, { fontWeight: "medium" }]}
            >
              {`ድርጅታችን ${
                user?.company?.nameAmharic
                  ? user?.company?.nameAmharic
                  : user?.company?.displayName
              } በአዋጅ ቁጥር 923/2008 መሰረት ተቋቁሞ ዜጎችን ወደ ውጭ ሀገር ለስራ በመላክ ላይ ይገኛል::`}
            </Text>
          </View>
          <View style={[{ paddingHorizontal: 20 }]} key="-70">
            <Text
              wrap={true}
              style={[styles.dateHeader, { fontWeight: "medium" }]}
            >
              {`በዚሁም መሰረት ስማቸው ከታች የተዘረዘሩ ተጓዦች በሚ/ር መ/ቤቱ አሻራ እንዲሰጡልን ስንል በትህትና እንጠይቃለን::`}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 5, paddingHorizontal: 20 }}>
          <View style={[styles.row2]} key="-1">
            <View style={[styles.row21, { width: "5%" }]}>
              <Text style={styles.headerCenter}>ተ.ቁ.</Text>
            </View>
            <View style={[styles.row21, { width: "39%" }]}>
              <Text style={styles.headerCenter}>ስም</Text>
            </View>
            <View style={[styles.row21, { width: "16%" }]}>
              <Text style={styles.headerCenter}>ፓስፖርት ቁ.</Text>
            </View>
            <View style={[styles.row21, { width: "18%" }]}>
              <Text style={styles.headerCenter}>አይዲ</Text>
            </View>
            <View style={[styles.row21, { width: "22%", borderRightWidth: 2 }]}>
              <Text style={styles.headerCenter}>ስልክ ቁ.</Text>
            </View>
          </View>
          {employees.map((employee, index) => {
            // console.log(employee);
            const lmisId =
              employee &&
              employee.additionalData &&
              employee.additionalData.lmisId &&
              employee.additionalData.lmisId.length > 5
                ? employee.additionalData.lmisId
                : "-";
            const telephone =
              employee &&
              employee.address &&
              employee.address.telephone &&
              employee.address.telephone.length > 8
                ? employee.address.telephone
                : "-";
            const nameAmharic =
              employee &&
              employee.additionalData &&
              employee.additionalData.fullNameAmharic &&
              employee.additionalData.fullNameAmharic.length > 8
                ? employee.additionalData.fullNameAmharic
                : "-";
            return (
              employee &&
              employee.passportNumber &&
              employee.passportNumber.length > 0 &&
              employee.additionalData &&
              employee.address && (
                <View style={[styles.row3]} key={index}>
                  <View style={[styles.row31, { width: "5%" }]}>
                    <Text style={styles.cellCenter}>{index + 1}</Text>
                  </View>
                  <View style={[styles.row31, { width: "39%" }]}>
                    <Text wrap={true} style={styles.cellCenter}>
                      {nameAmharic}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "16%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.passportNumber}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "18%" }]}>
                    <Text style={styles.cellCenter}>{lmisId}</Text>
                  </View>
                  <View style={[styles.row31, { width: "22%" }]}>
                    <Text style={styles.cellCenter}>{telephone}</Text>
                  </View>
                </View>
              )
            );
          })}
        </View>
        <View style={{ marginTop: 10 }}>
          <View style={[styles.row2]} key="-50">
            <View style={{ width: "65%" }}></View>
            <View style={{ width: "35%" }}>
              <Text style={[styles.dateHeader]}>ከሠላምታ ጋር</Text>
            </View>
          </View>

          <View style={[styles.row2, { marginTop: 40 }]} key="-80">
            <View style={{ width: "65%" }}></View>
            <View style={{ width: "35%" }}>
              <Text style={styles.dateHeader}>
                {user?.company?.managerNameAmharic}
              </Text>
            </View>
          </View>
          <View style={[styles.row2, { marginTop: 0 }]} key="-90">
            <View style={{ width: "65%" }}></View>
            <View style={{ width: "35%" }}>
              <Text style={styles.dateHeader}>ዋና ስራ አስኪያጅ</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
