import { Helmet } from "react-helmet";
import { Navigate, useNavigate, NavLink } from "react-router-dom";
import Link from "@mui/material/Link";

import { Form, FormikProps, Formik } from "formik";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { FormikTextField } from "../../components/Layout/FormikTextField";
import AuthSkeleton from "./AuthSkeleton";

import { registerCaptchaSchema } from "./validation";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectAuth, google, signUpRecaptcha } from "./authSlice";
import Toast from "../../components/Layout/Toast";
import { Autocomplete, Container, Stack, TextField } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { countries } from "../../components/dashboard/CountrySelect";
import ReCAPTCHA from "react-google-recaptcha";
import { createRef, useEffect, useState } from "react";
import { RecaptchaRegister } from "./types/authType";
import { useTranslation } from "react-i18next";
import React from "react";

export const SignUp = () => {
  const { loading, error, success, user } = useAppSelector(selectAuth);
  const { t: ta } = useTranslation("auth");
  const { t: te } = useTranslation("enums");
  const [isSudan, setIsSudan] = useState(false);
  // useEffect(() => {
  //   fetch("http://ip-api.com/json")
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (payload) {
  //       setIsSudan(payload.country === "sudan2");
  //     });
  // }, []);
  const [recaptchaUser, setRecaptchaUser] = useState<RecaptchaRegister>({
    name: "",
    email: "",
    phone: "",
    message: "",
    captcha: "",
    country: countries[0],
  });
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  if (user && user.email) {
    return <Navigate to="/app" />;
  }
  if (success) {
    navigate("/home");
  }

  const recaptchaRef = createRef<ReCAPTCHA>();
  const onReCAPTCHAChange = async (captchaCode: string | undefined | null) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      return;
    }
    try {
      dispatch(signUpRecaptcha({ ...recaptchaUser, captcha: captchaCode }));
    } catch (error) {
      //setError(error?.message || "Something went wrong");
    } finally {
      // Reset the reCAPTCHA when the request has failed or succeeded
      // so that it can be executed again if user submits another email.

      if (recaptchaRef.current) recaptchaRef.current.reset();
    }
  };
  return (
    <>
      <Helmet>
        <title>Sign Up | EnjazPlus</title>
      </Helmet>
      <Container maxWidth="sm" sx={{ height: "100vh" }}>
        <Card elevation={3} sx={{ m: 2 }}>
          <CardContent>
            <Stack>
              <Stack sx={{ mb: 3, alignItems: "center" }}>
                <Typography variant="h3" fontWeight={900}>
                  {ta("signUp")}
                </Typography>
              </Stack>

              {loading === "pending" ? (
                <AuthSkeleton />
              ) : (
                <>
                  <Formik
                    initialValues={recaptchaUser}
                    validationSchema={registerCaptchaSchema}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false);
                      recaptchaRef.current!.execute();
                      //console.log(values);
                      setRecaptchaUser({ ...values });
                      //dispatch(signUp(values));
                    }}
                  >
                    {(props: FormikProps<RecaptchaRegister>) => (
                      <Form>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey={
                            process.env.REACT_APP_RECAPTCHA_SITE_KEY as string
                          }
                          onChange={onReCAPTCHAChange}
                        />
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={7}>
                            <Autocomplete
                              id="country"
                              sx={{ width: "100%", mt: 1 }}
                              value={props.values.country}
                              options={countries}
                              size="small"
                              autoHighlight
                              getOptionLabel={(option) =>
                                te(option.label.toLowerCase()) +
                                "(+" +
                                option.phone +
                                ")"
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                              }
                              onChange={(e, value) => {
                                props.setFieldValue(
                                  "country",
                                  value !== null ? value : null
                                );
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="40"
                                    src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  {te(option.label)} ({option.code}) +
                                  {option.phone}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={ta("yourCountry")}
                                  name="country"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <FormikTextField
                              formikKey="phone"
                              label={ta("phone")}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormikTextField
                              formikKey="name"
                              label={ta("companyName")}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormikTextField
                              formikKey="email"
                              label={ta("email")}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormikTextField
                              formikKey="message"
                              label={ta("message")}
                              multiline
                              rows={2}
                            />
                          </Grid>
                        </Grid>

                        {error && (
                          <Toast severity="error">{error.message}</Toast>
                        )}
                        <Box component="div">
                          <Button
                            sx={{ width: "100%", marginY: "8px" }}
                            type="submit"
                            color={isSudan ? "warning" : "secondary"}
                            variant="contained"
                            disabled={!props.isValid}
                          >
                            {ta("signUp")}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  <Stack sx={{ my: 1, alignItems: "center" }} direction="row">
                    <Typography>{ta("haveAnAccount")}?</Typography>
                    <Link
                      component={NavLink}
                      underline="none"
                      to="/login"
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <>{ta("signin")}</>
                    </Link>
                  </Stack>
                </>
              )}
              <Divider variant="middle" sx={{ my: 2 }}>
                {ta("or")}
              </Divider>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    dispatch(google(credentialResponse.credential as string));

                    // console.log(credentialResponse);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  data-text="signin_with"
                  theme="filled_blue"
                  size="large"
                  data-logo_alignment="center"
                  data-shape="pill"
                  useOneTap
                />
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
