import { TableContainer, Paper, Table, TableBody } from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import Image from "mui-image";
import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  flagName?: string | "/saudiFlag.png";
}
export const BasicFlag = ({ currentEmployee, template, flagName }: Props) => {
  return (
    <>
      <Image
        src={flagName as string}
        alt={currentEmployee.passportNumber}
        width={template.flagWidth}
        height={template.flagHeight}
        shift="top"
        distance="2rem"
        shiftDuration={320}
        style={{
          objectFit: "fill",
          borderRadius: template.borderRadius,
          marginTop: template.spacing,
          borderColor: template.borderColor,
          borderWidth: template.borderWidth,
          borderStyle: template.borderStyle,
        }}
      />
    </>
  );
};

export const BasicFlagHorz = ({ currentEmployee, template }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        marginBottom: 0,
        borderRadius: template.borderRadius,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "15%" }}
            >
              {tt("country", template.cvLang)}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "27%" }}
            >
              <BasicFlag
                currentEmployee={currentEmployee}
                template={template}
                flagName="/ethiopiaFlag.png"
              />
            </StyledTableCellValueTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "16%" }}
            ></StyledTableCellLabelTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{ width: "15%" }}
            >
              {tt("destination", template.cvLang)}
            </StyledTableCellLabelTemplate>
            <StyledTableCellValueTemplate
              template={template}
              sx={{ width: "27%" }}
            >
              <BasicFlag
                currentEmployee={currentEmployee}
                template={template}
                flagName="/saudiFlag.png"
              />
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const BasicFlagVert = ({ currentEmployee, template }: Props) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        marginBottom: 0,
        borderRadius: template.borderRadius,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="33">
            <StyledTableCellLabelTemplate template={template}>
              {tt("country", template.cvLang)}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>

          <StyledTableRowTemplate template={template} key="333">
            <StyledTableCellValueTemplate template={template}>
              <BasicFlag
                currentEmployee={currentEmployee}
                template={template}
                flagName="/ethiopiaFlag.png"
              />
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>

          <StyledTableRowTemplate template={template} key="333">
            <StyledTableCellLabelTemplate template={template}>
              {tt("destination", template.cvLang)}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
          <StyledTableRowTemplate template={template} key="333">
            <StyledTableCellValueTemplate template={template}>
              <BasicFlag
                currentEmployee={currentEmployee}
                template={template}
                flagName="/saudiFlag.png"
              />
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
