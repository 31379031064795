import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  StyledTableRow,
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
} from "../../../../styles/tableStyles";
import { useTranslation } from "react-i18next";
import { Employee } from "../../types/processTypes";
interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const CvLanguage = ({ currentEmployee, template }: Props) => {
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 1,
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRow key="3">
              <StyledTableCellLabelTemplate colSpan={2} template={template}>
                {te("language") + " " + tt("and") + " " + te("education_level")}
              </StyledTableCellLabelTemplate>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow key="0">
              <StyledTableCellLabelTemplate template={template}>
                {te("arabic")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {te(currentEmployee.education?.arabicLanguage as string)}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
            <StyledTableRow key="1">
              <StyledTableCellLabelTemplate template={template}>
                {te("english")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {te(currentEmployee.education?.englishLanguage as string)}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
            <StyledTableRow key="2">
              <StyledTableCellLabelTemplate template={template}>
                {te("education_level")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {te(currentEmployee.education?.educationLevel as string)}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
