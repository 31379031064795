import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "dayjs/locale/ar-sa";
import "dayjs/locale/am";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MuiLink from "@mui/material/Link";

import {
  NavLink,
  NavLink as RouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import {
  default as Alert,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Backspace, VideoCall } from "@mui/icons-material";

import Toast from "../../components/Layout/Toast";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createEmployee,
  getEmployee,
  resetSelectedEmployee,
  selectProcesses,
} from "./processSlices";
//import Barcode, { Renderer } from "react-jsbarcode";
import {
  Employee,
  MaritalStatus,
  ProfessionalTypes,
  ReligionTypes,
  Sex,
} from "./types/processTypes";
import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { Form, Formik, FormikProps } from "formik";
import { createEmployeeSchema } from "./validation";
import Save from "@mui/icons-material/Save";
import { addDays } from "date-fns";
import { EmployeeTabs } from "./components/EmployeeTabs";
import { useTranslation } from "react-i18next";
import FacePhoto from "./components/FacePhoto";
import { usePDF } from "@react-pdf/renderer";
import { SaudiApplication } from "../../components/reports/embassy/SaudiApplication";
import { AuthUser, ClientTypes } from "../auth/types/authType";
import { Company } from "../setups/types/setupTypes";
import { CompanyFilter } from "../../components/filter/CompanyFilter";
import { selectAuth } from "../auth/authSlice";
import { getCompany, selectSetups } from "../setups/setupSlices";
import { ContractTranslation } from "../../components/reports/ContractTranslation";
import { EmployeeEntryMenu } from "./components/EmployeeEntryMenu";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const EmployeeEntry = () => {
  const { id } = useParams() as {
    id: string;
  };

  //const theme = useTheme();
  const [company, setCompany] = useState<Company>();
  const { t, i18n } = useTranslation();
  const { t: te } = useTranslation("enums");
  const { user } = useAppSelector(selectAuth);
  const {
    selectedEmployee,
    error,
    success,
    loading: employeeEntryLoading,
  } = useAppSelector(selectProcesses);
  const [opacity, setOpacity] = useState(0.1);
  const [pointerEvents, setPointerEvents] = useState("none");
  const [printVisibility, setPrintVisibility] = useState("none");
  const { locale } = useAppSelector(selectPreference);
  const navigate = useNavigate();
  const inputReference = useRef<HTMLInputElement>(null);
  const { selectedCompany } = useAppSelector(selectSetups);
  const theme = useTheme();

  const [companies, setCompanies] = useState<Company[]>([]);

  // const [subscriptionMessage, setSubscriptionMessage] = useState("");
  const [timeZone, setTimeZone] = useState(-3);
  useEffect(() => {
    const dt = new Date();
    let diffTZ = dt.getTimezoneOffset() / 60;
    setTimeZone(diffTZ);
  }, []);

  const application = SaudiApplication({
    employee: selectedEmployee,
    user: user as AuthUser,
    company: selectedCompany,
  });
  const [instance, updateInstance] = usePDF({
    document: application,
  });

  const contractTranslation = ContractTranslation({
    employee: selectedEmployee,
    user: user as AuthUser,
  });
  const [contractTranslationInstance, updateContractTranslationInstance] =
    usePDF({
      document: contractTranslation,
    });

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedEmployee) {
      updateInstance();
      updateContractTranslationInstance();
      if (selectedEmployee._id) {
        setOpacity(1);
        setPointerEvents("initial");
        if (selectedEmployee.visa && selectedEmployee.embassyProcess) {
          setPrintVisibility("flex");
        } else setPrintVisibility("none");
      } else {
        setOpacity(0.1);
        setPointerEvents("none");
        setPrintVisibility("none");
      }
      if (selectedEmployee.prospects)
        setSelected(selectedEmployee.prospects.map((f) => f.displayName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee]);

  useEffect(() => {
    if (inputReference && inputReference.current)
      inputReference.current.focus();
  }, [inputReference]);

  useEffect(() => {
    dispatch(changePageTitle(`Employee Entry`));

    if (id && id.length > 10) {
      dispatch(getEmployee(id));
    } else {
      if (user && user.company && user.company.enjazPlusLicense) {
        if (user.company.enjazPlusLicense === "expired") {
          alert(t("expiredMessage"));
          navigate("/app/employees");
        } else if (user.company.enjazPlusLicense === "quota") {
          alert(t("fulledQuota"));
          navigate("/app/employees");
        } else if (user.company.enjazPlusLicense === "renewExpired") {
          alert(
            "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አይችሉም፥፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
          );
          navigate("/app/employees");
        } else if (user.company.enjazPlusLicense === "renewal") {
          alert(
            "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አገልግሎት ይቋረጣል፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
          );
        }
        //alert("Your Database is Full");
        //navigate("/app/employees");
      } else resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  function resetFields() {
    //navigate("/employee/0");
    dispatch(resetSelectedEmployee());
  }

  useEffect(() => {
    if (user && user.company) {
      dispatch(getCompany(user.company._id as string));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (selectedCompany) {
      //console.log(selectedCompany);
      if (id && id.length < 11 && selectedCompany.enjazPlusLicense) {
        if (selectedCompany.enjazPlusLicense === "expired") {
          alert(t("expiredMessage"));
          navigate("/app/employees");
        } else if (selectedCompany.enjazPlusLicense === "quota") {
          alert(t("fulledQuota"));
          navigate("/app/employees");
        } else if (selectedCompany.enjazPlusLicense === "renewExpired") {
          alert(
            "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አይችሉም፥፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
          );
          navigate("/app/employees");
        } else if (selectedCompany.enjazPlusLicense === "renewal") {
          alert(
            "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አገልግሎት ይቋረጣል፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCompany]);

  // useEffect(() => {
  //   const listener = (event: any) => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       console.log("Enter key was pressed. Run your function.");
  //       event.preventDefault();
  //       // callMyFunction();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);
  const monthsShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const getDateFormatted = (dateToBeFormatted: string) => {
    let splitDate = dateToBeFormatted.split("-");
    const monthValue = parseInt(splitDate[1]) - 1;
    return splitDate[2] + "/" + monthsShort[monthValue] + "/" + splitDate[0];
  };
  const getGender = (gender: string) => {
    if (gender === "Female") return Sex.female;
    else return Sex.male;
  };
  const getReligion = (religion: string) => {
    if (religion === "Non Muslim") return ReligionTypes.non_muslim;
    else return ReligionTypes.muslim;
  };
  const getProfession = (profession: string) => {
    if (profession === "House Maid") return ProfessionalTypes.housemaid;
    else if (profession === "House Worker")
      return ProfessionalTypes.house_worker;
    else return ProfessionalTypes.driver;
  };
  const getMaritalStatus = (maritalStatus: string) => {
    if (maritalStatus === "Single" || maritalStatus === "SingleMarried")
      return MaritalStatus.single;
    else if (maritalStatus === "Married") return MaritalStatus.married;
    else return MaritalStatus.divorced;
  };

  const pasteEmployeeData = async (formikData: FormikProps<Employee>) => {
    try {
      const pasteContent = await navigator.clipboard.readText();
      if (pasteContent) {
        let contentArray = pasteContent.split("\r\n");
        //console.log(contentArray);
        if (contentArray.length < 10) contentArray = pasteContent.split("\n");
        if (contentArray.length < 10) return;
        formikData.setFieldValue(
          "surName",
          contentArray[contentArray.indexOf("Surname") + 1]
        );
        formikData.setFieldValue(
          "givenName",
          contentArray[contentArray.indexOf("Given Names") + 1]
        );
        formikData.setFieldValue(
          "passportNumber",
          contentArray[contentArray.indexOf("Passport Number") + 1]
        );
        formikData.setFieldValue(
          "placeOfIssue",
          contentArray[contentArray.indexOf("Passport Issue Place") + 1]
        );
        formikData.setFieldValue(
          "placeOfBirth",
          contentArray[contentArray.indexOf("City") + 1]
        );

        formikData.setFieldValue(
          "sex",
          getGender(contentArray[contentArray.indexOf("Gender") + 1])
        );
        formikData.setFieldValue(
          "additionalData.maritalStatus",
          getMaritalStatus(
            contentArray[contentArray.indexOf("Marital Status") + 1]
          )
        );
        formikData.setFieldValue(
          "additionalData.religion",
          getReligion(contentArray[contentArray.indexOf("Religion") + 1])
        );
        formikData.setFieldValue(
          "additionalData.appliedProfession",
          getProfession(contentArray[contentArray.indexOf("Job") + 1])
        );

        formikData.setFieldValue(
          "address.telephone",
          contentArray[contentArray.indexOf("Mobile Number") + 1]
        );

        formikData.setFieldValue(
          "dateOfBirth",
          getDateFormatted(
            contentArray[contentArray.indexOf("Date of Birth") + 1]
          )
        );
        formikData.setFieldValue(
          "passportIssueDate",
          getDateFormatted(
            contentArray[contentArray.indexOf("Passport Issue Date") + 1]
          )
        );
        formikData.setFieldValue(
          "passportExpiryDate",
          getDateFormatted(
            contentArray[contentArray.indexOf("Passport Expiry Date") + 1]
          )
        );
        formikData.setFieldValue(
          "sex",
          getGender(contentArray[contentArray.indexOf("Gender") + 1])
        );
        formikData.setFieldValue(
          "additionalData.maritalStatus",
          getMaritalStatus(
            contentArray[contentArray.indexOf("Marital Status") + 1]
          )
        );
        formikData.setFieldValue(
          "additionalData.religion",
          getReligion(contentArray[contentArray.indexOf("Religion") + 1])
        );
        formikData.setFieldValue(
          "additionalData.appliedProfession",
          getProfession(contentArray[contentArray.indexOf("Job") + 1])
        );

        formikData.setFieldValue(
          "address.telephone",
          contentArray[contentArray.indexOf("Mobile Number") + 1]
        );

        formikData.setFieldValue(
          "address.city",
          contentArray[contentArray.indexOf("City") + 1]
        );
        formikData.setFieldValue(
          "address.streetAddress",
          contentArray[contentArray.lastIndexOf("Address") + 1]
        );

        formikData.setFieldValue(
          "contactPerson.displayName",
          contentArray[contentArray.indexOf("Relative name") + 1]
        );
        formikData.setFieldValue(
          "contactPerson.kinship",
          contentArray[contentArray.indexOf("Relative kinship") + 1]
        );
        formikData.setFieldValue(
          "contactPerson.address.telephone",
          contentArray[contentArray.indexOf("Relative phone") + 1]
        );
        formikData.setFieldValue(
          "contactPerson.address.streetAddress",
          contentArray[contentArray.indexOf("Relative address") + 1]
        );
      }
    } catch {}
  };
  function downLoadPdf() {
    if (selectedCompany) {
      if (selectedCompany.enjazPlusLicense === "expired") {
        alert(t("expiredMessage"));
        return;
      } else if (selectedCompany.enjazPlusLicense === "renewExpired") {
        alert(
          "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አይችሉም፥፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
        );
        navigate("/app/employees");
      } else if (selectedCompany.enjazPlusLicense === "quota") {
        alert(t("fulledQuota"));
        return;
      } else if (selectedCompany.enjazPlusLicense === "renewal") {
        alert(
          "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አገልግሎት ይቋረጣል፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
        );
      }
    }
    let downloadLink = document.createElement("a");
    const fileName = selectedEmployee?.givenName + "_Enjaz.pdf";

    downloadLink.href = instance.url as string;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  function downLoadContractTranslation() {
    let downloadLink = document.createElement("a");
    const fileName = selectedEmployee?.givenName + "_Contract.pdf";

    downloadLink.href = contractTranslationInstance.url as string;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function printPdf() {
    if (selectedCompany) {
      if (selectedCompany.enjazPlusLicense === "expired") {
        alert(t("expiredMessage"));
        return;
      } else if (selectedCompany.enjazPlusLicense === "renewExpired") {
        alert(
          "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አይችሉም፥፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
        );
        navigate("/app/employees");
      } else if (selectedCompany.enjazPlusLicense === "quota") {
        alert(t("fulledQuota"));
        return;
      } else if (selectedCompany.enjazPlusLicense === "renewal") {
        alert(
          "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አገልግሎት ይቋረጣል፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
        );
      }
    }

    if (instance && instance.url) {
      const iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = instance.url as string;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }
  function printContractTranslation() {
    if (contractTranslationInstance && contractTranslationInstance.url) {
      const iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = contractTranslationInstance.url as string;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }
  const [selected, setSelected] = useState<string[]>([]);

  const handleChange = (value: string[], formikData: FormikProps<Employee>) => {
    if (value[value.length - 1] === "all") {
      setSelected(
        selected.length === companies.length
          ? []
          : companies.map((c) => c.displayName)
      );
      formikData.setFieldValue("prospects", companies);
      return;
    }
    setSelected(value as string[]);
    const filteredCompanies = companies.filter((c) =>
      value.includes(c.displayName)
    );
    formikData.setFieldValue("prospects", filteredCompanies);
  };
  // useEffect(() => {
  //   if (formikData && formikData.values && formikData.values.prospects) {
  //     setSelected(formikData.values.prospects.map((f) => f.displayName));
  //   }
  // }, [formikData]);
  useEffect(() => {
    if (user && user.company && user.company.partners) {
      setCompanies(user.company.partners);
    }
  }, [user]);

  useEffect(() => {
    if (employeeEntryLoading === "pending") {
      document.body.style.opacity = "0.5";
    } else {
      document.body.style.opacity = "1";
    }
  }, [employeeEntryLoading]);

  return (
    <div dir={i18n.dir()}>
      <Helmet>
        <title>
          {`${
            selectedEmployee?.givenName?.toUpperCase() as string
          } | EnjazPlus`}
        </title>
      </Helmet>

      {timeZone !== -4 && timeZone !== -3 ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
          spacing={3}
        >
          <Typography
            fontSize="md"
            fontFamily="Noto Sans Ethiopic"
            fontWeight="extrabold"
            color="error"
          >
            {`እባክህ ኮምፒውተርን TIMEZONE ወደ UTC+03:00(Kuwait, Riyadh) ወይም UTC+04:00(Abu Dhabi, Muscat) ቀይር `}
          </Typography>
          <Typography
            fontSize="md"
            fontFamily="Noto Sans Ethiopic"
            fontWeight="extrabold"
            color="error"
          >
            {`UTC+3 أو UTC+4 يرجى تغيير المنطقة الزمنية للكمبيوتر إلى `}
          </Typography>
          <Typography
            fontSize="md"
            fontFamily="Noto Sans Ethiopic"
            fontWeight="extrabold"
            color="error"
          >
            {`Please change computer TIMEZONE to UTC+03:00(Kuwait, Riyadh) or UTC+04:00(Abu Dhabi, Muscat) `}
          </Typography>
          <MuiLink
            color="secondary"
            href="https://www.youtube.com/watch?v=MrYSYwinLrs"
            sx={{ textDecoration: "none" }}
            target="_blank"
          >
            Windows 10 - Watch Video
            <VideoCall />
          </MuiLink>{" "}
          <MuiLink
            color="secondary"
            href="https://www.youtube.com/watch?v=PdhqQPBo1t0"
            sx={{ textDecoration: "none" }}
            target="_blank"
          >
            Windows 11 - Watch Video
            <VideoCall />
          </MuiLink>{" "}
        </Stack>
      ) : (
        <>
          <Box
            sx={{
              m: { xs: 0, md: 1 },
              px: { xs: 0, md: 2 },
            }}
          >
            <>
              <Formik
                enableReinitialize={true}
                initialValues={selectedEmployee as Employee}
                validationSchema={createEmployeeSchema}
                onSubmit={(values, actions) => {
                  //console.log(Math.random().toString(36).slice(2, 12));
                  actions.setSubmitting(false);
                  if (company) {
                    values = {
                      ...values,
                      owner: company,
                    };
                  } else if (user && user.company) {
                    values = {
                      ...values,
                      owner: user.company,
                    };
                  }
                  //console.log(values);
                  dispatch(createEmployee(values));
                }}
              >
                {(props: FormikProps<Employee>) => (
                  <Form>
                    <Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Tooltip title="Back to Employee list">
                          <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            component={RouterLink}
                            to={"/app/employees"}
                          >
                            <Backspace />
                          </Button>
                        </Tooltip>
                        {id && id.length < 20 && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Button
                              variant="contained"
                              onClick={() => pasteEmployeeData(props)}
                              sx={{
                                mt: 1,
                                backgroundImage: "url(/musanedLogo.svg)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100px 40px",
                                backgroundPositionX: "right",
                                paddingRight: "110px",
                              }}
                            >
                              Paste Employee Data From
                            </Button>

                            <Button
                              size="small"
                              color="secondary"
                              component={NavLink}
                              to={`/app/tutor/cbIC0G3FVWE`}
                            >
                              Watch Video
                              <VideoCall />
                            </Button>
                          </Stack>
                        )}
                        {/* <Tooltip
                      title={
                        t("addNew") + " " + t("employee") + " " + t("data")
                      }
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        component={NavLink}
                        to={`/app/employee/${Math.random().toString(36).slice(2, 12)}`}
                      >
                        <Add />
                      </Button>
                    </Tooltip> */}

                        <Stack
                          direction="row"
                          spacing={1}
                          alignSelf="flex-end"
                          sx={{
                            "& > :not(style)": {
                              m: 0,
                              ml: 1,
                            },
                            display: printVisibility,
                          }}
                        >
                          <EmployeeEntryMenu
                            printSaudiApplication={printPdf}
                            downloadSaudiApplication={downLoadPdf}
                            printContractTranslation={printContractTranslation}
                            downloadContractTranslation={
                              downLoadContractTranslation
                            }
                          />
                          {/* <Tooltip title={t("Download First Page")}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={downLoadPdf}
                          size="large"
                          sx={{ display: printVisibility }}
                        >
                          <Download />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t("Print First Page")}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={printContractTranslation}
                          size="large"
                          sx={{ display: printVisibility }}
                        >
                          <Print />
                          {" " + t("print")}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          t("save") + " " + t("employee") + " " + t("data")
                        }
                      >
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          size="large"
                          disabled={!props.isValid}
                        >
                          <Save />
                          {" " + t("save")}
                        </Button>
                      </Tooltip> */}
                        </Stack>
                      </Stack>
                      {/* <Divider variant="middle" sx={{ mt: 2 }} /> backgroundColor: "rgba(69, 90, 100, 0.08)" */}
                      <Accordion sx={{ my: 0 }} expanded>
                        <StyledAccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ width: "100%" }}
                          >
                            <Typography variant="h5" component="span">
                              Passport Data
                            </Typography>
                            <Typography variant="h5" component="span">
                              {`Status: ${te(props.values.status as string)}`}
                            </Typography>
                          </Stack>
                        </StyledAccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="space-between"
                          >
                            <Grid container item spacing={1} md={8} xs={12}>
                              {user &&
                                user.company &&
                                user.company.type ===
                                  ClientTypes.ForeignAgent && (
                                  <Grid item xs={12}>
                                    <CompanyFilter
                                      companyType={ClientTypes.LocalAgency}
                                      setCompany={setCompany}
                                    />
                                  </Grid>
                                )}
                              <Grid item md={4} xs={12}>
                                <FormikTextField
                                  autoFocus
                                  formikKey="givenName"
                                  label={t("givenName")}
                                  sx={{ mt: 1 }}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <FormikTextField
                                  formikKey="surName"
                                  label={t("surName")}
                                  sx={{ mt: 1 }}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <FormikTextField
                                  formikKey="sex"
                                  select
                                  label={te("sex")}
                                >
                                  {Object.keys(Sex).map((option) => (
                                    <MenuItem
                                      key={option}
                                      value={option as string}
                                    >
                                      {te(option)}
                                    </MenuItem>
                                  ))}
                                </FormikTextField>
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <FormikTextField
                                  formikKey="passportNumber"
                                  label={t("passportNumber")}
                                  sx={{ mt: 1 }}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <FormikTextField
                                  formikKey="placeOfBirth"
                                  label={t("placeOfBirth")}
                                  sx={{ mt: 1 }}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <FormikTextField
                                  formikKey="placeOfIssue"
                                  label={t("placeOfIssue")}
                                  sx={{ mt: 1 }}
                                />
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={locale}
                                >
                                  <DatePicker
                                    label={t("dateOfBirth")}
                                    views={["year", "month", "day"]}
                                    minDate={new Date("1920-01-01")}
                                    disableMaskedInput
                                    inputFormat="DD-MMM-YYYY"
                                    value={props.values.dateOfBirth}
                                    onChange={(value) => {
                                      props.setFieldValue(
                                        "dateOfBirth",
                                        value,
                                        true
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id="dateOfBirth"
                                        sx={{
                                          mt: 1,
                                        }}
                                        fullWidth
                                        size="small"
                                        disabled
                                        error={Boolean(
                                          props.touched.dateOfBirth &&
                                            props.errors.dateOfBirth
                                        )}
                                        helperText={
                                          props.touched.dateOfBirth &&
                                          props.errors.dateOfBirth
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={locale}
                                >
                                  <DatePicker
                                    label={t("dateOfIssue")}
                                    views={["year", "month", "day"]}
                                    minDate={new Date("2012-01-01")}
                                    maxDate={new Date("2025-12-31")}
                                    disableMaskedInput
                                    inputFormat="DD-MMM-YYYY"
                                    value={props.values.passportIssueDate}
                                    onChange={(value) => {
                                      const isDate = new Date(value as Date);
                                      // console.log(addYears(isDate, 5));
                                      props.setFieldValue(
                                        "passportIssueDate",
                                        value,
                                        true
                                      );
                                      props.setFieldValue(
                                        "passportExpiryDate",
                                        addDays(isDate, 1825),
                                        true
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{ mt: 1 }}
                                        id="passportIssueDate"
                                        fullWidth
                                        size="small"
                                        disabled
                                        error={Boolean(
                                          props.touched.passportIssueDate &&
                                            props.errors.passportIssueDate
                                        )}
                                        helperText={
                                          props.touched.passportIssueDate &&
                                          props.errors.passportIssueDate
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={locale}
                                >
                                  <DatePicker
                                    label={t("dateOfExpiry")}
                                    views={["year", "month", "day"]}
                                    minDate={new Date("2024-01-01")}
                                    maxDate={new Date("2035-01-01")}
                                    disableMaskedInput
                                    inputFormat="DD-MMM-YYYY"
                                    value={props.values.passportExpiryDate}
                                    onChange={(value) => {
                                      props.setFieldValue(
                                        "passportExpiryDate",
                                        value,
                                        true
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id="passportExpiryDate"
                                        sx={{ mt: 1 }}
                                        fullWidth
                                        size="small"
                                        disabled
                                        error={Boolean(
                                          props.touched.passportExpiryDate &&
                                            props.errors.passportExpiryDate
                                        )}
                                        helperText={
                                          props.touched.passportExpiryDate &&
                                          props.errors.passportExpiryDate
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <FormikTextField
                                  formikKey="additionalData.maritalStatus"
                                  select
                                  label={te("maritalStatus")}
                                >
                                  {Object.keys(MaritalStatus).map((option) => (
                                    <MenuItem
                                      key={option}
                                      value={option as string}
                                    >
                                      {te(option)}
                                    </MenuItem>
                                  ))}
                                </FormikTextField>
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <FormikTextField
                                  formikKey="additionalData.religion"
                                  select
                                  label={te("religion")}
                                >
                                  {Object.keys(ReligionTypes).map((option) => (
                                    <MenuItem
                                      key={option}
                                      value={option as string}
                                    >
                                      {te(option)}
                                    </MenuItem>
                                  ))}
                                </FormikTextField>
                              </Grid>
                              <Grid item md={4} xs={12}>
                                <FormikTextField
                                  formikKey="additionalData.appliedProfession"
                                  select
                                  label={te("applied") + " " + te("profession")}
                                >
                                  {Object.keys(ProfessionalTypes).map(
                                    (option) => (
                                      <MenuItem
                                        key={option}
                                        value={option as string}
                                      >
                                        {te(option)}
                                      </MenuItem>
                                    )
                                  )}
                                </FormikTextField>
                              </Grid>
                              <Grid item md={8} xs={12}>
                                <FormControl sx={{ width: "100%" }}>
                                  <InputLabel id="multiple-select-label">
                                    For Company
                                  </InputLabel>
                                  <Select
                                    labelId="multiple-select-label"
                                    id="prospect"
                                    name="prospect"
                                    label="For Company"
                                    multiple
                                    fullWidth
                                    size="small"
                                    sx={{ mt: 1 }}
                                    value={selected}
                                    onChange={(event) =>
                                      handleChange(
                                        event.target.value as string[],
                                        props
                                      )
                                    }
                                    renderValue={(selected) =>
                                      selected.length > 1
                                        ? selected.join(", ")
                                        : selected
                                    }
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight:
                                            ITEM_HEIGHT * 4.5 +
                                            ITEM_PADDING_TOP,
                                          width: 250,
                                        },
                                      },

                                      variant: "menu",
                                    }}
                                  >
                                    {companies.map((option) => (
                                      <MenuItem
                                        key={option._id}
                                        value={option.displayName}
                                        sx={{
                                          "&.Mui-selected": {
                                            backgroundColor:
                                              theme.palette.action.selected,
                                          },
                                          "&.Mui-selected:hover": {
                                            backgroundColor:
                                              theme.palette.action.hover,
                                          },
                                        }}
                                      >
                                        <ListItemIcon>
                                          <Checkbox
                                            color="secondary"
                                            checked={
                                              selected.indexOf(
                                                option.displayName as string
                                              ) > -1
                                            }
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={option.displayName}
                                        />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item md={4} xs={12}>
                                <Tooltip
                                  title={
                                    t("save") +
                                    " " +
                                    t("employee") +
                                    " " +
                                    t("data")
                                  }
                                >
                                  <Button
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    sx={{ mt: 1 }}
                                    disabled={!props.isValid}
                                  >
                                    <Save />
                                    {t("save")}
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              md={4}
                              xs={12}
                              sx={{
                                justifyContent: "flex-end",
                                opacity: opacity,
                                pointerEvents: pointerEvents,
                              }}
                            >
                              <FacePhoto />
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              opacity: opacity,
                              pointerEvents: pointerEvents,
                              m: 0,
                              p: 0,
                            }}
                          >
                            <EmployeeTabs
                              formikData={props}
                              printPdf={printPdf}
                              printVisibility={printVisibility}
                            />
                          </Box>
                          <br />
                          {success && (
                            <Toast severity="success">{success.message}</Toast>
                          )}
                          {error && (
                            <Toast severity="error">{error.message}</Toast>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                  </Form>
                )}
              </Formik>

              {error && <Toast severity="error">{error.message}</Toast>}
            </>
          </Box>
          <div>
            <input
              type="hidden"
              id="employeeTelephone"
              value={selectedEmployee.address?.telephone}
            />
            <input
              type="hidden"
              id="employeeCity"
              value={selectedEmployee.address?.city}
            />
            <input
              type="hidden"
              id="employeeAddress"
              value={selectedEmployee.address?.streetAddress}
            />

            <input
              type="hidden"
              id="contactName"
              value={selectedEmployee.contactPerson?.displayName}
            />
            <input
              type="hidden"
              id="contactKinship"
              value={selectedEmployee.contactPerson?.kinship}
            />
            <input
              type="hidden"
              id="contactTelephone"
              value={selectedEmployee.address?.telephone}
            />
            <input
              type="hidden"
              id="contactAddress"
              value={selectedEmployee.contactPerson?.address?.streetAddress}
            />
            <input
              type="hidden"
              id="fullNameAmharic"
              value={selectedEmployee.additionalData?.fullNameAmharic}
            />
          </div>
        </>
      )}
    </div>
  );
};
