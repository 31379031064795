import * as React from "react";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Employee, EmployeeStatus } from "../types/processTypes";
import { FormikProps } from "formik";
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandMore, Flight, Save, Stop } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { selectPreference } from "../../preferences/preferencesSlice";
import { StyledAccordionSummary } from "../../../styles/componentStyled";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { DateTimePicker } from "@mui/x-date-pickers";

interface ExperienceTabProps {
  formikData: FormikProps<Employee>;
}
export const ProcessStages = ({ formikData }: ExperienceTabProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { t: te } = useTranslation("enums");
  const { locale } = useAppSelector(selectPreference);

  const [selectedStage, setSelectedStage] = useState(false);
  const [contractCompleted, setContractCompleted] = useState(false);
  const [medicalCompleted, setMedicalCompleted] = useState(false);
  const [embassyIssued, setEmbassyIssued] = useState(false);
  const [osscCompleted, setOsscCompleted] = useState(false);
  const [labourIssued, setLabourIssued] = useState(false);
  const [flightBooked, setFlightBooked] = useState(false);
  const [traveled, setTraveled] = useState(false);
  const [onGoodCondition, setOnGoodCondition] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isChecked, setIsChecked] = useState(false);

  const currentStatus = formikData?.values?.status;
  useEffect(() => {
    if (currentStatus) {
      //console.log(currentStatus);
      switch (currentStatus) {
        case EmployeeStatus.selected:
          checkBoxes(0);
          break;
        case EmployeeStatus.contract_process:
          checkBoxes(1);
          break;
        case EmployeeStatus.medical_process:
          checkBoxes(2);
          break;
        case EmployeeStatus.embassy_process:
          checkBoxes(3);
          break;
        case EmployeeStatus.ossc_process:
          checkBoxes(4);
          break;
        case EmployeeStatus.labour_process:
          checkBoxes(5);
          break;
        case EmployeeStatus.flight_process:
          checkBoxes(6);
          break;
        case EmployeeStatus.flight_booked:
          checkBoxes(7);
          break;
        case EmployeeStatus.traveled:
          checkBoxes(8);
          break;
        case EmployeeStatus.on_good_condition:
          checkBoxes(9);
          break;
        case EmployeeStatus.returned:
          checkBoxes(9);
          break;
        case EmployeeStatus.lost:
          checkBoxes(9);
          break;
        case EmployeeStatus.has_complain:
          checkBoxes(9);
          break;
      }
    }
  }, [currentStatus]);
  const checkBoxes = (index2: number) => {
    const index = index2 - 1;
    if (index >= 0) setSelectedStage(true);
    if (index >= 1) setContractCompleted(true);
    if (index >= 2) setMedicalCompleted(true);
    if (index >= 3) setEmbassyIssued(true);
    if (index >= 4) setOsscCompleted(true);
    if (index >= 5) setLabourIssued(true);
    if (index >= 6) setFlightBooked(true);
    if (index >= 7) setTraveled(true);
    if (index === 8) setOnGoodCondition(true);
  };

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setCurrentIndex(index);
      if (event.target.checked) {
        setIsChecked(true);
        if (index >= 0) setSelectedStage(true);
        if (index >= 1) setContractCompleted(true);
        if (index >= 2) setMedicalCompleted(true);
        if (index >= 3) setEmbassyIssued(true);
        if (index >= 4) setOsscCompleted(true);
        if (index >= 5) setLabourIssued(true);
        if (index >= 6) setFlightBooked(true);
        if (index >= 7) setTraveled(true);
        if (index === 8) setOnGoodCondition(true);
      } else {
        setIsChecked(false);
        if (index === 0) setSelectedStage(false);
        if (index <= 1) setContractCompleted(false);
        if (index <= 2) setMedicalCompleted(false);
        if (index <= 3) setEmbassyIssued(false);
        if (index <= 4) setOsscCompleted(false);
        if (index <= 5) setLabourIssued(false);
        if (index <= 6) setFlightBooked(false);
        if (index <= 7) setTraveled(false);
        if (index <= 8) setOnGoodCondition(false);
      }
    };
  useEffect(() => {
    if (isChecked) {
      switch (currentIndex) {
        case 0:
          formikData.setFieldValue("status", EmployeeStatus.contract_process);
          break;
        case 1:
          formikData.setFieldValue("status", EmployeeStatus.medical_process);
          break;
        case 2:
          formikData.setFieldValue("status", EmployeeStatus.embassy_process);
          break;
        case 3:
          formikData.setFieldValue("status", EmployeeStatus.ossc_process);
          break;
        case 4:
          formikData.setFieldValue("status", EmployeeStatus.labour_process);
          break;
        case 5:
          formikData.setFieldValue("status", EmployeeStatus.flight_process);
          break;
        case 6:
          formikData.setFieldValue("status", EmployeeStatus.flight_booked);
          break;
        case 7:
          formikData.setFieldValue("status", EmployeeStatus.traveled);
          break;
        case 8:
          formikData.setFieldValue("status", EmployeeStatus.on_good_condition);
          break;
      }
    } else {
      switch (currentIndex) {
        case 0:
          formikData.setFieldValue("status", EmployeeStatus.new);
          break;
        case 1:
          formikData.setFieldValue("status", EmployeeStatus.contract_process);
          break;
        case 2:
          formikData.setFieldValue("status", EmployeeStatus.medical_process);
          break;
        case 3:
          formikData.setFieldValue("status", EmployeeStatus.embassy_process);
          break;
        case 4:
          formikData.setFieldValue("status", EmployeeStatus.ossc_process);
          break;
        case 5:
          formikData.setFieldValue("status", EmployeeStatus.labour_process);
          break;
        case 6:
          formikData.setFieldValue("status", EmployeeStatus.flight_process);
          break;
        case 7:
          formikData.setFieldValue("status", EmployeeStatus.flight_booked);
          break;
        case 8:
          formikData.setFieldValue("status", EmployeeStatus.traveled);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, currentIndex]);

  return (
    <Stack sx={{ px: 2, py: 2 }} alignItems="center">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}></Grid>
        <Grid container item xs={12} md={6} spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: theme.direction === "ltr" ? "left" : "right",
            }}
          >
            <FormControlLabel
              style={{ marginBottom: "0" }}
              control={
                <Switch
                  color="secondary"
                  checked={selectedStage}
                  name="selected"
                  onChange={handleChange(0)}
                />
              }
              label="Selected"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: theme.direction === "ltr" ? "left" : "right",
            }}
          >
            <FormControlLabel
              style={{ marginBottom: "0" }}
              control={
                <Switch
                  color="secondary"
                  checked={contractCompleted}
                  name="contractCompleted"
                  onChange={handleChange(1)}
                />
              }
              label="FMOL Approved Contract"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: theme.direction === "ltr" ? "left" : "right",
            }}
          >
            <FormControlLabel
              style={{ marginBottom: "0" }}
              control={
                <Switch
                  color="secondary"
                  checked={medicalCompleted}
                  name="medicalCompleted"
                  onChange={handleChange(2)}
                />
              }
              label="Medically FIT"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              textAlign: theme.direction === "ltr" ? "left" : "right",
            }}
          >
            <FormControlLabel
              style={{ marginBottom: "0" }}
              control={
                <Switch
                  color="secondary"
                  checked={embassyIssued}
                  name="embassyIssued"
                  onChange={handleChange(3)}
                />
              }
              label="Visa Issued"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            visibility={
              embassyIssued && formikData.values.embassyProcess
                ? "visible"
                : "hidden"
            }
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <DatePicker
                label={"Issued Date"}
                views={["year", "month", "day"]}
                minDate={new Date("2020-01-01")}
                disableMaskedInput
                inputFormat="DD-MMM-YYYY"
                value={formikData.values.embassyProcess?.stampedDate}
                onChange={(value) => {
                  formikData.setFieldValue(
                    "embassyProcess.stampedDate",
                    value,
                    true
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="stampedDate"
                    sx={{
                      mt: 0,
                    }}
                    fullWidth
                    size="small"
                    disabled
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: theme.direction === "ltr" ? "left" : "right",
            }}
          >
            <FormControlLabel
              style={{ marginBottom: "0" }}
              control={
                <Switch
                  color="secondary"
                  checked={osscCompleted}
                  name="osscCompleted"
                  onChange={handleChange(4)}
                />
              }
              label="Biometrics Completed"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: theme.direction === "ltr" ? "left" : "right",
            }}
          >
            <FormControlLabel
              style={{ marginBottom: "0" }}
              control={
                <Switch
                  color="secondary"
                  checked={labourIssued}
                  name="labourIssued"
                  onChange={handleChange(5)}
                />
              }
              label="LMIS Issued"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              textAlign: theme.direction === "ltr" ? "left" : "right",
            }}
          >
            <FormControlLabel
              style={{ marginBottom: "0" }}
              control={
                <Switch
                  color="secondary"
                  checked={flightBooked}
                  name="flightBooked"
                  onChange={handleChange(6)}
                />
              }
              label="Ticket Booked"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            visibility={flightBooked ? "visible" : "hidden"}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <DateTimePicker
                label={"Flight Date and Time"}
                views={["year", "month", "day", "hours", "minutes"]}
                minDate={new Date("2023-01-01")}
                disableMaskedInput
                inputFormat="DD-MMM-YYYY hh:mm A"
                value={formikData.values.flight?.flightDateTime}
                onChange={(value) => {
                  formikData.setFieldValue(
                    "flight.flightDateTime",
                    value,
                    true
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="flightDateTime"
                    sx={{
                      mt: 0,
                    }}
                    fullWidth
                    size="small"
                    disabled
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: theme.direction === "ltr" ? "left" : "right",
            }}
          >
            <FormControlLabel
              style={{ marginBottom: "0" }}
              control={
                <Switch
                  color="secondary"
                  checked={traveled}
                  name="traveled"
                  onChange={handleChange(7)}
                />
              }
              label="Traveled"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              textAlign: theme.direction === "ltr" ? "left" : "right",
            }}
          >
            <FormControlLabel
              style={{ marginBottom: "0" }}
              control={
                <Switch
                  color="secondary"
                  checked={onGoodCondition}
                  name="onGoodCondition"
                  onChange={handleChange(8)}
                />
              }
              label="Working Well"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            visibility={onGoodCondition ? "visible" : "hidden"}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={locale}
              >
                <DatePicker
                  label={"On Date"}
                  views={["year", "month", "day"]}
                  minDate={new Date("2023-01-01")}
                  disableMaskedInput
                  inputFormat="DD-MMM-YYYY"
                  value={
                    formikData.values.additionalData?.afterFlightStatusDate
                  }
                  onChange={(value) => {
                    formikData.setFieldValue(
                      "additionalData.afterFlightStatusDate",
                      value,
                      true
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="afterFlightStatusDate"
                      sx={{
                        mt: 1,
                      }}
                      fullWidth
                      size="small"
                      disabled
                    />
                  )}
                />
              </LocalizationProvider>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!formikData.isValid}
              size="large"
              sx={{ mt: 1, width: "100%" }}
            >
              <Save />
              {t("save") + " " + t("status")}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} alignItems="self-end">
          <Stack justifyContent="space-between">
            <Box
              visibility={
                (selectedStage ||
                  formikData?.values?.status === EmployeeStatus.discontinued) &&
                !traveled
                  ? "visible"
                  : "hidden"
              }
            >
              <Accordion>
                <StyledAccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stop />
                  <Typography variant="h6" component="div">
                    Process Discontinued
                  </Typography>
                </StyledAccordionSummary>

                <AccordionDetails>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12}>
                      <FormikTextField
                        formikKey="status"
                        select
                        label={"Status"}
                      >
                        {Object.keys(EmployeeStatus)
                          .filter((st) => st === EmployeeStatus.discontinued)
                          .map((option) => (
                            <MenuItem key={option} value={option as string}>
                              {te(option.toLowerCase())}
                            </MenuItem>
                          ))}
                      </FormikTextField>
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}
                      >
                        <DatePicker
                          label={"On Date"}
                          views={["year", "month", "day"]}
                          minDate={new Date("2023-01-01")}
                          disableMaskedInput
                          inputFormat="DD-MMM-YYYY"
                          value={
                            formikData.values.additionalData?.discontinuedDate
                          }
                          onChange={(value) => {
                            formikData.setFieldValue(
                              "additionalData.discontinuedDate",
                              value,
                              true
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="discontinuedDate"
                              sx={{
                                mt: 1,
                                "&.Mui-disabled": {
                                  color: "red",
                                },
                              }}
                              fullWidth
                              size="small"
                              disabled
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        formikKey="additionalData.discontinuedRemark"
                        label={t("remark")}
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box visibility={onGoodCondition ? "visible" : "hidden"}>
              <Accordion>
                <StyledAccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6" component="div">
                    <Flight />
                    Change After Flight Status
                  </Typography>
                </StyledAccordionSummary>

                <AccordionDetails>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      xs={12}
                      visibility={onGoodCondition ? "visible" : "hidden"}
                    >
                      <FormikTextField
                        formikKey="status"
                        select
                        label={"After Flight Status"}
                      >
                        {Object.keys(EmployeeStatus)
                          .filter(
                            (st) =>
                              st === EmployeeStatus.on_good_condition ||
                              st === EmployeeStatus.lost ||
                              st === EmployeeStatus.returned ||
                              st === EmployeeStatus.has_complain ||
                              st === EmployeeStatus.contract_completed
                          )
                          .map((option) => (
                            <MenuItem key={option} value={option as string}>
                              {te(option.toLowerCase())}
                            </MenuItem>
                          ))}
                      </FormikTextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      visibility={onGoodCondition ? "visible" : "hidden"}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}
                      >
                        <DatePicker
                          label={"On Date"}
                          views={["year", "month", "day"]}
                          minDate={new Date("2023-01-01")}
                          disableMaskedInput
                          inputFormat="DD-MMM-YYYY"
                          value={
                            formikData.values.additionalData
                              ?.afterFlightStatusDate
                          }
                          onChange={(value) => {
                            formikData.setFieldValue(
                              "additionalData.afterFlightStatusDate",
                              value,
                              true
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="afterFlightStatusDate"
                              sx={{
                                mt: 1,
                              }}
                              fullWidth
                              size="small"
                              disabled
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextField
                        formikKey="additionalData.afterFlightStatusRemark"
                        label={t("remark")}
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {/* {formikData &&
      formikData.values &&
      formikData.values.visa &&
      formikData.values.visa.visaNumber &&
      formikData.values.visa.visaNumber.length >= 9 ? (
        
      ) : (
        <Typography variant="h4" textTransform="capitalize">
          {" "}
          Fill out VISA information first....
        </Typography>
      )} */}
    </Stack>
  );
};
