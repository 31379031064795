import {
  Document,
  Page,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";

import { ReportProps } from "../types";
import { EmployeeStatus } from "../../../features/process/types/processTypes";
import { getAmharicCalendarFormatted } from "../../../utils/calendarUtility";

Font.register({
  family: "Noto",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Naskh_Arabic/NotoNaskhArabic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Ethiopic",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Sans_Ethiopic/NotoSansEthiopic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Open Sans",
  format: "truetype",
  fonts: [
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      src: "/fonts/open-sans-regular.ttf",
    },
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      src: "/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },

  row2: {
    flexDirection: "row",
    alignItems: "center",
    height: 30,
  },
  row21: {
    borderColor: "#000000",
    borderWidth: 2,
    alignItems: "center",
    marginRight: -1,
    borderRightWidth: 0,
  },
  row31: {
    width: "25%",
    borderColor: "#000000",
    borderWidth: 1,
    alignItems: "center",
    marginRight: -1,
    borderTopWidth: 0,
  },
  row3: {
    flexDirection: "row",
    height: 30,
  },
  cellCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "medium",
    fontFamily: "Ethiopic",
    fontSize: "14px",
  },
  headerCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Ethiopic",
    fontSize: "14px",
  },
  header: {
    position: "absolute",
    top: 20,
    left: 0,
    right: 0,
    height: "150px",
    marginBottom: 20,
  },
  dateHeader: {
    textAlign: "left",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Ethiopic",
    fontSize: "14px",
  },
});
export const AfterFlight = ({
  employees,
  user,
  reportType,
  durationStartDate,
  durationEndDate,
  letterDate,
  letterNumber,
}: ReportProps) => {
  return (
    <Document
      creator="pinnasofts"
      author="pinnaSofts"
      title="EnjazPlus"
      subject="Ashara List"
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <Image
            source={
              user && user.company && user.company?.header
                ? user.company?.header
                : "/noHeader.png"
            }
          />
        </View>
        <View style={{ marginTop: 120 }}>
          <View style={[styles.row2]} key="-20">
            <View style={{ width: "65%" }}></View>
            <View
              style={{
                width: "30%",
                borderBottomWidth: 2,
                borderColor: "black",
              }}
            >
              <Text style={styles.dateHeader}>{`ቀን: ${letterDate}`}</Text>
            </View>
            <View style={{ width: "5%" }}></View>
          </View>
          <View style={[styles.row2]} key="-10">
            <View style={{ width: "65%" }}></View>
            <View
              style={{
                width: "30%",
                borderBottomWidth: 2,
                borderColor: "black",
              }}
            >
              <Text style={styles.dateHeader}>{`ቁጥር: ${letterNumber}`}</Text>
            </View>
            <View style={{ width: "5%" }}></View>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          <View
            style={[styles.row2, { paddingHorizontal: 20, height: 25 }]}
            key="-31"
          >
            <Text style={styles.dateHeader}>ለኢትዮጲያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ</Text>
          </View>
          <View
            style={[styles.row2, { paddingHorizontal: 20, height: 25 }]}
            key="-30"
          >
            <Text style={styles.dateHeader}>የስራና ክህሎት ሚኒስቴር</Text>
          </View>
          <View
            style={[
              styles.row2,
              {
                paddingHorizontal: 20,
              },
            ]}
            key="-40"
          >
            <Text
              style={[
                styles.dateHeader,
                {
                  width: "12%",
                  borderBottomWidth: 2,
                  borderColor: "black",
                },
              ]}
            >
              አዲስ አበባ
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          <View style={[styles.row2]} key="-50">
            <View style={{ width: "15%" }}></View>
            <View
              style={{
                width: "55%",
              }}
            >
              <Text
                style={[
                  styles.dateHeader,
                  { borderBottomWidth: 2, borderColor: "black" },
                ]}
              >
                {reportType === EmployeeStatus.on_good_condition
                  ? `ጉዳዩ: ተጉዘው በስራ ላይ ያሉ ሰራተኞች ዝርዝር`
                  : reportType === EmployeeStatus.lost
                  ? `ጉዳዩ: ተጉዘው ከሚሰሩበት ቤት የጠፉ ሰራተኞች ዝርዝር`
                  : reportType === EmployeeStatus.returned
                  ? `ጉዳዩ: ኮንትራት ሳይጨርሱ የተመለሱ ሰራተኞች ዝርዝር`
                  : reportType === EmployeeStatus.contract_completed
                  ? `ጉዳዩ: ኮንትራት ጨርሰው የተመለሱ ሰራተኞች ዝርዝር`
                  : reportType === EmployeeStatus.discontinued
                  ? `ጉዳዩ: ኢሹድ ተብልው ፕሮሰስ ያቋረጡ ሰራተኞች ዝርዝር`
                  : ""}
              </Text>
            </View>
            <View style={{ width: "30%" }}></View>
          </View>
          <View style={[{ paddingHorizontal: 20 }]} key="-60">
            <Text
              wrap={true}
              style={[styles.dateHeader, { fontWeight: "medium" }]}
            >
              {`ድርጅታችን ${
                user?.company?.nameAmharic
                  ? user?.company?.nameAmharic
                  : user?.company?.displayName
              } በአዋጅ ቁጥር 923/2008 መሰረት ተቋቁሞ ወደ ስራ የገባ መሆኑ ይታወቃል::`}
            </Text>
          </View>
          <View style={[{ paddingHorizontal: 20 }]} key="-70">
            <Text
              wrap={true}
              style={[styles.dateHeader, { fontWeight: "medium" }]}
            >
              {reportType === EmployeeStatus.on_good_condition
                ? `በዚሁም መሰረት ስማቸው ከታች የተዘረዘሩ በድርጅታችን አማካኝነት ከ${durationStartDate} እስከ ${durationEndDate} ተጉዘው በስራ ላይ ያሉ መሆኑን እናሳውቃለን ::`
                : reportType === EmployeeStatus.lost
                ? `በዚሁም መሰረት ስማቸው ከታች የተዘረዘሩ ተጓዦች ከ${durationStartDate} እስከ ${durationEndDate} ከሚሰሩብት ቤት መጥፋታቸውን እናሳውቃለን::`
                : reportType === EmployeeStatus.returned
                ? `በዚሁም መሰረት ስማቸው ከታች የተዘረዘሩ ተጓዦች ከ${durationStartDate} እስከ ${durationEndDate} ኮንትራት ሳይጨርሱ መመለሳቸውን እናሳውቃለን::`
                : reportType === EmployeeStatus.contract_completed
                ? `በዚሁም መሰረት ስማቸው ከታች የተዘረዘሩ ተጓዦች ከ${durationStartDate} እስከ ${durationEndDate} ኮንትራት ጨርሰው መመለሳቸውን እናሳውቃለን::`
                : reportType === EmployeeStatus.discontinued
                ? `በዚሁም መሰረት ስማቸው ከታች የተዘረዘሩ ከ${durationStartDate} እስከ ${durationEndDate} ኢሹድ ተብለው ሳይበሩ በፊት ፕሮሰስ ማቋረጣቸውን እናሳውቃለን::`
                : ""}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 5, paddingHorizontal: 20 }}>
          <View style={[styles.row2]} key="-1">
            <View style={[styles.row21, { width: "5%" }]}>
              <Text style={styles.headerCenter}>ተ.ቁ.</Text>
            </View>
            <View style={[styles.row21, { width: "35%" }]}>
              <Text style={styles.headerCenter}>ስም</Text>
            </View>
            <View style={[styles.row21, { width: "20%" }]}>
              <Text style={styles.headerCenter}>ፓስፖርት ቁ.</Text>
            </View>
            <View style={[styles.row21, { width: "20%" }]}>
              <Text style={styles.headerCenter}>ሌበር አይዲ</Text>
            </View>
            <View style={[styles.row21, { width: "20%", borderRightWidth: 2 }]}>
              <Text style={styles.headerCenter}>
                {reportType === EmployeeStatus.on_good_condition
                  ? `የተጓዙበት ቀን`
                  : reportType === EmployeeStatus.lost
                  ? `የጠፉበት ቀን`
                  : reportType === EmployeeStatus.returned
                  ? `የተመለሱበት ቀን`
                  : reportType === EmployeeStatus.contract_completed
                  ? `የተጠናቀቀበት ቀን`
                  : reportType === EmployeeStatus.discontinued
                  ? `ያቋረጡበት ቀን`
                  : ""}
              </Text>
            </View>
          </View>
          {employees.map((employee, index) => {
            const stDate =
              employee &&
              employee.additionalData &&
              employee.additionalData.afterFlightStatusDate
                ? employee.additionalData.afterFlightStatusDate
                : new Date();
            const discDate =
              employee &&
              employee.additionalData &&
              employee.additionalData.discontinuedDate
                ? employee.additionalData.discontinuedDate
                : new Date();
            const nameAmharic =
              employee &&
              employee.additionalData &&
              employee.additionalData.fullNameAmharic &&
              employee.additionalData.fullNameAmharic.length > 8
                ? employee.additionalData.fullNameAmharic
                : employee.givenName + " " + employee.surName;
            const labourId =
              employee &&
              employee.additionalData &&
              employee.additionalData.labourId &&
              employee.additionalData.labourId.length > 5
                ? employee.additionalData.labourId
                : "-";
            return (
              employee &&
              employee.passportNumber &&
              employee.passportNumber.length > 0 &&
              employee.additionalData &&
              employee.address && (
                <View style={[styles.row3]} key={index}>
                  <View style={[styles.row31, { width: "5%" }]}>
                    <Text style={styles.cellCenter}>{index + 1}</Text>
                  </View>
                  <View style={[styles.row31, { width: "35%" }]}>
                    <Text wrap={true} style={styles.cellCenter}>
                      {nameAmharic}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "20%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.passportNumber}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "20%" }]}>
                    <Text style={styles.cellCenter}>{labourId}</Text>
                  </View>
                  <View style={[styles.row31, { width: "20%" }]}>
                    <Text style={styles.cellCenter}>
                      {reportType === EmployeeStatus.on_good_condition ||
                      reportType === EmployeeStatus.lost ||
                      reportType === EmployeeStatus.returned ||
                      reportType === EmployeeStatus.contract_completed
                        ? getAmharicCalendarFormatted(stDate, "/")
                        : reportType === EmployeeStatus.discontinued
                        ? getAmharicCalendarFormatted(discDate, "/")
                        : ""}
                    </Text>
                  </View>
                </View>
              )
            );
          })}
        </View>
        <View style={{ marginTop: 10 }}>
          <View style={[styles.row2]} key="-50">
            <View style={{ width: "65%" }}></View>
            <View style={{ width: "35%" }}>
              <Text style={[styles.dateHeader]}>ከሠላምታ ጋር</Text>
            </View>
          </View>

          <View style={[styles.row2, { marginTop: 50 }]} key="-80">
            <View style={{ width: "65%" }}></View>
            <View style={{ width: "35%" }}>
              <Text style={styles.dateHeader}>
                {user?.company?.managerNameAmharic}
              </Text>
            </View>
          </View>
          <View style={[styles.row2, { marginTop: 0 }]} key="-90">
            <View style={{ width: "65%" }}></View>
            <View style={{ width: "35%" }}>
              <Text style={styles.dateHeader}>ዋና ስራ አስኪያጅ</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
