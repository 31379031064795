import {
  ArrowDropDown,
  Download,
  Print,
  RemoveRedEye,
} from "@mui/icons-material";

import {
  Box,
  Button,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StyledListItemText } from "../../../styles/listStyled";
import CustomDialog from "../../../components/modals/CustomDialog";
import { useAppSelector } from "../../../app/hooks";
import {
  selectPreference,
  setLanguage,
} from "../../preferences/preferencesSlice";
import { EmbassyListCommission } from "../../../components/reports/embassy/EmbassyListCommission";
import { usePDF } from "@react-pdf/renderer";
import { Employee, Sex } from "../types/processTypes";
import React from "react";
import { selectAuth } from "../../auth/authSlice";
import { selectSetups } from "../../setups/setupSlices";
import { AuthUser } from "../../auth/types/authType";
import { useTranslation } from "react-i18next";
import { FormikTextField } from "../../../components/Layout/FormikTextField";

interface Props {
  employees: Employee[];
  printAsharaList: Function;
  printEmbassyList: Function;
  printCorrectionLetter: Function;
  setLetterAmharicDate: Function;
  setLetterNumber: Function;
  setTypeOfCorrection: Function;
  setReason: Function;
}

export function CommissionsReportsMenu({
  employees,
  printAsharaList,
  printEmbassyList,
  printCorrectionLetter,
  setLetterAmharicDate,
  setLetterNumber,
  setTypeOfCorrection,
  setReason,
}: Props) {
  const theme = useTheme();
  const { locale } = useAppSelector(selectPreference);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const { user } = useAppSelector(selectAuth);
  const { selectedCompany } = useAppSelector(selectSetups);
  const [openVisa, setOpenVisa] = useState(false);
  const [isCorrection, setIsCorrection] = useState(false);

  const [reason, setReason2] = useState("");

  const [letterNumber2, setLetterNumber2] = useState("");
  const [letterAmharicDate2, setLetterAmharicDate2] = useState(
    new Date().toLocaleDateString()
  );
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const dialogCloseVisa = () => {
    setOpenVisa(false);
  };
  // function timeout(delay: number) {
  //   return new Promise((res) => setTimeout(res, delay));
  // }
  const printAsharaListWithDate = () => {
    setIsCorrection(false);
    setOpenVisa(true);
  };
  const setCorrectionLetter = async (type: string) => {
    printAsharaList2();
  };

  const handleChangeDate = (v: string) => {
    setLetterAmharicDate2(v);
  };

  const handleChangeNumber = (v: string) => {
    setLetterNumber2(v);
  };

  const handleChangeSubject = (v: string) => {
    setSubject(v);
  };

  const handleChangeBody = (v: string) => {
    setBody(v);
  };

  const offices = [
    {
      toNumber: "first",
      toText:
        " سعادة قنصل سفارة خادم الحرمين الشريفين الموقر                 اديس ابابا",
    },
    {
      toNumber: "second",
      toText:
        "السادة /  وزارة الخارجية السعودية ء قسم الاسترداد       المحترمون",
    },
  ];
  const [selectedOffice, setSelectedOffice] = useState(
    " سعادة قنصل سفارة خادم الحرمين الشريفين الموقر                 اديس ابابا"
  );
  const handleChange = (number: string) => {
    if (offices) setSelectedOffice(number as string);
  };

  // const asharaList = EmbassyListCommission({
  //   employees: employees,
  //   user: user as AuthUser,
  //   company: selectedCompany,
  //   letterDate: letterAmharicDate2 as string,
  //   letterNumber: letterNumber2 as string,
  //   subject: subject as string,
  //   body: body as string,
  // });
  const [instanceAshara, updateInstanceAshara] = usePDF({
    document: (
      <EmbassyListCommission
        employees={employees}
        user={user as AuthUser}
        company={selectedCompany}
        letterDate={letterAmharicDate2 as string}
        letterNumber={letterNumber2 as string}
        office={selectedOffice as string}
        subject={subject as string}
        body={body as string}
      />
    ),
  });
  useEffect(() => {
    updateInstanceAshara();
  }, [letterAmharicDate2, letterNumber2, updateInstanceAshara]);

  const handleChangeReason = (v: string) => {
    setReason(v);
    setReason2(v);
  };
  function printAsharaList2() {
    if (instanceAshara && instanceAshara.url) {
      const iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = instanceAshara.url as string;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }
  function downLoadPdf() {
    let downloadLink = document.createElement("a");
    const fileName = letterNumber2 + "_Letter.pdf";

    downloadLink.href = instanceAshara.url as string;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: theme.direction === "ltr" ? "right" : "left",
        }}
        id={"otherPrintsId"}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: theme.direction === "ltr" ? "right" : "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.default",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {[
          <MenuItem key="14" onClick={() => printEmbassyList()}>
            <StyledListItemText active={0}>
              {t("show") + " " + t("list")}
            </StyledListItemText>
          </MenuItem>,
          <MenuItem key="17" onClick={() => printAsharaList()}>
            <StyledListItemText active={0}>
              {t("download") + " " + t("list")}
            </StyledListItemText>
          </MenuItem>,

          <MenuItem key="15" onClick={() => printAsharaListWithDate()}>
            <StyledListItemText active={0}>
              {t("letter_template")}
            </StyledListItemText>
          </MenuItem>,
          // <MenuItem key="16" onClick={() => setCorrectionLetter("name")}>
          //   <StyledListItemText active={0}>የስም ማስተካከያ ደብዳቤ</StyledListItemText>
          // </MenuItem>,
          // <MenuItem key="17" onClick={() => setCorrectionLetter("sex")}>
          //   <StyledListItemText active={0}>የጾታ ማስተካከያ ደብዳቤ</StyledListItemText>
          // </MenuItem>,
          // <MenuItem key="18" onClick={() => setCorrectionLetter("issued")}>
          //   <StyledListItemText active={0}>
          //     የimmigration_verified ማስተካከያ ደብዳቤ
          //   </StyledListItemText>
          // </MenuItem>,
        ]}
      </Menu>

      <Button
        aria-label="Other Prints"
        aria-controls={"otherPrintsId"}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        size="medium"
        color="warning"
        variant="contained"
        // sx={{ alignSelf: "flex-end", marginBottom: "-30px" }}
        sx={{
          "& > :not(style)": {
            mt: 0,
            mr: theme.direction === "ltr" ? 1 : 0,
            ml: theme.direction === "rtl" ? 1 : 0,
          },
        }}
        startIcon={<Print />}
        endIcon={<Download />}
      >
        {t("print_download_letters")}
      </Button>
      <CustomDialog
        title={t("letter")}
        isOpen={openVisa}
        handleDialogClose={dialogCloseVisa}
      >
        <Box sx={{ p: 2, width: "auto", height: "auto" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} direction="row">
              <InputLabel id="multiple-select-label">{t("to")}</InputLabel>
              <Select
                value={selectedOffice}
                labelId="multiple-select-label"
                onChange={(event) => handleChange(event.target.value as string)}
                displayEmpty
                autoWidth
                variant="standard"
                label={t("to")}
                fullWidth
                sx={{
                  color: "black",
                }}
              >
                {offices.map((lang) => (
                  <MenuItem key={lang.toNumber} value={lang.toText}>
                    {lang.toText}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="letterDate"
                label={t("date")}
                onChange={(e) => handleChangeDate(e.target.value)}
                value={letterAmharicDate2}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="letterNumber"
                label={t("number")}
                onChange={(e) => handleChangeNumber(e.target.value)}
                value={letterNumber2}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="subject"
                label={t("subject")}
                onChange={(e) => handleChangeSubject(e.target.value)}
                value={subject}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="body"
                label={t("message")}
                onChange={(e) => handleChangeBody(e.target.value)}
                value={body}
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={6}>
              <Button
                size="medium"
                color="warning"
                variant="contained"
                onClick={
                  isCorrection
                    ? () => printCorrectionLetter()
                    : () => setCorrectionLetter("ashara")
                }
                fullWidth
                sx={{ mt: 1 }}
              >
                <RemoveRedEye />
                {t("show")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title={t("download")}>
                <Button
                  color="warning"
                  variant="contained"
                  onClick={downLoadPdf}
                  size="medium"
                  fullWidth
                  sx={{ mt: 1 }}
                >
                  <Download />
                  {t("download")}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </>
  );
}
