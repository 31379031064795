import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchEmployees,
  selectProcesses,
  removeEmployee,
} from "../process/processSlices";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { NavLink as RouterLink } from "react-router-dom";

import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
//import Avatar from "@mui/material/Avatar";
import {
  IconButton,
  Stack,
  Button,
  Typography,
  Tooltip,
  Box,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  useTheme,
} from "@mui/material";
import { Add, Edit, Delete, Print } from "@mui/icons-material";

import { StyledTableCell, StyledTableRow } from "../../styles/tableStyles";
import TableSkeleton from "../../components/Layout/TableSkeleton";
import Paging from "../../components/Layout/Paging";
import Toast from "../../components/Layout/Toast";
import { usePDF } from "@react-pdf/renderer";
import { selectAuth } from "../auth/authSlice";
import { AuthUser } from "../auth/types/authType";
import { EmbassyList } from "../../components/reports/embassy/EmbassyList";
import { EmbassyListCommission } from "../../components/reports/embassy/EmbassyListCommission";
import { Employee } from "./types/processTypes";
import { useTranslation } from "react-i18next";
import { selectSetups } from "../setups/setupSlices";
import { CommissionsReportsMenu } from "./components/CommissionsReportsMenu";
import React from "react";
// import { Company } from "../setups/types/setupTypes";

export const Commissions = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    employeesWithCount: { employees, totalCount },
    loading,
    success,
    error,
  } = useAppSelector(selectProcesses);
  //const { Employee } = useAppSelector(selectAuth);
  const { user } = useAppSelector(selectAuth);
  const { selectedCompany } = useAppSelector(selectSetups);
  const { searchText } = useAppSelector(selectPreference);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectCandidates, setSelectCandidates] = useState<boolean[]>([false]);
  // const [selectAll, setSelectAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  //const { selectedCompany } = useAppSelector(selectSetups);
  // const [company, setCompany] = useState<Company>();

  const [letterAmharicDate, setLetterAmharicDate] = useState(
    new Date().toLocaleDateString()
  );
  const [letterNumber, setLetterNumber] = useState("");
  const [typeOfCorrection, setTypeOfCorrection] = useState("name");
  const [reason, setReason] = useState("");

  const [isSudan, setIsSudan] = useState(false);
  useEffect(() => {
    if (
      user &&
      user.company &&
      user.company.depositAmount &&
      user.company.depositAmount === "sudan"
    ) {
      setIsSudan(true);
    } else setIsSudan(false);
  }, [user]);

  const application = EmbassyList({
    employees: selectedEmployees,
    user: user as AuthUser,
  });
  const [instance, updateInstance] = usePDF({
    document: application,
  });
  function printEmbassyList() {
    //console.log(instance);
    if (instance && instance.url) {
      const iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = instance.url as string;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }

  // const asharaList = EmbassyListCommission({
  //   employees: selectedEmployees,
  //   user: user as AuthUser,
  //   company: selectedCompany,
  //   letterDate: letterAmharicDate as string,
  //   letterNumber: letterNumber as string,
  // });
  // const [instanceAshara, updateInstanceAshara] = usePDF({
  //   document: asharaList,
  // });

  useEffect(() => {
    if (typeOfCorrection.startsWith("ashara")) {
      // updateInstanceAshara();
      printAsharaList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOfCorrection]);

  useEffect(() => {
    updateInstance();
    // updateInstanceAshara();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployees]);

  useEffect(() => {
    // updateInstanceAshara();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letterAmharicDate, letterNumber]);

  function printAsharaList() {
    let downloadLink = document.createElement("a");
    const fileName = new Date().toLocaleDateString() + "_Letter.pdf";

    downloadLink.href = instance.url as string;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  function printCorrectionLetter() {}

  const handleClickOpen = (id: string) => {
    setSelectedEmployeeId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const DeleteEmployee = () => {
    setOpen(false);
    dispatch(removeEmployee(selectedEmployeeId));
  };

  useEffect(() => {
    dispatch(changePageTitle(""));

    const skipRows = currentPage * rowsPerPage;

    dispatch(
      fetchEmployees({
        searchText,
        skip: skipRows,
        take: rowsPerPage,
      })
    );
  }, [dispatch, searchText, currentPage, rowsPerPage]); //

  useEffect(() => {
    setTotal(totalCount as number);
    const checkBoxes = [...Array(totalCount)].map((e, i) => {
      return false;
    });
    setSelectCandidates(checkBoxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  useEffect(() => {
    const employeeIds: Employee[] = [];
    for (let i = 0; i < totalCount; i++) {
      if (selectCandidates[i] === true)
        employeeIds.push(employees[i] as Employee);
    }
    setSelectedEmployees(employeeIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCandidates]);

  //const printEmbassyList = () => {
  //dispatch(generateLink(employeeIds));
  //console.log(employeeIds);
  //};
  // const changeSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const selectedAll = event.target.checked;
  //   setSelectAll(selectedAll);
  //   const checkBoxes = [...Array(totalCount)].map((e, i) => {
  //     return selectedAll;
  //   });
  //   setSelectCandidates(checkBoxes);
  //   //console.log(selectCandidates);
  // };
  const changeCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const tempSelected = [...selectCandidates];
    tempSelected[index] = event.target.checked;
    setSelectCandidates(tempSelected);
  };
  const RefreshList = () => {
    const skipRows = currentPage * rowsPerPage;
    dispatch(
      fetchEmployees({
        refreshList: "refresh",
        searchText,
        skip: skipRows,
        take: rowsPerPage,
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>List | EnjazPlus</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <>
          {employees.length > 0 && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                justifyItems="center"
              >
                <Tooltip title="Add New Employee">
                  <Button
                    color="warning"
                    variant="contained"
                    component={RouterLink}
                    to={`/app/com/${Math.random().toString(36).slice(2, 12)}`}
                  >
                    <Add />
                  </Button>
                </Tooltip>
                {isSudan ? (
                  <CommissionsReportsMenu
                    employees={selectedEmployees}
                    printEmbassyList={printEmbassyList}
                    printAsharaList={printAsharaList}
                    printCorrectionLetter={printCorrectionLetter}
                    setLetterAmharicDate={setLetterAmharicDate}
                    setLetterNumber={setLetterNumber}
                    setTypeOfCorrection={setTypeOfCorrection}
                    setReason={setReason}
                  />
                ) : (
                  <Tooltip title="Print Embassy List">
                    <Button
                      color="warning"
                      variant="contained"
                      onClick={printEmbassyList}
                      size="large"
                    >
                      <Print />
                    </Button>
                  </Tooltip>
                )}
              </Stack>
              {/* <Accordion>
                <StyledAccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6" component="div">
                    <FilterList />
                    Filter List
                  </Typography>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <CompanyFilter
                    companyType={ClientTypes.ForeignAgent}
                    setCompany={setCompany}
                    includeAll={true}
                  />
                </AccordionDetails>
              </Accordion> */}
            </>
          )}
          <Grid container justifyContent="center" sx={{ mt: 1 }}>
            <TableContainer component={Paper}>
              <Table size="medium" aria-label="simple table">
                <TableHead>
                  <StyledTableRow
                  // sx={{
                  //   background:
                  //     theme.direction === "ltr"
                  //       ? `linear-gradient(90deg, #D21034 45%, #FFFFFF 75%, #000000 85%)`
                  //       : `linear-gradient(-90deg, #D21034 45%, #FFFFFF 75%, #000000 85%)`,
                  // }}
                  >
                    <StyledTableCell>
                      {/* <Checkbox
                        checked={selectAll}
                        onChange={(e) => changeSelectAll(e)}
                        inputProps={{ "aria-label": "controlled" }}
                        name="selectAll"
                        color="secondary"
                      /> */}
                    </StyledTableCell>
                    <StyledTableCell>{t("sno")}</StyledTableCell>
                    <StyledTableCell>{t("photo")}</StyledTableCell>
                    <StyledTableCell>{t("fullName")}</StyledTableCell>
                    <StyledTableCell>{t("passportNumber")}</StyledTableCell>
                    <StyledTableCell>{t("visa_number")}</StyledTableCell>
                    <StyledTableCell>{t("com_sponsor")}</StyledTableCell>
                    <StyledTableCell>{t("applicationNumber")}</StyledTableCell>

                    <StyledTableCell>{t("actions")}</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {loading === "pending" ? (
                    <TableSkeleton numRows={5} numColumns={8} />
                  ) : employees.length === 0 ? (
                    <StyledTableRow key={"noData"}>
                      <StyledTableCell colSpan={9} align="center">
                        <Typography variant="h5" color="secondary">
                          {t("empty")}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    employees.map((row, index) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell>
                          <Checkbox
                            checked={
                              selectCandidates[index]
                                ? selectCandidates[index]
                                : false
                            }
                            onChange={(e) => changeCheckBox(e, index)}
                            inputProps={{ "aria-label": "controlled" }}
                            name={index.toString()}
                            color="secondary"
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          }}
                          component="th"
                          scope="row"
                        >
                          {currentPage * rowsPerPage + index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Avatar
                            component={RouterLink}
                            alt="avatar"
                            src={row ? row.facePhoto : ""}
                            sx={{
                              cursor: "pointer",
                              height: 40,
                              width: 40,
                            }}
                            to={`/app/com/${row._id}`}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.givenName + " " + row.surName}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: theme.palette.warning.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.passportNumber}
                        </StyledTableCell>
                        {/* <StyledTableCell
                          style={{
                            color: theme.palette.primary.main,
                            textAlign: "center",
                          }}
                        >
                          {row.prospects && row.prospects[0]
                            ? row.prospects[0].displayName.slice(0, 15) + "..."
                            : "-"}
                        </StyledTableCell> */}
                        <StyledTableCell
                          sx={{
                            color: theme.palette.warning.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.visa?.visaNumber}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.visa?.fullName}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            color: theme.palette.warning.main,
                            fontWeight: 700,
                          }}
                        >
                          {row.embassyProcess && row.embassyProcess.enjazNumber
                            ? `${row.embassyProcess.enjazNumber}`
                            : ""}
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          {te(row.status as string)}
                        </StyledTableCell> */}

                        <StyledTableCell>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <IconButton
                              color="primary"
                              component={RouterLink}
                              to={"/app/com/" + row._id}
                              size="large"
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              color="warning"
                              onClick={() => handleClickOpen(row._id as string)}
                              size="large"
                            >
                              <Delete />
                            </IconButton>
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <br />
          {success && <Toast severity="success">{success.message}</Toast>}
          {error && <Toast severity="error">{error.message}</Toast>}
          {employees.length > 0 && (
            <Stack spacing={1}>
              <Paging
                total={total}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
              />
              <Typography variant="body2" color="textPrimary" component="div">
                {t("total")} : {total}
              </Typography>
            </Stack>
          )}
        </>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This data will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={DeleteEmployee}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
