import {
  Accordion,
  AccordionDetails,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
  MenuItem,
  Select,
  SelectChangeEvent,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { SketchPicker } from "react-color";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useEffect, useState } from "react";
import { ExpandMore, Save } from "@mui/icons-material";
import { StyledAccordionSummary } from "../../../styles/componentStyled";
import { Company, CvTemplate } from "../../setups/types/setupTypes";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectAuth } from "../../auth/authSlice";
import { addCompany, getCompany, selectSetups } from "../../setups/setupSlices";
import { GradientTemplate } from "./styles/table";
import { CvMakerCompanyFilter } from "../../../components/filter/CvMakerCompanyFilter";

interface Props {
  setName: any;
  setNumberOfPages: any;
  setCvLang: any;
  setBGC: any;
  setHFC: any;
  setHBC: any;
  setLFC: any;
  setLBC: any;
  setVFC: any;
  setVBC: any;
  setBorderColor: any;
  setBorderRadius: any;
  setBorderWidth: any;
  setBorderStyle: any;
  setSpacing: any;
  setLabelFontSize: any;
  setValueFontSize: any;
  setLabelFontWeight: any;
  setValueFontWeight: any;
  setTextTransform: any;
  setHeaderHeight: any;
  setBodyHeight: any;
  setHeaderGradient: any;
  setEvenGradient: any;
  setOddGradient: any;
  setAddPassportHeader: any;
  setUseHeaderGradient: any;
  setUseBodyGradient: any;
  setHeaderShadow: any;
  setBodyShadow: any;
  setPhotoShadow: any;
  setPassportShadow: any;

  setQrLocationLink: any;
  setQrLocationWidth: any;
  setQrLocationHeight: any;
  setQrLocationImageUrl: any;
  setQrLocationMargin: any;
  setQrMoreCvsLink: any;
  setQrMoreCvsWidth: any;
  setQrMoreCvsHeight: any;
  setQrMoreCvsImageUrl: any;
  setQrMoreCvsMargin: any;
  setFaceHeight: any;
  setFaceWidth: any;
  setStandHeight: any;
  setStandWidth: any;
  setFlagHeight: any;
  setFlagWidth: any;
  setPassportHeight: any;
  setPassportWidth: any;
  setHeadBgColorAlpha: any;
  setHeadGrFirstColorAlpha: any;
  setHeadGrSecondColorAlpha: any;
  setLabelBgColorAlpha: any;
  setOddGrFirstColorAlpha: any;
  setOddGrSecondColorAlpha: any;
  setValueBgColorAlpha: any;
  setEvenGrFirstColorAlpha: any;
  setEvenGrSecondColorAlpha: any;
}

export const CvMakerPremium = ({
  setName,
  setNumberOfPages,
  setCvLang,
  setBGC: setBackgroundColor,
  setHFC,
  setHBC,
  setLFC,
  setLBC,
  setVFC,
  setVBC,
  setBorderColor,
  setBorderRadius,
  setBorderWidth,
  setBorderStyle,
  setSpacing,
  setLabelFontSize,
  setValueFontSize,
  setLabelFontWeight,
  setValueFontWeight,
  setTextTransform,
  setHeaderHeight,
  setBodyHeight,
  setHeaderGradient,
  setEvenGradient,
  setOddGradient,
  setAddPassportHeader,
  setUseHeaderGradient,
  setUseBodyGradient,
  setHeaderShadow,
  setBodyShadow,
  setPhotoShadow,
  setPassportShadow,
  setQrLocationLink,
  setQrLocationWidth,
  setQrLocationHeight,
  setQrLocationImageUrl,
  setQrLocationMargin,
  setQrMoreCvsLink,
  setQrMoreCvsWidth,
  setQrMoreCvsHeight,
  setQrMoreCvsImageUrl,
  setQrMoreCvsMargin,
  setFaceHeight,
  setFaceWidth,
  setStandHeight,
  setStandWidth,
  setFlagHeight,
  setFlagWidth,
  setPassportHeight,
  setPassportWidth,
  setHeadBgColorAlpha,
  setHeadGrFirstColorAlpha,
  setHeadGrSecondColorAlpha,
  setLabelBgColorAlpha,
  setOddGrFirstColorAlpha,
  setOddGrSecondColorAlpha,
  setValueBgColorAlpha,
  setEvenGrFirstColorAlpha,
  setEvenGrSecondColorAlpha,
}: Props) => {
  const theme = useTheme();
  const [company, setCompany] = useState<Company>();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  const [searchText, setSearchText] = useState("");
  const { selectedCompany } = useAppSelector(selectSetups);

  const [isAgentTemplate, setIsAgentTemplate] = useState<boolean>(false);

  const [name, setName2] = useState("basic");
  const [numberOfPages, setNumberOfPages2] = useState(2);
  const [cvLang, setCvLang2] = useState("en");
  const [backgroundColor, setBgColor] = useState(theme.palette.secondary.main);
  const [hfc, setHFC2] = useState("#fff");
  const [hbc, setHBC2] = useState(theme.palette.secondary.main);
  const [lfc, setLFC2] = useState(theme.palette.primary.main);
  const [lbc, setLBC2] = useState("#fff");
  const [vfc, setVFC2] = useState(theme.palette.secondary.main);
  const [vbc, setVBC2] = useState("#fff");

  const [borderRadius, setBorderRadius2] = useState("5px");
  const [borderStyle, setBorderStyle2] = useState("solid");
  const [borderColor, setBorderColor2] = useState("#ffffff");
  const [borderWidth, setBorderWidth2] = useState(1);
  const [spacing, setSpacing2] = useState(1);
  const [labelFontSize, setLabelFontSize2] = useState(16);
  const [valueFontSize, setValueFontSize2] = useState(18);
  const [labelFontWeight, setLabelFontWeight2] = useState(900);
  const [valueFontWeight, setValueFontWeight2] = useState(900);
  const [textTransform, setTextTransform2] = useState("uppercase");
  const [headerHeight, setHeaderHeight2] = useState(48);
  const [bodyHeight, setBodyHeight2] = useState(32);
  const [addPassportHeader, setAddPassportHeader2] = useState(false);
  const [useHeaderGradient, setUseHeaderGradient2] = useState(true);
  const [useBodyGradient, setUseBodyGradient2] = useState(false);
  const [headerShadow, setHeaderShadow2] = useState("5px 5px rgba(0,0,0,0.14)");
  const [bodyShadow, setBodyShadow2] = useState("5px 5px rgba(0,0,0,0.14)");
  const [photoShadow, setPhotoShadow2] = useState("5px 5px rgba(0,0,0,0.14)");
  const [passportShadow, setPassportShadow2] = useState(
    "5px 5px rgba(0,0,0,0.14)"
  );
  const [qrLocationLink, setQrLocationLink2] = useState(
    "https://maps.app.goo.gl/ikKVLs5b6Ef2Xemw8"
  );
  const [qrLocationWidth, setQrLocationWidth2] = useState(150);
  const [qrLocationHeight, setQrLocationHeight2] = useState(150);
  const [qrLocationImageUrl, setQrLocationImageUrl2] =
    useState("/googleMaps.png");
  const [qrLocationMargin, setQrLocationMargin2] = useState(0);

  const [qrMoreCvsLink, setQrMoreCvsLink2] = useState(
    "https://maps.app.goo.gl/ikKVLs5b6Ef2Xemw8"
  );
  const [qrMoreCvsWidth, setQrMoreCvsWidth2] = useState(150);
  const [qrMoreCvsHeight, setQrMoreCvsHeight2] = useState(150);
  const [qrMoreCvsImageUrl, setQrMoreCvsImageUrl2] =
    useState("/googleMaps.png");
  const [qrMoreCvsMargin, setQrMoreCvsMargin2] = useState(0);

  const [faceHeight, setFaceHeight2] = useState("240px");
  const [faceWidth, setFaceWidth2] = useState("100%");
  const [standHeight, setStandHeight2] = useState("600px");
  const [standWidth, setStandWidth2] = useState("400px");
  const [flagHeight, setFlagHeight2] = useState("200px");
  const [flagWidth, setFlagWidth2] = useState("100%");
  const [passportHeight, setPassportHeight2] = useState("650px");
  const [passportWidth, setPassportWidth2] = useState("700px");
  const [headBgColorAlpha, setHeadBgColorAlpha2] = useState(1);
  const [headGrFirstColorAlpha, setHeadGrFirstColorAlpha2] = useState(1);
  const [headGrSecondColorAlpha, setHeadGrSecondColorAlpha2] = useState(1);
  const [labelBgColorAlpha, setLabelBgColorAlpha2] = useState(1);
  const [oddGrFirstColorAlpha, setOddGrFirstColorAlpha2] = useState(1);
  const [oddGrSecondColorAlpha, setOddGrSecondColorAlpha2] = useState(1);
  const [valueBgColorAlpha, setValueBgColorAlpha2] = useState(1);
  const [evenGrFirstColorAlpha, setEvenGrFirstColorAlpha2] = useState(1);
  const [evenGrSecondColorAlpha, setEvenGrSecondColorAlpha2] = useState(1);

  const [headerGradient, setHeaderGradient2] = useState<GradientTemplate>({
    typeOfGradient: "radial-gradient",
    degree: "circle",
    firstColor: theme.palette.primary.main,
    firstPercentage: "0%",
    secondColor: theme.palette.secondary.main,
    secondPercentage: "100%",
  });
  const [evenGradient, setEvenGradient2] = useState<GradientTemplate>({
    typeOfGradient: "linear-gradient",
    degree: "90deg",
    firstColor: theme.palette.primary.main,
    firstPercentage: "0%",
    secondColor: theme.palette.secondary.main,
    secondPercentage: "100%",
  });
  const [oddGradient, setOddGradient2] = useState<GradientTemplate>({
    typeOfGradient: "linear-gradient",
    degree: "90deg",
    firstColor: theme.palette.primary.main,
    firstPercentage: "0%",
    secondColor: theme.palette.secondary.main,
    secondPercentage: "100%",
  });
  const [headerFirstColor, setHeaderFirstColor2] = useState(
    theme.palette.primary.main
  );
  const [headerSecondColor, setHeaderSecondColor2] = useState(
    theme.palette.primary.main
  );

  const [evenFirstColor, setEvenFirstColor2] = useState(
    theme.palette.primary.main
  );
  const [evenSecondColor, setEvenSecondColor2] = useState(
    theme.palette.primary.main
  );

  const [oddFirstColor, setOddFirstColor2] = useState(
    theme.palette.primary.main
  );
  const [oddSecondColor, setOddSecondColor2] = useState(
    theme.palette.primary.main
  );

  useEffect(() => {
    setHeaderGradient({ ...headerGradient, firstColor: headerFirstColor });
    setHeaderGradient2({ ...headerGradient, firstColor: headerFirstColor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerFirstColor]);
  useEffect(() => {
    setHeaderGradient({ ...headerGradient, secondColor: headerSecondColor });
    setHeaderGradient2({ ...headerGradient, secondColor: headerSecondColor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerSecondColor]);

  useEffect(() => {
    setEvenGradient({ ...evenGradient, firstColor: evenFirstColor });
    setEvenGradient2({ ...evenGradient, firstColor: evenFirstColor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evenFirstColor]);
  useEffect(() => {
    setEvenGradient({ ...evenGradient, secondColor: evenSecondColor });
    setEvenGradient2({ ...evenGradient, secondColor: evenSecondColor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evenSecondColor]);

  useEffect(() => {
    setOddGradient({ ...oddGradient, firstColor: oddFirstColor });
    setOddGradient2({ ...oddGradient, firstColor: oddFirstColor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oddFirstColor]);
  useEffect(() => {
    setOddGradient({ ...oddGradient, secondColor: oddSecondColor });
    setOddGradient2({ ...oddGradient, secondColor: oddSecondColor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oddSecondColor]);

  const changeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgentTemplate(!isAgentTemplate);
  };
  useEffect(() => {
    if (user && user.company && user.company._id)
      dispatch(getCompany(user.company._id));
  }, [dispatch, user]);

  useEffect(() => {
    if (company && company._id) {
      if (company._id === "-1") {
        if (user && user.company && user.company._id)
          dispatch(getCompany(user.company._id));
      } else {
        dispatch(getCompany(company._id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, company]);

  useEffect(() => {
    if (selectedCompany) {
      //console.log(selectedCompany);
      if (selectedCompany.cvTemplate) {
        const cv = selectedCompany.cvTemplate;
        setName(cv.name);
        setName2(cv.name);
        setNumberOfPages(cv.numberOfPages);
        setNumberOfPages2(cv.numberOfPages);
        setCvLang(cv.cvLang);
        setCvLang2(cv.cvLang);
        setBgColor(cv.bgColor);
        setBackgroundColor(cv.bgColor);
        setHFC(cv.headFontColor);
        setHFC2(cv.headFontColor);
        setHBC(cv.headBgColor);
        setHBC2(cv.headBgColor);
        setLFC(cv.labelFontColor);
        setLFC2(cv.labelFontColor);
        setLBC(cv.labelBgColor);
        setLBC2(cv.labelBgColor);
        setVFC(cv.valueFontColor);
        setVFC2(cv.valueFontColor);
        setVBC(cv.valueBgColor);
        setVBC2(cv.valueBgColor);

        setBorderColor(cv.borderColor);
        setBorderColor2(cv.borderColor);
        setBorderRadius(cv.borderRadius);
        setBorderRadius2(cv.borderRadius);
        setBorderWidth(cv.borderWidth);
        setBorderWidth2(cv.borderWidth);
        setBorderStyle(cv.borderStyle);
        setBorderStyle2(cv.borderStyle);
        setSpacing(cv.spacing);
        setSpacing2(cv.spacing);
        setLabelFontSize(cv.labelFontSize);
        setLabelFontSize2(cv.labelFontSize);
        setValueFontSize(cv.valueFontSize);
        setValueFontSize2(cv.valueFontSize);
        setLabelFontWeight(cv.labelFontWeight);
        setLabelFontWeight2(cv.labelFontWeight);
        setValueFontWeight(cv.valueFontWeight);
        setValueFontWeight2(cv.valueFontWeight);
        setTextTransform(cv.textTransform);
        setTextTransform2(cv.textTransform);
        setHeaderHeight(cv.headerHeight);
        setHeaderHeight2(cv.headerHeight);
        setBodyHeight(cv.bodyHeight);
        setBodyHeight2(cv.bodyHeight);

        setAddPassportHeader(cv.addPassportHeader);
        setAddPassportHeader2(cv.addPassportHeader);
        setUseHeaderGradient(cv.useHeaderGradient);
        setUseHeaderGradient2(cv.useHeaderGradient);
        setUseBodyGradient(cv.useBodyGradient);
        setUseBodyGradient2(cv.useBodyGradient);

        setHeaderShadow(cv.headerShadow);
        setHeaderShadow2(cv.headerShadow);
        setBodyShadow(cv.bodyShadow);
        setBodyShadow2(cv.bodyShadow);
        setPhotoShadow(cv.photoShadow);
        setPhotoShadow2(cv.photoShadow);
        setPassportShadow(cv.passportShadow);
        setPassportShadow2(cv.passportShadow);

        setQrLocationLink(cv.qrLocationLink);
        setQrLocationLink2(cv.qrLocationLink);
        setQrLocationWidth(cv.qrLocationWidth);
        setQrLocationWidth2(cv.qrLocationWidth);
        setQrLocationHeight(cv.qrLocationHeight);
        setQrLocationHeight2(cv.qrLocationHeight);
        setQrLocationImageUrl(cv.qrLocationImageUrl);
        setQrLocationImageUrl2(cv.qrLocationImageUrl);
        setQrLocationMargin(cv.qrLocationMargin);
        setQrLocationMargin2(cv.qrLocationMargin);

        setQrMoreCvsLink(cv.qrMoreCvsLink);
        setQrMoreCvsLink2(cv.qrMoreCvsLink);
        setQrMoreCvsWidth(cv.qrMoreCvsWidth);
        setQrMoreCvsWidth2(cv.qrMoreCvsWidth);
        setQrMoreCvsHeight(cv.qrMoreCvsHeight);
        setQrMoreCvsHeight2(cv.qrMoreCvsHeight);
        setQrMoreCvsImageUrl(cv.qrMoreCvsImageUrl);
        setQrMoreCvsImageUrl2(cv.qrMoreCvsImageUrl);
        setQrMoreCvsMargin(cv.qrMoreCvsMargin);
        setQrMoreCvsMargin2(cv.qrMoreCvsMargin);

        setFaceHeight(cv.faceHeight ? cv.faceHeight : faceHeight);
        setFaceHeight2(cv.faceHeight ? cv.faceHeight : faceHeight);
        setFaceWidth(cv.faceWidth ? cv.faceWidth : faceWidth);
        setFaceWidth2(cv.faceWidth ? cv.faceWidth : faceWidth);
        setStandHeight(cv.standHeight ? cv.standHeight : standHeight);
        setStandHeight2(cv.standHeight ? cv.standHeight : standHeight);
        setStandWidth(cv.standWidth ? cv.standWidth : standWidth);
        setStandWidth2(cv.standWidth ? cv.standWidth : standWidth);
        setFlagHeight(cv.flagHeight ? cv.flagHeight : flagHeight);
        setFlagHeight2(cv.flagHeight ? cv.flagHeight : flagHeight);
        setFlagWidth(cv.flagWidth ? cv.flagWidth : flagWidth);
        setFlagWidth2(cv.flagWidth ? cv.flagWidth : flagWidth);
        setPassportHeight(
          cv.passportHeight ? cv.passportHeight : passportHeight
        );
        setPassportHeight2(
          cv.passportHeight ? cv.passportHeight : passportHeight
        );
        setPassportWidth(cv.passportWidth ? cv.passportWidth : passportWidth);
        setPassportWidth2(cv.passportWidth ? cv.passportWidth : passportWidth);

        setHeadBgColorAlpha(
          cv.headBgColorAlpha ? cv.headBgColorAlpha : headBgColorAlpha
        );
        setHeadBgColorAlpha2(
          cv.headBgColorAlpha ? cv.headBgColorAlpha : headBgColorAlpha
        );
        setHeadGrFirstColorAlpha(
          cv.headGrFirstColorAlpha
            ? cv.headGrFirstColorAlpha
            : headGrFirstColorAlpha
        );
        setHeadGrFirstColorAlpha2(
          cv.headGrFirstColorAlpha
            ? cv.headGrFirstColorAlpha
            : headGrFirstColorAlpha
        );
        setHeadGrSecondColorAlpha(
          cv.headGrSecondColorAlpha
            ? cv.headGrSecondColorAlpha
            : headGrSecondColorAlpha
        );
        setHeadGrSecondColorAlpha2(
          cv.headGrSecondColorAlpha
            ? cv.headGrSecondColorAlpha
            : headGrSecondColorAlpha
        );
        setLabelBgColorAlpha(
          cv.labelBgColorAlpha ? cv.labelBgColorAlpha : labelBgColorAlpha
        );
        setLabelBgColorAlpha2(
          cv.labelBgColorAlpha ? cv.labelBgColorAlpha : labelBgColorAlpha
        );
        setOddGrFirstColorAlpha(
          cv.oddGrFirstColorAlpha
            ? cv.oddGrFirstColorAlpha
            : oddGrFirstColorAlpha
        );
        setOddGrFirstColorAlpha2(
          cv.oddGrFirstColorAlpha
            ? cv.oddGrFirstColorAlpha
            : oddGrFirstColorAlpha
        );
        setOddGrSecondColorAlpha(
          cv.oddGrSecondColorAlpha
            ? cv.oddGrSecondColorAlpha
            : oddGrSecondColorAlpha
        );
        setOddGrSecondColorAlpha2(
          cv.oddGrSecondColorAlpha
            ? cv.oddGrSecondColorAlpha
            : oddGrSecondColorAlpha
        );

        setValueBgColorAlpha(
          cv.valueBgColorAlpha ? cv.valueBgColorAlpha : valueBgColorAlpha
        );
        setValueBgColorAlpha2(
          cv.valueBgColorAlpha ? cv.valueBgColorAlpha : valueBgColorAlpha
        );

        setEvenGrFirstColorAlpha(
          cv.evenGrFirstColorAlpha
            ? cv.evenGrFirstColorAlpha
            : evenGrFirstColorAlpha
        );
        setEvenGrFirstColorAlpha2(
          cv.evenGrFirstColorAlpha
            ? cv.evenGrFirstColorAlpha
            : evenGrFirstColorAlpha
        );
        setEvenGrSecondColorAlpha(
          cv.evenGrSecondColorAlpha
            ? cv.evenGrSecondColorAlpha
            : evenGrSecondColorAlpha
        );
        setEvenGrSecondColorAlpha2(
          cv.evenGrSecondColorAlpha
            ? cv.evenGrSecondColorAlpha
            : evenGrSecondColorAlpha
        );

        const headerGr = cv.headerGradient.split("_");
        if (headerGr.length === 6) {
          setHeaderGradient2({
            typeOfGradient: headerGr[0],
            degree: headerGr[1],
            firstColor: headerGr[2],
            firstPercentage: headerGr[3],
            secondColor: headerGr[4],
            secondPercentage: headerGr[5],
          });
          setHeaderFirstColor2(headerGr[2]);
          setHeaderSecondColor2(headerGr[4]);
          setHeaderGradient(headerGradient);
        }

        const evenGr = cv.evenGradient.split("_");
        if (evenGr.length === 6) {
          setEvenGradient2({
            typeOfGradient: evenGr[0],
            degree: evenGr[1],
            firstColor: evenGr[2],
            firstPercentage: evenGr[3],
            secondColor: evenGr[4],
            secondPercentage: evenGr[5],
          });
          setEvenFirstColor2(evenGr[2]);
          setEvenSecondColor2(evenGr[4]);
          setEvenGradient(evenGradient);
        }
        const oddGr = cv.oddGradient.split("_");
        if (oddGr.length === 6) {
          setOddGradient2({
            typeOfGradient: oddGr[0],
            degree: oddGr[1],
            firstColor: oddGr[2],
            firstPercentage: oddGr[3],
            secondColor: oddGr[4],
            secondPercentage: oddGr[5],
          });
          setOddFirstColor2(oddGr[2]);
          setOddSecondColor2(oddGr[4]);
          setOddGradient(oddGradient);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  const saveSelectedCompanyCvTemplate = () => {
    //console.log(selectedCompany);
    let hg = headerGradient,
      eg = evenGradient,
      og = oddGradient;
    const headerGr =
        hg.typeOfGradient +
        "_" +
        hg.degree +
        "_" +
        hg.firstColor +
        "_" +
        hg.firstPercentage +
        "_" +
        hg.secondColor +
        "_" +
        hg.secondPercentage,
      evenGr =
        eg.typeOfGradient +
        "_" +
        eg.degree +
        "_" +
        eg.firstColor +
        "_" +
        eg.firstPercentage +
        "_" +
        eg.secondColor +
        "_" +
        eg.secondPercentage,
      oddGr =
        og.typeOfGradient +
        "_" +
        og.degree +
        "_" +
        og.firstColor +
        "_" +
        og.firstPercentage +
        "_" +
        og.secondColor +
        "_" +
        og.secondPercentage;

    const cvTemp: CvTemplate = {
      name,
      numberOfPages,
      cvLang,
      bgColor: backgroundColor,
      headFontColor: hfc,
      headBgColor: hbc,
      labelFontColor: lfc,
      labelBgColor: lbc,
      valueFontColor: vfc,
      valueBgColor: vbc,
      borderColor,
      borderRadius,
      borderStyle,
      borderWidth,
      spacing,
      labelFontSize,
      valueFontSize,
      labelFontWeight,
      valueFontWeight,
      headerHeight,
      bodyHeight,
      textTransform,
      addPassportHeader,
      useHeaderGradient,
      useBodyGradient,
      headerGradient: headerGr,
      evenGradient: evenGr,
      oddGradient: oddGr,
      headerShadow,
      bodyShadow,
      photoShadow,
      passportShadow,
      qrLocationLink,
      qrLocationWidth,
      qrLocationHeight,
      qrLocationImageUrl,
      qrLocationMargin,
      qrMoreCvsLink,
      qrMoreCvsWidth,
      qrMoreCvsHeight,
      qrMoreCvsImageUrl,
      qrMoreCvsMargin,
      faceHeight,
      faceWidth,
      standHeight,
      standWidth,
      flagHeight,
      flagWidth,
      passportHeight,
      passportWidth,
      headBgColorAlpha,
      headGrFirstColorAlpha,
      headGrSecondColorAlpha,
      labelBgColorAlpha,
      oddGrFirstColorAlpha,
      oddGrSecondColorAlpha,
      valueBgColorAlpha,
      evenGrFirstColorAlpha,
      evenGrSecondColorAlpha,
    };
    //console.log(cvTemp);
    dispatch(
      addCompany({
        ...selectedCompany,
        cvTemplate: { ...cvTemp },
      })
    );
  };
  const langs = [
    { lang: "en", nativeName: "English", code: "US", icon: "usaFlag.png" },
    { lang: "ar", nativeName: "العربية", code: "SA", icon: "saudiFlag.png" },
  ];
  //const [selectedLanguage, setSelectedLanguage] = useState("ar");
  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    setCvLang(value);
    setCvLang2(value);
  };
  return (
    <Accordion sx={{ mb: 1 }} expanded>
      <StyledAccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" component="div">
          CV MAKER
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              id="searchText"
              label="Search..."
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <CvMakerCompanyFilter
              setCompany={setCompany}
              searchText={searchText}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="name"
              label="Template Name"
              onChange={(e) => {
                setName2(e.target.value);
                setName(e.target.value);
              }}
              value={name}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="numberOfPages"
              type="number"
              label="Number Of Pages"
              onChange={(e) => {
                setNumberOfPages2(parseInt(e.target.value));
                setNumberOfPages(parseInt(e.target.value));
              }}
              value={numberOfPages}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <Select
              value={cvLang}
              onChange={handleChange}
              label="Cv Value Language"
              displayEmpty
              autoWidth
              variant="outlined"
              size="small"
              sx={{
                color: theme.palette.primary.main,
                borderColor: theme.palette.secondary.main,
              }}
            >
              {langs.map((lang) => (
                <MenuItem key={lang.lang} value={lang.lang}>
                  <img
                    loading="lazy"
                    width="30"
                    src={`https://flagcdn.com/w40/${lang.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${lang.code.toLowerCase()}.png 2x`}
                    alt={lang.nativeName}
                  />
                  {lang.nativeName}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Accordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="div">
                  Main Colors
                </Typography>
              </StyledAccordionSummary>

              <AccordionDetails>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12}>
                    <ColorPopState
                      title="Background Color"
                      buttonBackgroundColor={backgroundColor}
                      setLocalColor={setBgColor}
                      setParentColor={setBackgroundColor}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ColorPopState
                      title="Header Font Color"
                      buttonBackgroundColor={hfc}
                      setLocalColor={setHFC2}
                      setParentColor={setHFC}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ColorPopState
                      title="Header Background"
                      buttonBackgroundColor={hbc}
                      setLocalColor={setHBC2}
                      setParentColor={setHBC}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ColorPopState
                      title="Label Font Color"
                      buttonBackgroundColor={lfc}
                      setLocalColor={setLFC2}
                      setParentColor={setLFC}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ColorPopState
                      title="Label Background"
                      buttonBackgroundColor={lbc}
                      setLocalColor={setLBC2}
                      setParentColor={setLBC}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ColorPopState
                      title="Value Font Color"
                      buttonBackgroundColor={vfc}
                      setLocalColor={setVFC2}
                      setParentColor={setVFC}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <ColorPopState
                      title="Value Background"
                      buttonBackgroundColor={vbc}
                      setLocalColor={setVBC2}
                      setParentColor={setVBC}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ColorPopState
                      title="Border Color"
                      buttonBackgroundColor={borderColor}
                      setLocalColor={setBorderColor2}
                      setParentColor={setBorderColor}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="div">
                  Main Fields
                </Typography>
              </StyledAccordionSummary>

              <AccordionDetails>
                <Grid container xs={12} spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="borderStyle"
                      label="Border Style"
                      onChange={(e) => {
                        setBorderStyle2(e.target.value);
                        setBorderStyle(e.target.value);
                      }}
                      value={borderStyle}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  {/*  */}
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="borderRadius"
                      label="Border Radius"
                      onChange={(e) => {
                        setBorderRadius2(e.target.value);
                        setBorderRadius(e.target.value);
                      }}
                      value={borderRadius}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="borderWidth"
                      type="number"
                      label="Border Width"
                      onChange={(e) => {
                        setBorderWidth2(parseInt(e.target.value));
                        setBorderWidth(parseInt(e.target.value));
                      }}
                      value={borderWidth}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="spacing"
                      type="number"
                      label="Space B/n"
                      onChange={(e) => {
                        setSpacing2(parseInt(e.target.value));
                        setSpacing(parseInt(e.target.value));
                      }}
                      value={spacing}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="labelFontSize"
                      type="number"
                      label="Label FontSize"
                      onChange={(e) => {
                        setLabelFontSize2(parseInt(e.target.value));
                        setLabelFontSize(parseInt(e.target.value));
                      }}
                      value={labelFontSize}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="valueFontSize"
                      type="number"
                      label="Value FontSize"
                      onChange={(e) => {
                        setValueFontSize2(parseInt(e.target.value));
                        setValueFontSize(parseInt(e.target.value));
                      }}
                      value={valueFontSize}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="labelFontWeight"
                      type="number"
                      label="Label FontWeight"
                      onChange={(e) => {
                        setLabelFontWeight2(parseInt(e.target.value));
                        setLabelFontWeight(parseInt(e.target.value));
                      }}
                      value={labelFontWeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="valueFontWeight"
                      type="number"
                      label="Value FontWeight"
                      onChange={(e) => {
                        setValueFontWeight2(parseInt(e.target.value));
                        setValueFontWeight(parseInt(e.target.value));
                      }}
                      value={valueFontWeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="headerHeight"
                      type="number"
                      label="Header Height"
                      onChange={(e) => {
                        setHeaderHeight2(parseInt(e.target.value));
                        setHeaderHeight(parseInt(e.target.value));
                      }}
                      value={headerHeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="bodyHeight"
                      type="number"
                      label="Body Height"
                      onChange={(e) => {
                        setBodyHeight2(parseInt(e.target.value));
                        setBodyHeight(parseInt(e.target.value));
                      }}
                      value={bodyHeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      id="textTransform"
                      label="Text Transform"
                      onChange={(e) => {
                        setTextTransform2(e.target.value);
                        setTextTransform(e.target.value);
                      }}
                      value={textTransform}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControlLabel
              style={{ marginTop: 0 }}
              control={
                <Switch
                  checked={useHeaderGradient}
                  onChange={(e) => {
                    setUseHeaderGradient(!useHeaderGradient);
                    setUseHeaderGradient2(!useHeaderGradient);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  name="selectAll"
                  color="secondary"
                />
              }
              label="Header Gr."
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <FormControlLabel
              style={{ marginTop: 0 }}
              control={
                <Switch
                  checked={useBodyGradient}
                  onChange={(e) => {
                    setUseBodyGradient(!useBodyGradient);
                    setUseBodyGradient2(!useBodyGradient);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  name="selectAll"
                  color="secondary"
                />
              }
              label="Body Gr."
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <FormControlLabel
              style={{ marginTop: 0 }}
              control={
                <Switch
                  checked={addPassportHeader}
                  onChange={(e) => {
                    setAddPassportHeader(!addPassportHeader);
                    setAddPassportHeader2(!addPassportHeader);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  name="selectAll"
                  color="secondary"
                />
              }
              label="Passport Head"
            />
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="div">
                  Gradients
                </Typography>
              </StyledAccordionSummary>

              <AccordionDetails>
                <Grid container xs={12} spacing={3}>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal">Header Gradient</Divider>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="headerTypeOfGr"
                      label="Gradient Type"
                      onChange={(e) => {
                        setHeaderGradient2({
                          ...headerGradient,
                          typeOfGradient: e.target.value,
                        });
                        setHeaderGradient({
                          ...headerGradient,
                          typeOfGradient: e.target.value,
                        });
                      }}
                      value={headerGradient.typeOfGradient}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="headerDegree"
                      label="Degree"
                      onChange={(e) => {
                        setHeaderGradient2({
                          ...headerGradient,
                          degree: e.target.value,
                        });
                        setHeaderGradient({
                          ...headerGradient,
                          degree: e.target.value,
                        });
                      }}
                      value={headerGradient.degree}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ColorPopStateLocal
                      title="Header First Color"
                      buttonBackgroundColor={headerFirstColor}
                      setLocalColor={setHeaderFirstColor2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="headerFirstPercentage"
                      label="First Percentage"
                      onChange={(e) => {
                        setHeaderGradient2({
                          ...headerGradient,
                          firstPercentage: e.target.value,
                        });
                        setHeaderGradient({
                          ...headerGradient,
                          firstPercentage: e.target.value,
                        });
                      }}
                      value={headerGradient.firstPercentage}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ColorPopStateLocal
                      title="Header Second Color"
                      buttonBackgroundColor={headerSecondColor}
                      setLocalColor={setHeaderSecondColor2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="headerSecondPercentage"
                      label="Second Percentage"
                      onChange={(e) => {
                        setHeaderGradient2({
                          ...headerGradient,
                          secondPercentage: e.target.value,
                        });
                        setHeaderGradient({
                          ...headerGradient,
                          secondPercentage: e.target.value,
                        });
                      }}
                      value={headerGradient.secondPercentage}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal">Even Gradient</Divider>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="evenTypeOfGr"
                      label="Gradient Type"
                      onChange={(e) => {
                        setEvenGradient2({
                          ...evenGradient,
                          typeOfGradient: e.target.value,
                        });
                        setEvenGradient({
                          ...evenGradient,
                          typeOfGradient: e.target.value,
                        });
                      }}
                      value={evenGradient.typeOfGradient}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="evenDegree"
                      label="Degree"
                      onChange={(e) => {
                        setEvenGradient2({
                          ...evenGradient,
                          degree: e.target.value,
                        });
                        setEvenGradient({
                          ...evenGradient,
                          degree: e.target.value,
                        });
                      }}
                      value={evenGradient.degree}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ColorPopStateLocal
                      title="Even First Color"
                      buttonBackgroundColor={evenFirstColor}
                      setLocalColor={setEvenFirstColor2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="evenFirstPercentage"
                      label="First Percentage"
                      onChange={(e) => {
                        setEvenGradient2({
                          ...evenGradient,
                          firstPercentage: e.target.value,
                        });
                        setEvenGradient({
                          ...evenGradient,
                          firstPercentage: e.target.value,
                        });
                      }}
                      value={evenGradient.firstPercentage}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ColorPopStateLocal
                      title="Even Second Color"
                      buttonBackgroundColor={evenSecondColor}
                      setLocalColor={setEvenSecondColor2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="evenSecondPercentage"
                      label="Second Percentage"
                      onChange={(e) => {
                        setEvenGradient2({
                          ...evenGradient,
                          secondPercentage: e.target.value,
                        });
                        setEvenGradient({
                          ...evenGradient,
                          secondPercentage: e.target.value,
                        });
                      }}
                      value={evenGradient.secondPercentage}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal">Odd Gradient</Divider>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="oddTypeOfGr"
                      label="Gradient Type"
                      onChange={(e) => {
                        setOddGradient2({
                          ...oddGradient,
                          typeOfGradient: e.target.value,
                        });
                        setOddGradient({
                          ...oddGradient,
                          typeOfGradient: e.target.value,
                        });
                      }}
                      value={oddGradient.typeOfGradient}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="oddDegree"
                      label="Degree"
                      onChange={(e) => {
                        setOddGradient2({
                          ...oddGradient,
                          degree: e.target.value,
                        });
                        setOddGradient({
                          ...oddGradient,
                          degree: e.target.value,
                        });
                      }}
                      value={oddGradient.degree}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ColorPopStateLocal
                      title="Odd First Color"
                      buttonBackgroundColor={oddFirstColor}
                      setLocalColor={setOddFirstColor2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="oddFirstPercentage"
                      label="First Percentage"
                      onChange={(e) => {
                        setOddGradient2({
                          ...oddGradient,
                          firstPercentage: e.target.value,
                        });
                        setOddGradient({
                          ...oddGradient,
                          firstPercentage: e.target.value,
                        });
                      }}
                      value={oddGradient.firstPercentage}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <ColorPopStateLocal
                      title="Odd Second Color"
                      buttonBackgroundColor={oddSecondColor}
                      setLocalColor={setOddSecondColor2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="oddSecondPercentage"
                      label="Second Percentage"
                      onChange={(e) => {
                        setOddGradient2({
                          ...oddGradient,
                          secondPercentage: e.target.value,
                        });
                        setOddGradient({
                          ...oddGradient,
                          secondPercentage: e.target.value,
                        });
                      }}
                      value={oddGradient.secondPercentage}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="div">
                  Opacity
                </Typography>
              </StyledAccordionSummary>

              <AccordionDetails>
                <Grid container xs={12} spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="headBgColorAlpha"
                      type="number"
                      label="Head Bg Color"
                      onChange={(e) => {
                        setHeadBgColorAlpha2(parseFloat(e.target.value));
                        setHeadBgColorAlpha(parseFloat(e.target.value));
                      }}
                      value={headBgColorAlpha}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="labelBgColorAlpha"
                      type="number"
                      label="Label BgColor"
                      onChange={(e) => {
                        setLabelBgColorAlpha2(parseFloat(e.target.value));
                        setLabelBgColorAlpha(parseFloat(e.target.value));
                      }}
                      value={labelBgColorAlpha}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="valueBgColorAlpha"
                      type="number"
                      label="Value BgColor"
                      onChange={(e) => {
                        setValueBgColorAlpha2(parseFloat(e.target.value));
                        setValueBgColorAlpha(parseFloat(e.target.value));
                      }}
                      value={valueBgColorAlpha}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal">Head Gradient</Divider>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="headGrFirstColorAlpha"
                      type="number"
                      label="First Color"
                      onChange={(e) => {
                        setHeadGrFirstColorAlpha2(parseFloat(e.target.value));
                        setHeadGrFirstColorAlpha(parseFloat(e.target.value));
                      }}
                      value={headGrFirstColorAlpha}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="headGrSecondColorAlpha"
                      type="number"
                      label="Second Color"
                      onChange={(e) => {
                        setHeadGrSecondColorAlpha2(parseFloat(e.target.value));
                        setHeadGrSecondColorAlpha(parseFloat(e.target.value));
                      }}
                      value={headGrSecondColorAlpha}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider orientation="horizontal">Even Gradient</Divider>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="evenGrFirstColorAlpha"
                      type="number"
                      label="First Color"
                      onChange={(e) => {
                        setEvenGrFirstColorAlpha2(parseFloat(e.target.value));
                        setEvenGrFirstColorAlpha(parseFloat(e.target.value));
                      }}
                      value={evenGrFirstColorAlpha}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="evenGrSecondColorAlpha"
                      type="number"
                      label="Second Color"
                      onChange={(e) => {
                        setEvenGrSecondColorAlpha2(parseFloat(e.target.value));
                        setEvenGrSecondColorAlpha(parseFloat(e.target.value));
                      }}
                      value={evenGrSecondColorAlpha}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider orientation="horizontal">Odd Gradient</Divider>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="oddGrFirstColorAlpha"
                      type="number"
                      label="First Color"
                      onChange={(e) => {
                        setOddGrFirstColorAlpha2(parseFloat(e.target.value));
                        setOddGrFirstColorAlpha(parseFloat(e.target.value));
                      }}
                      value={oddGrFirstColorAlpha}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="oddGrSecondColorAlpha"
                      type="number"
                      label="Second Color"
                      onChange={(e) => {
                        setOddGrSecondColorAlpha2(parseFloat(e.target.value));
                        setOddGrSecondColorAlpha(parseFloat(e.target.value));
                      }}
                      value={oddGrSecondColorAlpha}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="div">
                  Shadows
                </Typography>
              </StyledAccordionSummary>

              <AccordionDetails>
                <Grid container xs={12} spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="headerShadow"
                      label="Header Shadow"
                      onChange={(e) => {
                        setHeaderShadow2(e.target.value);
                        setHeaderShadow(e.target.value);
                      }}
                      value={headerShadow}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="bodyShadow"
                      label="Body Shadow"
                      onChange={(e) => {
                        setBodyShadow2(e.target.value);
                        setBodyShadow(e.target.value);
                      }}
                      value={bodyShadow}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="photoShadow"
                      label="Photo Shadow"
                      onChange={(e) => {
                        setPhotoShadow2(e.target.value);
                        setPhotoShadow(e.target.value);
                      }}
                      value={photoShadow}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="passportShadow"
                      label="Passport Shadow"
                      onChange={(e) => {
                        setPassportShadow2(e.target.value);
                        setPassportShadow(e.target.value);
                      }}
                      value={passportShadow}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="div">
                  QR
                </Typography>
              </StyledAccordionSummary>

              <AccordionDetails>
                <Grid container xs={12} spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrLocationLink"
                      label="QrLocation Link"
                      onChange={(e) => {
                        setQrLocationLink2(e.target.value);
                        setQrLocationLink(e.target.value);
                      }}
                      value={qrLocationLink}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrLocationImageUrl"
                      label="QrLocation ImageUrl"
                      onChange={(e) => {
                        setQrLocationImageUrl2(e.target.value);
                        setQrLocationImageUrl(e.target.value);
                      }}
                      value={qrLocationImageUrl}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrLocationWidth"
                      type="number"
                      label="qrLocation Width"
                      onChange={(e) => {
                        setQrLocationWidth2(parseInt(e.target.value));
                        setQrLocationWidth(parseInt(e.target.value));
                      }}
                      value={qrLocationWidth}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrLocationHeight"
                      type="number"
                      label="qrLocation Height"
                      onChange={(e) => {
                        setQrLocationHeight2(parseInt(e.target.value));
                        setQrLocationHeight(parseInt(e.target.value));
                      }}
                      value={qrLocationHeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrLocationMargin"
                      type="number"
                      label="qrLocation Margin"
                      onChange={(e) => {
                        setQrLocationMargin2(parseInt(e.target.value));
                        setQrLocationMargin(parseInt(e.target.value));
                      }}
                      value={qrLocationMargin}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal">More Cvs QR</Divider>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrMoreCvsLink"
                      label="QrMoreCvs Link"
                      onChange={(e) => {
                        setQrMoreCvsLink2(e.target.value);
                        setQrMoreCvsLink(e.target.value);
                      }}
                      value={qrMoreCvsLink}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrMoreCvsImageUrl"
                      label="QrMoreCvs ImageUrl"
                      onChange={(e) => {
                        setQrMoreCvsImageUrl2(e.target.value);
                        setQrMoreCvsImageUrl(e.target.value);
                      }}
                      value={qrMoreCvsImageUrl}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrMoreCvsWidth"
                      type="number"
                      label="qrMoreCvs Width"
                      onChange={(e) => {
                        setQrMoreCvsWidth2(parseInt(e.target.value));
                        setQrMoreCvsWidth(parseInt(e.target.value));
                      }}
                      value={qrMoreCvsWidth}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrMoreCvsHeight"
                      type="number"
                      label="qrMoreCvs Height"
                      onChange={(e) => {
                        setQrMoreCvsHeight2(parseInt(e.target.value));
                        setQrMoreCvsHeight(parseInt(e.target.value));
                      }}
                      value={qrMoreCvsHeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="qrMoreCvsMargin"
                      type="number"
                      label="qrMoreCvs Margin"
                      onChange={(e) => {
                        setQrMoreCvsMargin2(parseInt(e.target.value));
                        setQrMoreCvsMargin(parseInt(e.target.value));
                      }}
                      value={qrMoreCvsMargin}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="div">
                  Image Size
                </Typography>
              </StyledAccordionSummary>

              <AccordionDetails>
                <Grid container xs={12} spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="faceHeight"
                      label="Face Height"
                      onChange={(e) => {
                        setFaceHeight2(e.target.value);
                        setFaceHeight(e.target.value);
                      }}
                      value={faceHeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="faceWidth"
                      label="Face Width"
                      onChange={(e) => {
                        setFaceWidth2(e.target.value);
                        setFaceWidth(e.target.value);
                      }}
                      value={faceWidth}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="standHeight"
                      label="Stand Height"
                      onChange={(e) => {
                        setStandHeight2(e.target.value);
                        setStandHeight(e.target.value);
                      }}
                      value={standHeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="standWidth"
                      label="Stand Width"
                      onChange={(e) => {
                        setStandWidth2(e.target.value);
                        setStandWidth(e.target.value);
                      }}
                      value={standWidth}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="flagHeight"
                      label="Flag Height"
                      onChange={(e) => {
                        setFlagHeight2(e.target.value);
                        setFlagHeight(e.target.value);
                      }}
                      value={flagHeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="flagWidth"
                      label="Flag Width"
                      onChange={(e) => {
                        setFlagWidth2(e.target.value);
                        setFlagWidth(e.target.value);
                      }}
                      value={flagWidth}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="passportHeight"
                      label="Passport Height"
                      onChange={(e) => {
                        setPassportHeight2(e.target.value);
                        setPassportHeight(e.target.value);
                      }}
                      value={passportHeight}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="passportWidth"
                      label="Passport Width"
                      onChange={(e) => {
                        setPassportWidth2(e.target.value);
                        setPassportWidth(e.target.value);
                      }}
                      value={passportWidth}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="warning"
              variant="contained"
              sx={{ width: "100%" }}
              onClick={saveSelectedCompanyCvTemplate}
            >
              <Save /> Save Template
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

interface PopUpProps {
  setLocalColor: any;
  setParentColor: any;
  title: string;
  buttonBackgroundColor: string;
}
interface PopUpLocalProps {
  setLocalColor: any;
  title: string;
  buttonBackgroundColor: string;
}
export const ColorPopState = ({
  title,
  setLocalColor,
  setParentColor,
  buttonBackgroundColor,
}: PopUpProps) => {
  const theme = useTheme();
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <>
          <Button
            variant="contained"
            sx={{
              backgroundColor: buttonBackgroundColor,
              width: "100%",
              color: theme.palette.getContrastText(buttonBackgroundColor),
            }}
            {...bindTrigger(popupState)}
          >
            {title}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <SketchPicker
              color={buttonBackgroundColor}
              onChange={(color) => {
                setParentColor(color.hex);
                setLocalColor(color.hex);
              }}
              // presetColors={[
              //   "#FFFFFF00",
              //   "#D0021B",
              //   "#F5A623",
              //   "#F8E71C",
              //   "#8B572A",
              //   "#7ED321",
              //   "#417505",
              //   "#BD10E0",
              //   "#9013FE",
              //   "#4A90E2",
              //   "#50E3C2",
              //   "#B8E986",
              //   "#000000",
              //   "#4A4A4A",
              //   "#9B9B9B",
              //   "#FFFFFF",
              // ]}
              // onChangeComplete={(color, event) => console.log(color)}
            />
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export const ColorPopStateLocal = ({
  title,
  setLocalColor,
  buttonBackgroundColor,
}: PopUpLocalProps) => {
  const theme = useTheme();
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <>
          <Button
            variant="contained"
            sx={{
              backgroundColor: buttonBackgroundColor,
              width: "100%",
              color: theme.palette.getContrastText(buttonBackgroundColor),
            }}
            {...bindTrigger(popupState)}
          >
            {title}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <SketchPicker
              color={buttonBackgroundColor}
              onChange={(color) => {
                setLocalColor(color.hex);
              }}
              // presetColors={[
              //   "#D0021B",
              //   "#F5A623",
              //   "#F8E71C",
              //   "#8B572A",
              //   "#7ED321",
              //   "#417505",
              //   "#BD10E0",
              //   "#9013FE",
              //   "#4A90E2",
              //   "#50E3C2",
              //   "#B8E986",
              //   "#000000",
              //   "#4A4A4A",
              //   "#9B9B9B",
              //   "#FFFFFF",
              // ]}
              // onChangeComplete={(color, event) => console.log(color)}
            />
          </Popover>
        </>
      )}
    </PopupState>
  );
};
